import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ServiceType } from "../../models/environment.model";
import { Answer, Interview, Summary } from '../../models/interview.model';
import { HttpService } from "../http/http.service";

const LOG: LoggerService = LoggerService.get('InterviewService');

/**
 * Retrieves interview topics and questions from the backend and converts it to a structured map for lter use by IntrerviewComponent
 */
@Injectable({
    providedIn: 'root'
})
export class InterviewService {

    initiatorParams: any = {};
    summaryState: any = {
        enabled: false,
        editing: false,
        editingTopic: null,
        editingSubTopic: null,
        activeTopics: []
    };
    willAttributes: any = new Map<string, string>([
        ['charity', null],
        ['lawfirm', null]
    ]);

    constructor(
        private http: HttpService,
        private route: ActivatedRoute) {

        this.route.queryParams.subscribe((params: Params) => {
            if (params['beq_initiator']) {
                this.initiatorParams['beq_initiator'] = params['beq_initiator'];
            }
            if (params['beq_initiator_type']) {
                this.initiatorParams['beq_initiator_type'] = params['beq_initiator_type'];
            }
            if (params['beq_package_reference']) {
                this.initiatorParams['beq_package_reference'] = params['beq_package_reference'];
            }
            if (params['lawfirm']) {
                this.initiatorParams['lawfirm'] = params['lawfirm'];
            }
            if (params['origin']) {
                this.initiatorParams['origin'] = params['origin'];
            }
        });
        this.route.params.subscribe((params: Params) => {
            if (params['charity']) {
                this.initiatorParams['beq_initiator_type'] = 'charity';
                this.initiatorParams['beq_initiator'] = params['charity'];
            }
        })
    }

    /**
     * Creates a brand-new interview record
     */
    async start(type: string): Promise<Interview> {
        return this.http.get(ServiceType.INTERVIEW, 'start-interview', { type }, this.initiatorParams)
         .then((response: any) => {
           const interview: Interview = new Interview();
           if (response) {
               interview.id = response.id;
               interview.type = type;
               interview.qa = response.qa;
               this.willAttributes['charity'] = response.willAttributes.charity;
               this.willAttributes['lawfirm'] = response.willAttributes.lawfirm;
           }
           return interview;
       })
    }

    /**
     * Fetch summary for specific interview type
     */
    summary(type: string, id: number): Promise<Summary> {
        return this.http.get(ServiceType.INTERVIEW, 'get-summary', {type, id})
            .then((response: any) => {
                this.willAttributes['charity'] = response.willAttributes.charity;
                this.willAttributes['lawfirm'] = response.willAttributes.lawfirm;
                return new Summary(response)
            });
    }

    /**
     * Evaluates the current answer-set
     */
    async evaluate(type: string, id: number, qa: Answer[], removed: Answer[]): Promise<Interview> {
        return this.http.post(ServiceType.INTERVIEW, 'evaluate-interview', {qa, removed}, {type, id})
           .then((response: any) => {
               const interview: Interview = new Interview();
               interview.type = type;
               if (!!response && !response.error) {
                   interview.id = response.id;
                   interview.qa = response.qa;
                   interview.removed = response.removed || [];
                   LOG.debug('evaluate ', JSON.stringify(response));
               }

               return interview;
           });
    }

    /**
     * Saves current interview
     */
    async save(type: string, id: number, qa: Answer[], removed: Answer[]): Promise<Interview> {
        return this.http.post(
            ServiceType.INTERVIEW,
            'save-interview',
            {qa, removed},
            {type, id}
        ).then((response: any) => {
           const interview: Interview = new Interview();
           interview.type = type;
           if (!!response && !response.error) {
               interview.id = response.id;
               interview.qa = response.qa;
               interview.removed = response.removed;
               LOG.debug('evaluate ', JSON.stringify(response));
           }

           return interview;
       });
    }

    async get(type: string, id: number) {
        try {
            const response: any = await this.http.get(ServiceType.INTERVIEW, 'get-interview', { type, id })
            const interview: Interview = new Interview();
            interview.type = type;
            if (response) {
                interview.id = response.id;
                interview.qa = response.qa;
                interview.removed = response.removed;
                this.willAttributes.set('charity', response.willAttributes.charity);
                this.willAttributes.set('lawfirm', response.willAttributes.lawfirm);
            }

            return interview;
        } catch (err) {
            LOG.error(`Error launching interview edit: ${err}`);
        }
        return null;
    }

    /**
     * Invokes edit on the given interview ID to download the previously answered questions and display data
     *
     * @return the loaded interview object
     * @param type
     * @param id
     */
    async edit(type: string, id: number): Promise<Interview> {
        try {
            const response: any = await this.http.get(ServiceType.INTERVIEW, 'edit-interview', { type, id })
            const interview: Interview = new Interview();
            interview.type = type;
            if (response) {
               interview.id = response.id;
               interview.qa = response.qa;
               interview.removed = response.removed;
               this.willAttributes.set('charity', response.willAttributes.charity);
               this.willAttributes.set('lawfirm', response.willAttributes.lawfirm);
            }
            return interview;

        } catch (err) {
            LOG.error(`Error launching interview edit: ${err}`);
        }

        return null;
    }

    /**
     * Completes the interview and triggers document and report generation
     */
    async complete(type: string, id: number): Promise<Interview> {
        return this.http.post(ServiceType.INTERVIEW, 'complete-interview', null, {type, id})
           .then((response: any) => {
               LOG.debug('complete ', JSON.stringify(response));
               const interview = new Interview();
               interview.type = type;
               if (!!response && !response.error) {
                   interview.id = response.id;
                   LOG.debug('complete ', JSON.stringify(response));
               }

               return interview;
           });
    }

    /**
     * Completes the interview and triggers document and report generation
     */
    async completeProcess(type: string, id: number): Promise<Interview> {
        return this.http.post(ServiceType.INTERVIEW, 'complete-process', {interviewId: id}, {type, id})
           .then((response: any) => {
               LOG.debug('completeProcess ', JSON.stringify(response));
               const interview = new Interview();
               interview.type = type;
               if (!!response && !response.error) {
                   interview.id = response.id;
                   LOG.debug('completeProcess ', JSON.stringify(response));
               }

               return interview;
           });
    }

    async hasAccess(type: string, id: any): Promise<boolean> {
        return this.http.get(ServiceType.INTERVIEW, 'check-access-to-interview', { type, id });
    }

    async isComplete(type: string, id: any): Promise<boolean> {
        return this.http.get(ServiceType.INTERVIEW, 'check-interview-is-complete', { type, id });
    }

    async hasWill(type: any): Promise<boolean> {
        try {
            return this.http.get(ServiceType.INTERVIEW, 'check-has-existing-will', { type });
        } catch (err) {
            LOG.error(`Error checking if the user already has an Will... ${err}`);
        }
        return false;
    }
}
