import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { MessageService } from '../../../core/message/message.service';
import { BaseForm } from '../../../core/ui/base-form/base-form.component';
import { PageUtil } from '../../../core/util/page.util';
import { RulesService } from '../../../core/services/rules.service';
import { InterviewService } from '../../../core/services/interview.service';
import {ApplicationType, ENV} from "../../../models/environment.model";

const LOG: LoggerService = new LoggerService('InterviewCompleteComponent');

@Component({
    selector: 'app-interview-cannot-complete',
    templateUrl: './interview-cannot-complete.component.html',
    styleUrls: ['./interview-cannot-complete.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class InterviewCannotCompleteComponent extends BaseForm implements OnInit {

    type: string;
    interviewId: number;
    myWishesUrl: string;

    constructor(
        private router: Router,
        private title: Title,
        protected messageService: MessageService,
        protected changeDetector: ChangeDetectorRef,
        protected interviewService: InterviewService,
        protected cookieService: CookieService,
        protected authenticationService: AuthenticationService,
        public rulesService: RulesService,
        private route: ActivatedRoute
    ) {
        super(messageService, changeDetector, cookieService, authenticationService, rulesService);
        this.title.setTitle('Will Interview : Complete');

        PageUtil.setFocus('interviewCompleteHeader');
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.myWishesUrl =  ENV.applications.get(ApplicationType.HOME).baseUrl + '/my-wishes';

        this.route.params.subscribe((params: { id: number; type: string; action: string }) => {
            this.type = params.type;
            this.interviewId = params.id;

            this.interviewService.completeProcess('WILL', this.interviewId).then(() => LOG.info('init', 'Process completed!'));
        });
    }

    listOfWills(): void {
        window.location.href = `${this.homeAppUri}/s/storage`;
    }
}
