/**
 * Utility class providing a way to validate a number of different data types
 *
 * @author Dan Bennett (dbennett)
 */
export class ValidationUtils {

    static isNotBlank(value: string): boolean {
        return this._isNotEmpty(value);
    }

    static isBlank(value: string): boolean {
        return this._isEmpty(value);
    }

    static listIsNotEmpty(value: any[]): boolean {
        return !this.listIsEmpty(value);
    }

    static listIsEmpty(value: any[]): boolean {
        return !value || value.length === 0;
    }

    static isAnyBlank(...value: string[]): boolean {
        return this._isEmpty(value) || value.findIndex((v: string) => this._isEmpty(v)) > -1;
    }

    static isEqual(value1: any, value2: any): boolean {
        return value1 === value2;
    }

    static isNotEqual(value1: any, value2: any): boolean {
        return !ValidationUtils.isEqual(value1, value2);
    }

    static valid(value: string, regex: any): boolean {
        return this._isNotEmpty(value) && this._isValid(value, regex);
    }

    static validNumber(value: string): boolean {
        return this._isNotEmpty(value) && this._isValid(value, '(^[-]?[0-9]*[.]?[0-9]+$)');
    }

    /**
     * Private function to validate number to two decimal places
     *
     * @param value - value to validate
     */
    static validNumberTwoDecimalPlaces(value: string): boolean {
        return this._isNotEmpty(value) && this._isValid(value, '^[1-9][0-9]?([.][0-9]{1,2})?$|^[0-9][.][0-9]{1,2}$');
    }

    static validPercentage(value: string): boolean {
        return this._isNotEmpty(value) && this._isValid(value, '(^[-]?[0-9]*[.]?[0-9]+$)|(^[-]?[0-9]*[.]?[0-9]+%$)');
    }

    /**
     * Private function to validate percentage value is not over 100
     *
     * @param value - value to validate
     */
    static validPercentageValue(value: string): boolean {
        return this._isNotEmpty(value) && this._isValid(value, '^[1-9][0-9]?$|^100$');
    }

    /**
     * Private function to validate percentage value is not over 100 to two decimal places
     *
     * @param value - value to validate
     */
    static validPercentageTwoDecimalPlaces(value: string): boolean {
        return this._isNotEmpty(value) && this._isValid(value, '^(100.00|100.0|100)$|^[1-9][0-9]?([.][0-9]{1,2})?$|^[0-9][.][0-9]{1,2}$');
    }

    static validEmail(value: string): boolean {
        return this._isNotEmpty(value) && this._isValid(value, '^([\\w\\-\\.]+)([+]?)([\\w\\-\\.]+)@(([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)([ ]*)$');
    }

    static validPostCode(value: string): boolean {
        return this._isNotEmpty(value) && this._isValid(value, '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2})$');
    }

    static endsWith(value: string, suffix: string): boolean {
        return this._hasSuffix(value, suffix);
    }

    static validMaxLength(value: string, maxLength: number): boolean {
        return value.length <= maxLength;
    }

    static validMinLength(newValue: string, minLength: number): boolean {
        return newValue && newValue.length >= minLength;
    }

    static validCurrency(value: any): boolean {
        return this._isNotEmpty(value) && this._isValid(value, '^(-?)([^0-9]?)([0-9]*[0-9\u002e]+)$');
    }

    static startsWith(value: string, test: string): boolean {
        return this._isNotEmpty(value) && value.startsWith(test);
    }

    /**
     * Private function to check for a blank or null value
     */
    private static _isEmpty(value: any): boolean {
        return !this._isNotEmpty(value);
    }

    /**
     * Private function to check for a value in a string
     *
     * @param value - value to validate
     */
    private static _isNotEmpty(value: any): boolean {
        return (value != null && (value !== '') && value.length > 0);
    }

    /**
     * Private function to valid a value against a regular expression
     *
     * @param value - value to validate
     * @param regex - regular expression to use for validation
     */
    private static _isValid(value: string, regex: any): boolean {
        return new RegExp(regex).test(value);
    }

    /**
     * Private function to check the suffix of a value
     *
     * @param value - value to validate
     * @param suffix
     */
    private static _hasSuffix(value: string, suffix: string): boolean {
        return value.indexOf(suffix, value.length - suffix.length) !== -1;
    }

}
