<ng-template #modal>
  <div class="popup-window">
    <div class="modal-header">
      <h5 tabindex="0" id="confirm-title" class="modal-title">{{ modalConfig.modalTitle }}</h5>
    </div>
    <div tabindex="0" class="modal-body">
      <ng-content></ng-content>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary rounded-pill submit" (click)="confirm()"
              *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
              [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
        {{ modalConfig.confirmButtonLabel }}
      </button>
      <button type="button" class="btn btn-secondary rounded-pill cancel pull-right" (click)="dismiss()"
              *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
              [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
        {{ modalConfig.dismissButtonLabel }}
      </button>
    </div>
  </div>
</ng-template>
