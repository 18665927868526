<div class="container-fluid">
  <div class="row" *ngIf="!consent && !loading && !skip">
    <div id="confirmation-panel" class="content-panel col">
      <div class="row">
        <div class="col" tabindex="-1">
          <h1 id="confirmation-panel-title" class="underline" tabindex="-1">{{ messages.getText('title.interview.give-to-charity') }}</h1> <!-- aria -->
          <div id="info-text" [innerHTML]="messages.getText('body.interview.give-to-charity')"></div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button type="button" class="btn btn-primary rounded-pill submit btn-lg"
                  (click)="navigateToDonate()"
                  (keyup.enter)="navigateToDonate()"
                  (keyup.space)="navigateToDonate()"
          >
            {{ messages.getText('btn.confirm.give-to-charity') }}
          </button>
          <br>
          <button type="button" class="btn btn-outline-secondary rounded-pill"
                  (click)="navigateToComplete()"
                  (keyup.enter)="navigateToComplete()"
                  (keyup.space)="navigateToComplete()"
          >
            {{ messages.getText('btn.decline.give-to-charity') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="(consent || skip) && !loading">
    <div class="content-panel col">
      <div class="row">
        <div class="col">
          <h1 id="donate-title" class="underline" tabindex="-1">{{ messages.getText('title.interview.giving-to-charity') }}</h1> <!-- aria -->
        </div>
      </div>

      <!-- Display a single charity if an initiator is set -->
      <div class="row" *ngIf="!!charity">
        <p [innerHtml]="messages.getTextInterpolate('body.interview.give-to-initiator-charity', [charity.name])"></p>
      </div>
      <charity-display *ngIf="!!charity"
                       [charity]="charity"
                       [interviewId]="interviewId"
                       [confirmDonationTrigger]="confirmDonationTrigger"
      ></charity-display>

      <!-- Display a list of charities -->
      <div *ngIf="!!charities" id="charity-search-panel" class="row" [class.extra-space]="!!charity">
        <p id="select-charity-msg" *ngIf="!!charity">{{ messages.getText('label.search-for-charity.2') }}</p>
        <p id="select-charity-msg" *ngIf="!charity">{{ messages.getText('body.interview.select-charity') }}</p>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">{{ messages.getText('search.placeholder') }}</span>
          </div>
          <input type="text" class="form-control"
                 [(formControl)]="searchControl"
                 [placeholder]="messages.getText('label.find-your-charity')"
                 [attr.aria-label]="messages.getText('label.find-your-charity')">
        </div>
        <div id="search-results" class="container">
          <charity-display *ngFor="let charity of charities"
                   [charity]="charity"
                   [interviewId]="interviewId"
                   [confirmDonationTrigger]="confirmDonationTrigger"
          ></charity-display>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button type="button" class="btn btn-outline-secondary rounded-pill"
                  (click)="navigateToComplete()"
                  (keyup.enter)="navigateToComplete()"
                  (keyup.space)="navigateToComplete()"
          >
            {{ messages.getText('btn.changed-mind.give-to-charity') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmationModal let-confirmationModal>
  <charity
    [userId]="user?.userId"
    [interviewId]="interviewId"
    [charity]="selectedCharity"
    [modal]="confirmationModal"
    [paymentMadeTrigger]="paymentMadeTrigger"
    [paymentErrorTrigger]="paymentErrorTrigger"
    [paymentCancelledTrigger]="paymentCancelledTrigger"
  ></charity>
</ng-template>
