import { Injectable } from '@angular/core';
import { CoreModule } from '../../core.module';
import { AbstractTokenService } from '../abstract-token.service';
import { APPLICATION_CONSTANTS } from '../../../models/application.constants';
import { CookieService } from 'ngx-cookie-service';

/**
 * Service to handle abstract token maintenance / decoding
 *
 * @author Dan Bennett (dbennett)
 */
@Injectable({
    providedIn: CoreModule
})
export class AnonymousTokenService extends AbstractTokenService {

    constructor(cookieService: CookieService) {
        super(APPLICATION_CONSTANTS.anonymousAuthToken, cookieService);
    }

}
