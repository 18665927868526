import { ENV } from "../../models/environment.model";
import { DateUtils } from "../util/date.utils";

export const LogLevel = [
    'TRACE',
    'DEBUG',
    'INFO',
    'WARN',
    'ERROR',
    'NONE'
];

/**
 * Service to wrap any logging statements to allow an easy way to turn it off on production
 *
 * @author Dan Bennett (dbennett)
 * @author Paul Thorp (pthorp)
 */
export class LoggerService {

    private level: number;

    constructor(private className: string) {
        this.level = LogLevel.indexOf(ENV.config.get('logLevel')  || 'DEBUG');
    }

    static get = (name: string) => new LoggerService(name);

    prettyPrint(message: string | any): string {
        if (typeof message === 'string') {
            message = (() => {
                try {
                    return JSON.parse(message);
                } catch {
                    return message;
                }
            })();
        }
        return JSON.stringify(message, null, '  ');
    }

    /**
     * Used for very low level developer logging i.e. inside POJSOs etc
     *
     * @param functionName
     * @param message
     */
    trace(functionName: string, message: string): void {
        if (this.levelEnabled('TRACE')) {
            this.log(`[${this.className}::${functionName}] ${message}`, 'TRACE');
        }
    }

    /**
     * Used for development / test logging i.e. enter / exit component etc
     *
     * @param functionName
     * @param message
     */
    debug(functionName: string, message: string): void {
        if (this.levelEnabled('DEBUG')) {
            this.log(`[${this.className}::${functionName}] ${message}`, 'DEBUG');
        }
    }

    /**
     * Used for informational logging that should shown on production servers
     * - Keep this to a minimum! (If in doubt, use debug)
     *
     * @param functionName
     * @param message
     */
    info(functionName: string, message: string): void {
        if (this.levelEnabled('INFO')) {
            this.log(`[${this.className}::${functionName}] ${message}`, 'INFO');
        }
    }

    /**
     * Used for displaying warning logging within the application. This is shown on a production level
     *
     * @param message
     */
    warn(message: string): void {
        if (this.levelEnabled('WARN')) {
            this.log(message, 'WARN');
        }
    }

    /**
     * Used for displaying error logging within the application. This is shown on a production level
     *
     * @param message
     */
    error(message: string): void {
        if (this.levelEnabled('ERROR')) {
            this.log(message, 'ERROR');
        }
    }

    private log(message: string, level: any): void {
        const now: Date = new Date();
        const msg: string = `[${DateUtils.formatDate(now)} ${DateUtils.formatTime(now)}] ${level} ${message}`;

        switch (level) {
            case 'TRACE':
            case 'DEBUG':
                console.debug(msg);
                break;
            case 'INFO':
                console.info(msg);
                break;
            case 'WARN':
                console.warn(msg);
                break;
            case 'ERROR':
                console.error(msg);
        }
    }

    /**
     * Checks the current log level against the level passed in
     *
     * @param level
     * @returns {boolean}
     */
    public levelEnabled(level: string): boolean {
        const ll: number = LogLevel.indexOf(level);
        return ll >= this.level;
    }
}
