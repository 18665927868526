/**
 * Utility for interacting with HTML
 */
export class HtmlUtils {

    /** Update a given CSS Variable */
    public static updateCss(variable: string, value: string): void {
        document.documentElement.style.setProperty(`--${variable}`, value);
    }

}
