<span *ngIf="!!question.messageCode">
  <p #label id="{{ question.id }}-label-content" class="field-label">{{ messages.getText(question.messageCode) }}</p>
</span>
<span *ngIf="!!question.noteMessageCode">
  <div id="{{ question.id }}-desc" class="field-description" [innerHTML]="messages.getText(question.noteMessageCode)"></div>
</span>
<div *ngIf="controlHasError()" class="alert-danger error pill form-error-list">
  <div id="{{ question.id }}-err" class="error-list-wrapper" [attr.aria-live]="'assertive'">
    <ul>
      <li *ngFor="let err of baseForm.errorMap.get(question.id)">
        <span>{{ determineErrorMessage(err.error, control, question) }}</span>
      </li>
    </ul>
  </div>
</div>
<ng-container *ngIf="!!control">
  <input *ngIf="(!question.locked || !!control?.value)" #input [attr.type]="inputType"
         [attr.name]="question.id"
         [attr.id]="question.id"
         class="form-control {{inputClass}}"
         [attr.autocomplete]="determineAutocompleteValue(question.id)"
         [required]="question.required" [attr.aria-required]="question.required" [attr.aria-invalid]="controlHasError()"
         [attr.min]="question.min" [attr.max]="question.max"
         [(formControl)]="control"
         (keydown.enter)="onEnter($event)"
         (blur)="revalidateInput()"
         (input)="setModelValue($event);revalidateInput()"
         [attr.aria-labelledby]="getAriaLabels('label')"
         [attr.aria-label]="getLabel()"
         [ngClass]="{ 'is-invalid': controlHasError() }"/>
  <div #feedback
       *ngIf="!!control.validator && (control.touched || baseForm.isSubmitted())"
       class="feedback-icon" aria-hidden="true">
    <i class="fa" aria-hidden="true"
       [ngClass]="{ 'fa-check-circle' : !controlHasError(), 'fa-times-circle' : controlHasError() }"></i>
  </div>
  <ng-content></ng-content>
</ng-container>
