import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationService } from './core/authentication/authentication.service';
import { TokenService } from './core/authentication/token.service';
import { LoggerService } from './core/logger/logger.service';
import { MessageService } from './core/message/message.service';

const LOG: LoggerService = LoggerService.get('AppService');

/**
 * Service to handle basic application functions and service calls
 *
 * @author Dan Bennett (dbennett)
 * @author Paul Thorp (pthorp)
 */
@Injectable({
    providedIn: 'root'
})
export class AppService {

    startedInitialising: boolean;
    messagesLoaded: boolean;

    appLoaded: EventEmitter<boolean> = new EventEmitter();

    constructor(private messageService: MessageService,
                private tokenService: TokenService,
                private authenticationService: AuthenticationService) {
        this.startedInitialising = false;
        this.messagesLoaded = false;
    }

    initialiseApp(): void {
        if (this.startedInitialising || this.isLoaded()) {
            this.emitLoadedEvent();
            return;
        }
        this.startedInitialising = true;

        const loggedIn: boolean = this.authenticationService.isLoggedIn();
        AuthenticationService.authenticated.next(loggedIn);
        LOG.debug('initialiseApp', `Logged In = ${loggedIn}`);
        // Reset flags to be re-updated after service calls
        this.messagesLoaded = false;
        this.loadMessages();
    }

    async loadMessages(): Promise<void> {
        LOG.trace('initialiseApp', 'Loading message bundle...');
        const response: boolean | void = await this.messageService.retrieveMessageBundle()
            .catch((error: any) => {
                LOG.error(`Error!\n${error}`);
            });

        if (response) {
            this.messagesLoaded = response;

            this.emitLoadedEvent();
        }

    }

    isLoaded(): boolean {
        return this.messagesLoaded;
    }

    // Emit the event when reference data is loaded
    emitLoadedEvent(): void {
        this.appLoaded.emit(this.isLoaded());
    }
}
