import { Component, ViewEncapsulation } from '@angular/core';
import { Option } from '../../../../models/navigator.model';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { PageUtil } from '../../../util/page.util';
import { BaseInputControl } from '../base-input-control/base-input-control.component';

const LOG: LoggerService = LoggerService.get('BaseSelectionControl');

/**
 * Base control for all select and multi-select controls
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'base-selection-control',
    templateUrl: './base-selection-control.component.html',
    styleUrls: [
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class BaseSelectionControl extends BaseInputControl {

    constructor(messageService: MessageService) {
        super(messageService);
        this.LOG = LOG;
    }

    setModelValue(val: any): void {
        this.control.setValue(val);
        this.modelValue = val;
        this.modelValueChange.emit(this.modelValue);
    }

    focus(): void {
        const nativeElement: any = PageUtil.findNativeElement(`${this.question.id}-${this.convertWhitespaces(this.getFirstOption().value)}`);
        if (nativeElement) {
            nativeElement.focus();
        }
    }

    getFirstOption(): Option {
        return this.question.optionsList[0];
    }
}
