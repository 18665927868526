import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggerService } from '../logger/logger.service';
import { MessageService } from '../message/message.service';
import { ConfigService } from "./config.service";
import { ThreadUtils } from "../util/thread.utils";
import { Application, ApplicationType, ENV, Service, ServiceType } from "../../models/environment.model";

const LOG: LoggerService = LoggerService.get('LoaderService');

declare function initIntercom(appId: string): void;

/**
 * Service to handle basic application functions and service calls
 *
 * @author Dan Bennett
 */
@Injectable({
    providedIn: 'any'
})
export class LoaderService {

    startedInitialising = false;
    configLoaded = false;
    messagesLoaded = false;
    loadingAttempts: number = 0;

    appLoaded: EventEmitter<boolean> = new EventEmitter();

    constructor(private messageService: MessageService,
        private configService: ConfigService,
        private authenticationService: AuthenticationService) {
        this.loadingAttempts = 0;
    }

    initialiseApp(): Promise<boolean> {

        return new Promise<boolean>((resolve: any) => {
            this.startedInitialising = true;

            const loggedIn = this.authenticationService.isLoggedIn();
            AuthenticationService.authenticated.next(loggedIn);
            LOG.debug('initialiseApp', 'Logged In = ' + loggedIn)
            // Reset flags to be re-updated after service calls
            this.messagesLoaded = false;
            this.configLoaded = false;

            this.loadConfiguration().then((loaded: boolean) => {
                this.configLoaded = loaded;

                LOG.debug('initialiseApp', `Loaded config:\n\n${JSON.stringify(ENV)}\n\n`)
                this.loadMessages().then((loaded: boolean) => {
                    this.messagesLoaded = loaded;
                    resolve(this.isLoaded());
                    this.appLoaded.emit(true);
                });
                initIntercom(ENV.config.get('intercomAppId'));
            });
        });
    }

    async loadMessages(): Promise<boolean> {
        LOG.trace('loadMessages', `Loading message bundle...`);
        try {

            // ToDo :: Check if messages are stored in app store, retrieve new messages anyway but return from app store first as quicker
            const response = await this.messageService.retrieveMessageBundle();
            if (response) {
                // ToDo :: Store message bundle in app store
                return true;
            }
        } catch (e) {
            LOG.debug('loadMessages', `Failed to load the message bundle! ${e}`);
        }
        await ThreadUtils.sleep(500);
        return await this.loadMessages();
    }

    async loadConfiguration(): Promise<boolean> {
        let localConfigCache: any = localStorage.getItem('cache.env_config');
        if (!!localConfigCache && typeof localConfigCache === 'string') {
            localConfigCache = JSON.parse(localConfigCache);
        }
        if (!!localConfigCache?.configuration && new Date(localConfigCache.expires) > new Date()) {
            this.buildEnv(localConfigCache.configuration);
            setTimeout(() => {
                this.loadConfigurationFromBackend().then(() => LOG.debug('loadConfiguration', 'Loaded configuration asynchronously!'));
            }, 100);
            return true;
        }

        return await this.loadConfigurationFromBackend();
    }

    async loadConfigurationFromBackend(): Promise<boolean> {
        LOG.trace('loadConfiguration', `Loading service and app configuration...`);
        try {
            const response: any = await this.configService.loadEnvironment();
            if (response) {

                const expires: Date = new Date();
                expires.setDate(expires.getDate() + 7);

                localStorage.setItem('cache.env_config', JSON.stringify({ configuration: response, expires: expires.getTime() }));
                this.buildEnv(response);
                return true;
            }
        } catch (e) {
            LOG.debug('loadConfiguration', `Failed to load the configuration! ${e}`);
        }
        // Wait a bit longer each time
        await ThreadUtils.sleep(++this.loadingAttempts * 300);
        return await this.loadConfiguration();
    }

    private buildEnv(response: any): void {
        ENV.services = new Map<ServiceType, Service>();
        Object.keys(response.services).forEach((key: string) => {
            const svc: any = response.services[key];
            const service: Service = {
                baseUrl: svc.baseUrl,
                tokenHeader: svc.tokenHeader,
                api: svc.api
                // api: new Map<string, string>()
            };
            // ToDo :: Re-add
            // if (svc.api) {
            //     Object.keys(svc.api).forEach((apiKey: string) => service.api.set(apiKey, svc.api[apiKey]));
            // }
            ENV.services.set(ServiceType[key as keyof typeof ServiceType], service);
        });

        ENV.applications = new Map<ApplicationType, Application>();
        Object.keys(response.applications).forEach((key: string) => {
            const app: any = response.applications[key];
            const application: Application = {
                baseUrl: app.baseUrl,
                loginRequired: app.loginRequired,
                api: app.api
                // api: new Map<string, string>()
            };
            // ToDo :: Re-add
            // if (app.api) {
            //     Object.keys(app.api).forEach((apiKey: string) => application.api.set(apiKey, app.api[apiKey]));
            // }
            ENV.applications.set(ApplicationType[key as keyof typeof ApplicationType], application);
        });

        ENV.config = new Map<string, string>();
        Object.keys(response.config).forEach((key: string) => ENV.config.set(key, response.config[key]));
    }

    isLoaded(): boolean {
        return this.configLoaded && this.messagesLoaded;
    }
}
