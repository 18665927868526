import { Injectable } from '@angular/core';
import { WebServiceError } from '../../models/http.model';
import { LoggerService } from '../logger/logger.service';
import { AdviceOption, Answer } from '../../models/interview.model';
import { Topic } from '../../models/navigator.model';
import { ServiceType } from "../../models/environment.model";
import { HttpService } from "../http/http.service";

const LOG: LoggerService = LoggerService.get('RulesService');

/**
 * Integrates with the rules engine to provide components for the interview
 *
 * @author Dan Bennett (dbennett)
 */
@Injectable({
    providedIn: 'root'
})
export class RulesService {

    constructor(private http: HttpService) {}

    async getBaseNavigator(): Promise<any> {
        return this.http.get(ServiceType.RULES_ENGINE, 'get-base-navigator')
           .then((response: any | WebServiceError) => {
               if (response instanceof WebServiceError) {
                   LOG.error('Error retrieving navigator!');
                   return null;
               }
               return {
                   currentTopic: response.currentTopic,
                   currentSubTopic: response.currentSubTopic,
                   completed: response.completed,
                   topics: response.topics.map((t: any) => new Topic(t)).sort((a: Topic, b: Topic) => a.order > b.order)
               };
           })}

    async getNavigator(qa: Answer[]): Promise<any> {
        if (!qa) {
            return this.getBaseNavigator();
        }

        const answers: any = {};
        qa.forEach((q: any) => {
            let answer = answers[q.question];
            if (!answer) {
                answer = (answers[q.question] = []);
            }
            answer.push(q.answer);
        });

        return this.http.post(ServiceType.RULES_ENGINE, 'get-topic-navigator', answers)
            .then((response: any | WebServiceError) => {
               if (response instanceof WebServiceError) {
                   LOG.error('Error retrieving navigator!');
                   return null;
               }
               return {
                   currentTopic: response.currentTopic,
                   currentSubTopic: response.currentSubTopic,
                   completed: response.complete,
                   topics: response.topics.map((t: any) => new Topic(t)).sort((a: Topic, b: Topic) => a.order > b.order)
               };
           });
    }

    async validate(validations: string[], qa: any): Promise<Map<string, string[]> | WebServiceError> {
        return this.http.post(ServiceType.RULES_ENGINE, 'validate', { validations, qa })
                   .then((response: WebServiceError | any) => {
                       if (response instanceof WebServiceError || response.errors?.length === 0) {
                           return null;
                       }
                       const errors: Map<string, string[]> = new Map<string, string[]>();
                       Object.keys(response.errors)
                             .forEach((key: string) => {
                                 if (response.errors[key]?.length) {
                                     errors.set(key, response.errors[key]);
                                 }
                             });
                       return errors;
                   });
    }

    async validateAll(qa: Answer[]): Promise<Map<string, string[]>> {
        const request: any = {};
        // Clone QA for validation request
        qa.forEach((answer: Answer) => {
            if (!request[answer.question]) {
                request[answer.question] = [];
            }
            request[answer.question].push(answer.answer);
        });

        return this.http.post(ServiceType.RULES_ENGINE, 'validate-all', request)
            .then((response: WebServiceError | any) => {
                if (response instanceof WebServiceError || response.errors?.length === 0) {
                    return null;
                }
                const errors: Map<string, string[]> = new Map<string, string[]>();
                Object.keys(response.errors)
                    .forEach((key: string) => {
                        if (response.errors[key]?.length) {
                            errors.set(key, response.errors[key]);
                        }
                    });
                return errors;
            })
    }

    async getAdviceRecommendations(qa: Answer[]): Promise<AdviceOption[]> {
        const answers: any = {};
        qa.forEach((q: any) => {
            let answer = answers[q.question];
            if (!answer) {
                answer = (answers[q.question] = []);
            }
            answer.push(q.answer);
        });

        return this.http.post(ServiceType.RULES_ENGINE, 'advice', answers)
            .then((response: any | WebServiceError) => {
                if (response instanceof WebServiceError) {
                    LOG.error('Error retrieving Advice recommendations!');
                    return null;
                }
                return response;
            });
    }

}
