import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { BaseInputControl } from '../base-input-control/base-input-control.component';

const LOG: LoggerService = LoggerService.get('TextControl');

/**
 * Basic text input field component with label, description element, validation message area and the input element styling (feedback icon, border change)
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'text-control',
    templateUrl: '../base-input-control/base-input-control.component.html',
    styleUrls: [
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class TextControl extends BaseInputControl implements OnInit {

    constructor(messageService: MessageService) {
        super(messageService);
        this.inputType = 'text';
        this.LOG = LOG;
    }

    ngOnInit(): void {
        this.determineValidators();
        super.ngOnInit();
    }
}
