import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core/core.module';
import { UIModule } from '../../core/ui/ui.module';
import { CharityGivingComponent } from './charity-giving-component/charity-giving.component';

/**
 * Module to handle interaction with the PayPal Giving app
 *
 * @author Dan Bennett (dbennett)
 */
@NgModule({
    declarations: [
        CharityGivingComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        // Shared application modules
        CoreModule,
        UIModule,
        NgbModule
    ]
})
export class CharityModule {
}
