import { Component, Input } from '@angular/core';
import { Group, Question } from '../../../../models/navigator.model';
import { MessageService } from '../../../message/message.service';

/**
 * Displays the output of a control in READONLY format
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'display-control',
    templateUrl: './display-control.component.html',
    styleUrls: ['./display-control.component.sass']
})
export class DisplayControl {

    @Input() first: boolean;
    @Input() question: Question;
    @Input() group: Group;
    @Input() answer: string | string[];

    constructor(private messageService: MessageService) {
    }

    get messages(): MessageService {
        return this.messageService;
    }

    get value(): string {
        if (typeof this.answer === 'string') {
            return this.answer;
        }
        return this.answer.join(', ');
    }

    get optionValue(): string {
        return this.question.optionsList
                   .filter(o => this.answer.indexOf(o.value) > -1)
                   .map(o => this.messages.getText(o.messageCode))
                   .join(', ');
    }

}
