<span *ngIf="!!question.messageCode">
  <p #label id="{{ question.id }}-label-content" class="field-label">{{ messages.getText(question.messageCode) }}</p>
</span>
<span *ngIf="!!question.noteMessageCode">
  <div id="{{ question.id }}-desc" class="field-description" [innerHTML]="messages.getText(question.noteMessageCode)"></div>
</span>
<div *ngIf="question.locked !== true && controlHasError()" class="alert-danger error pill form-error-list">
  <div id="{{ question.id }}-err" class="error-list-wrapper" [attr.aria-live]="'assertive'">
    <ul>
      <li *ngFor="let err of baseForm.errorMap.get(question.id)">
        <span>{{ determineErrorMessage(err.error, control, question) }}</span>
      </li>
    </ul>
  </div>
</div>
<!-- make sure aria attributes and other are configurable -->
<div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text">£</span>
  </div>
<input *ngIf="(!question.locked || !!control?.value)" #input [attr.type]="inputType"
       integer
       [attr.name]="question.id"
       [attr.id]="question.id"
       class="form-control {{inputClass}}"
       [attr.autocomplete]="determineAutocompleteValue(question.id)"
       [required]="question.required" [attr.aria-required]="question.required" [attr.aria-invalid]="controlHasError()"
       [attr.max]="question.max" [attr.min]="question.min"
       [(formControl)]="control"
       (keydown.enter)="onEnter($event)"
       (blur)="revalidateInput()"
       [readOnly]="question.locked"
       (change)="setModelValue($event)"
       [attr.aria-labelledby]="getAriaLabels('label')"
       [attr.aria-label]="getLabel()"
       [ngClass]="{ 'is-invalid': controlHasError(), 'question.locked' : question.locked  }"/>
</div>
<div #feedback
     *ngIf="!!control.validator && question.locked !== true && (control.touched || baseForm.isSubmitted())"
     class="feedback-icon" aria-hidden="true">
  <i class="fa" aria-hidden="true"
     [ngClass]="{ 'fa-check-circle' : !controlHasError(), 'fa-times-circle' : controlHasError() }"></i>
</div>
<ng-content></ng-content>


