<a href="{{ getCurrentPage() }}#main" id="skipToContent" class="skip-link">Skip to main content</a>
<header>
  <!-- only for screen-readers -->
  <p class="sr-only" aria-live="polite">{{ title.getTitle() }}</p>
  <nav class="navbar navbar-expand-md" role="banner">
    <div class="container" role="navigation">
      <!-- Brand -->
      <div id="headerLogoWrapper" class="navbar-brand">
        <a href="{{ homeAppUri }}" id="main-logo">
          <img [src]="logoSrc" alt="Home Page"/>
        </a>
      </div>
      @if (branded) {
        <span id="powered-by">Powered By <br><img alt="Make your plan for good" src="assets/img/Bequeathed_Logo_Strapline_MYPFG.png"></span>
      }
      <!-- Toggler/collapsible Button -->

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
              aria-label="Navigation button">
        <div></div>
        <div></div>
        <div></div>
      </button>
      <!-- Navbar links -->
      <div [ngbCollapse]="menuCollapsed" class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link primary rounded-pill" href="{{homeAppUri}}/my-wishes">My wishes</a>
          </li>
          <li class="nav-item" *ngIf="currentUser">
            <a class="nav-link secondary rounded-pill" href="javascript:;" (click)="logout()">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <nav class="breadcrumb-bar" aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="{{ homeAppUri }}">Home</a></li>
        <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs">
          <span *ngIf="breadcrumb.active" [class.active]="breadcrumb.active">{{ breadcrumb.name }}</span>
          <a *ngIf="!breadcrumb.active" href="{{ breadcrumb.url }}">{{ breadcrumb.name }}</a>
        </li>
      </ol>
    </div>
  </nav>
</header>

<main id="main" class="container clear-top">
  <a id="main-focusable" tabindex="-1">
    <!-- IE11 workaround to handle skip links -->
  </a>
  <router-outlet></router-outlet>
</main>

<footer class="footer mt-auto">
  <div id="banner-cookies" *ngIf="!areCookiesAccepted()" class="cookies">
    <div class="container-fluid">
      <a href="javascript:;" class="btn-clear" (click)="closeCookieBanner()">x</a>
      <p>{{ messages.getText('label.cookiebanner.text')}}
        <a href="/i/privacy-policy">Privacy Policy</a>
      </p>
      <a href="javascript:;" class="accept" (click)="acceptCookies()">{{messages.getText('label.cookiebanner.accept')}}</a>
    </div>
  </div>
  <div class="container" [innerHTML]="footer | safeHtml">
    <!-- S3 footer.html goes here -->
  </div>
</footer>
