import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Option } from '../../../../models/navigator.model';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { CheckboxValidator } from '../../validators/checkbox.validator';
import { BaseSelectionControl } from '../base-selection-control/base-selection-control.component';

const LOG: LoggerService = LoggerService.get('CheckboxControl');

/**
 * Control to display checkboxes
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'checkbox-control',
    templateUrl: './checkbox-control.component.html',
    styleUrls: [
        './checkbox-control.component.sass',
        '../../base-form/base-form.component.sass',
        '../base-input-control/base-input-control.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class CheckboxControl extends BaseSelectionControl implements OnInit {

    private normalizedControlName: string;

    constructor(messageService: MessageService) {
        super(messageService);

        this.inputClass = '';
        this.LOG = LOG;
    }

    ngOnInit(): void {
        this.normalizedControlName = this.question.id.replace(/\./g, '').replace(/_/g, '');

        if (this.question.required && this.question.multiple) {
            this.validators.push(CheckboxValidator.requiredCheckboxGroupValidator);
        } else if (this.question.required) {
            this.validators.push(CheckboxValidator.singleRequiredCheckboxGroupValidator);
        }
        this.previousAnswer = this.baseForm.getAnswer(this.question.id);
        const qControl: UntypedFormArray = new UntypedFormArray([], { validators: this.validators });
        this.question.optionsList.forEach((option: Option) => {
            const control: UntypedFormControl = new UntypedFormControl((this.previousAnswer as string[]).indexOf(option.value) > -1);
            control['id'] = option.value;
            qControl.push(control);
        });
        this.control = qControl;

        super.ngOnInit();
    }

    isChecked(): boolean {
        return this.control.value;
    }

    isHoriz(): boolean {
        return this.inputClass === 'options-wrapper-horizontal';
    }

    get formArray() {
        return this.control as UntypedFormArray;
    }

    getInputId(i: number): string {
        return 'collapsibleCheckboxDescription-' + this.normalizedControlName + '-' + i;
    }

    getInputValue(option: Option): string {
        return `${this.question.id}###${option.value}`;
    }

    uncheckOthers(optionKey: string, i: number) {
        if (this.question.multiple) {
            return;
        }
        const ctrlArray = (this.control as UntypedFormArray).controls;
        const ctrl = ctrlArray[i];
        if (ctrl.value) {
            for (let j = 0; j < ctrlArray.length; j++) {
                if (j !== i) {
                    ctrlArray[j].setValue(false);
                }
            }
        }
    }

    getValue(): string[] {
        const checkedControls: AbstractControl[] = this.formArray.controls.filter((cntl: AbstractControl) => cntl.value === true);
        if (this.question.multiple) {
            return checkedControls.map(cntrl => cntrl['id']);
        }
        if (checkedControls.length === 0) {
            return null;
        }
        // If not multiple, there should only be one value
        return [checkedControls[0]['id']];
    }
}
