import { Injectable } from '@angular/core';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { TokenService } from "../authentication/token.service";
import { WebServiceError } from "../../models/http.model";
import { Advice } from "../../models/advice.model";
import { ServiceType } from "../../models/environment.model";
import { User } from "../../models/user.model";
import { HttpService } from "../http/http.service";
import { of } from "rxjs";
import { delay } from "rxjs/operators";

const LOG: LoggerService = LoggerService.get('AdviceService');

/**
 * Saves and retrieves advice options and responses to / from the backend
 */
@Injectable({
    providedIn: 'root'
})
export class AdviceService {

    constructor(
        private http: HttpService,
        private tokenService: TokenService) {}

    get user(): User {
        return this.tokenService.getUser();
    }

    async isComplete(id: any): Promise<boolean> {
        return this.http.get(ServiceType.INTERVIEW, 'check-interview-and-advice-is-complete', { id });
    }

    async start(id: number, options: string[]): Promise<Advice> {
        const params = {
            userId: this.user.userId,
            emailAddress: this.user.email,
            options: options
        };

        return this.http.get(ServiceType.INTERVIEW, 'start-advice', {interviewId:id}, params)
               .then((response: any | WebServiceError) => {
                   if (response instanceof WebServiceError) {
                       LOG.error('Error retrieving Advice Interview details!');
                       return null;
                   }

                   return of(
                       new Advice(response)
                   ).pipe(delay(3000)).toPromise(); // Delay to display analysing spinner
               });

    }

    async saveAnswers(interviewId: number, advice: Advice): Promise<boolean> {
        const adviceId = advice.id;
        return this.http.post(ServiceType.INTERVIEW, 'save-advice-answers', advice, {interviewId, adviceId} )
            .then((response: any | WebServiceError) => {
                   if (response instanceof WebServiceError) {
                       LOG.error('Error saving Advice recommendation answers!');
                       return null;
                   }
                   return response;
               });
    }

    async complete(interviewId: number, adviceId: number): Promise<boolean> {
        return this.http.post(ServiceType.INTERVIEW, 'complete-advice', null, {interviewId, adviceId})
           .then((response: any | WebServiceError) => {
               if (response instanceof WebServiceError) {
                   LOG.error('Error completing Advice interview!');
                   return null;
               }
               return response;
           });
    }
}
