import { Pipe, PipeTransform } from '@angular/core';
import { QuestionSummary } from "../../../../models/interview.model";

/**
 * Filters questions for the summary page if they are of HIDDEN type
 *
 * @author Dan Bennett (dbennett)
 */
@Pipe({
    name: 'notHidden'
})
export class NotHiddenPipe implements PipeTransform {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(value: QuestionSummary[], ...args: unknown[]): unknown {
        return value.filter(q => q.type !== 'HIDDEN');
    }

}
