<span *ngIf="!!question.messageCode">
  <p #label id="{{ question.id }}-label-content" class="field-label video-title">{{ messages.getText(question.messageCode) }}</p>
</span>
<span *ngIf="!!question.noteMessageCode">
  <div id="{{ question.id }}-desc" class="field-description video-question" [innerHTML]="messages.getText(question.noteMessageCode)"></div>
</span>

<form-control-info [id]="question.id" [items]="infoMessages"></form-control-info>
<!--<form-control-info [id]="question.id + '-info'" [items]="infoMessages"></form-control-info>-->

<div class="container">
  <div class="row align-items-center">
    <video class="video mt-1 mx-1" [hidden]="stage !== VideoStage.RECORDING && stage !== VideoStage.PAUSED" #video autoplay></video>
    <div class="video-time mb-1 mx-1 pl-2 py-1" [hidden]="stage !== VideoStage.RECORDING && stage !== VideoStage.PAUSED">
      {{ messages.getText("video.time-left") }}{{timeLeft * 1000 | date:'mm:ss' }}
    </div>
    <video class="video m-1" [hidden]="stage === VideoStage.RECORDING || stage !== VideoStage.COMPLETE" #recordedVideo controls></video>
  </div>
  <div class="option-row row align-items-center">
    <button *ngIf="stage !== VideoStage.RECORDING && stage !== VideoStage.PAUSED && stage !== VideoStage.SAVING && stage !== VideoStage.FAILED_TO_UPLOAD"
            class="btn btn-primary rounded-pill submit"
            [disabled]="stage === VideoStage.LOADING"
            (click)="startRecording()">
      <span *ngIf="stage === VideoStage.LOADING" class="spinner-border spinner-border-sm mr-1"></span>
      {{ messages.getText("video.start") }}
    </button>
    <button class="btn btn-secondary rounded-pill submit" *ngIf="stage === VideoStage.PAUSED" (click)="playPauseRecording()">
      {{ messages.getText("video.resume") }}
    </button>
    <button class="btn btn-secondary rounded-pill submit" *ngIf="stage === VideoStage.RECORDING" (click)="playPauseRecording()">
      {{ messages.getText("video.pause") }}
    </button>
    <button class="btn btn-primary rounded-pill submit mx-2" *ngIf="stage === VideoStage.RECORDING" (click)="stopRecording()">
      {{ messages.getText("video.stop") }}
    </button>
    <button class="btn btn-primary rounded-pill submit mx-2" *ngIf="stage === VideoStage.FAILED_TO_UPLOAD" (click)="onStopRecordingEvent()">
      {{ messages.getText("video.retry-upload") }}
    </button>
    <button *ngIf="stage !== VideoStage.RECORDING && stage !== VideoStage.SAVING" class="btn btn-primary rounded-pill submit mx-2" (click)="turnVideoOff()">
      {{ messages.getText("video.switch-to-text") }}
    </button>
  </div>
</div>

<div #feedback
     *ngIf="!!control.validator && question.locked !== true && (control.touched || baseForm.isSubmitted())"
     class="feedback-icon" aria-hidden="true">
  <i class="fa" aria-hidden="true"
     [ngClass]="{ 'fa-check-circle' : !controlHasError() || stage === VideoStage.COMPLETE, 'fa-times-circle' : controlHasError() && !stage }"></i>
</div>


<ng-content></ng-content>
