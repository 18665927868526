import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CharityDisplayComponent } from './charity-display/charity-display.component';
import { CharityComponent } from './charity/charity.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { ControlGroup } from './control-group/control-group.component';
import { BaseInputControl } from './control/base-input-control/base-input-control.component';
import { BaseSelectionControl } from './control/base-selection-control/base-selection-control.component';
import { CharityControl } from './control/charity-control/charity-control.component';
import { CharitySearchControl } from './control/charity-search-control/charity-search-control.component';
import { CheckboxControl } from './control/checkbox-control/checkbox-control.component';
import { CurrencyControl } from './control/currency-control/currency-control.component';
import { DateControl } from './control/date-control/date-control.component';
import { DisplayControl } from './control/display-control/display-control.component';
import { DropdownControl } from './control/dropdown-control/dropdown-control.component';
import { EmailControl } from './control/email-control/email-control.component';
import { NoteControl } from './control/note-control/note-control.component';
import { NumberControl } from './control/number-control/number-control.component';
import { PercentageControl } from './control/percentage-control/percentage-control.component';
import { PostcodeControl } from './control/postcode-control/postcode-control.component';
import { RadioButtonControl } from './control/radio-button-control/radio-button-control.component';
import { TextControl } from './control/text-control/text-control.component';
import { VideoControl } from "./control/video-control/video-control.component";
import { FormControlInfoComponent } from './form-control-info/form-control-info.component';
import { CurrencyOnlyDirective } from './input/currency-only/currency-only.directive';
import { IntegerOnlyDirective } from './input/integer-only/integer-only.directive';
import {LargeTextControl} from "./control/large-text-control/large-text-control.component";

/**
 * Module for Core components
 */
@NgModule({
    declarations: [
        ConfirmPopupComponent,

        CharityDisplayComponent,
        CharityComponent,

        BaseInputControl,
        NumberControl,
        CurrencyControl,
        PercentageControl,
        DateControl,
        PostcodeControl,
        EmailControl,

        NoteControl,

        IntegerOnlyDirective,
        CurrencyOnlyDirective,
        TextControl,
        LargeTextControl,
        RadioButtonControl,
        BaseSelectionControl,
        CheckboxControl,
        DropdownControl,
        ControlGroup,
        DisplayControl,
        CharityControl,
        CharitySearchControl,
        VideoControl,
        FormControlInfoComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    providers: [NgbModule],
    exports: [
        ConfirmPopupComponent,
        CharityComponent,
        CharityDisplayComponent,
        // Controls
        BaseInputControl,
        CharityControl,
        CharitySearchControl,
        CheckboxControl,
        ControlGroup,
        CurrencyControl,
        DateControl,
        DropdownControl,
        EmailControl,
        NoteControl,
        NumberControl,
        PercentageControl,
        PostcodeControl,
        RadioButtonControl,
        TextControl,
        LargeTextControl,
        VideoControl
    ]
})
export class UIModule {
}
