import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Option } from '../../../../models/navigator.model';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { BaseSelectionControl } from '../base-selection-control/base-selection-control.component';

const LOG: LoggerService = LoggerService.get('DropdownControl');

/**
 * Control to render a dropdown select
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'dropdown-control',
    templateUrl: './dropdown-control.component.html',
    styleUrls: [
        './dropdown-control.component.sass',
        '../../base-form/base-form.component.sass',
        '../base-input-control/base-input-control.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class DropdownControl extends BaseSelectionControl implements OnInit {

    constructor(private messageService: MessageService) {
        super(messageService);

        this.inputClass = '';
        this.LOG = LOG;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    selectOption(key: string) {
        this.control.setValue(key);
        this.control.updateValueAndValidity();
    }

    getOption(value: string | string[]): Option {
        return this.question.optionsList.filter(q => q.value === value)[0];
    }

}
