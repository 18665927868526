import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ValidationUtils } from '../../util/validation.util';

/**
 * Set of validators for date control
 *
 * @author Mateusz Jablonski (mjablonski)
 */
export class DateValidator {

    static isDayValid(): any {
        return (control: AbstractControl): ValidationErrors | null => {
            const isDayValid = ValidationUtils.valid(control.value, /((|0)?[1-9]|[12]\d|3[01])/);
            return !isDayValid ? {
                'invalid-day': true
            } : null;
        };
    }

    static isMonthValid(): any {
        return (control: AbstractControl): ValidationErrors | null => {
            const isMonthValid = ValidationUtils.valid(control.value, /^((|0)?[1-9]|1[012])$/);
            return !isMonthValid ? {
                'invalid-month': true
            } : null;
        };
    }

    static isYearValid(): any {
        return (control: AbstractControl): ValidationErrors | null => {
            const isYearValid = ValidationUtils.valid(control.value, /^(19|20)\d{2}$/);
            return !isYearValid ? {
                'invalid-year': true
            } : null;
        };
    }
}
