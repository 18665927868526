import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Answer } from '../../../models/interview.model';
import { Group, Question } from '../../../models/navigator.model';
import { NavigatorComponent } from '../../../features/interview/navigator/navigator.component';
import { LoggerService } from '../../logger/logger.service';
import { MessageService } from '../../message/message.service';
import { PageUtil } from "../../util/page.util";
import { BaseForm } from '../base-form/base-form.component';
import { BaseControl } from '../control/base-control/base-control.component';

const LOG: LoggerService = LoggerService.get('ControlGroup');

/**
 * Manages a repeating group of questions
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'control-group',
    templateUrl: './control-group.component.html',
    styleUrls: ['./control-group.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class ControlGroup implements OnInit {

    // ToDo :: Split this into 2 seperate controls:
    //         - ControlGroup
    //         - DyanmicControlGroup

    @Input() groupName: string;
    @Input() dynamicSource: string;
    @Input() questions: Question[];
    @Input() baseForm: BaseForm;
    @Input() form: UntypedFormGroup;
    @Input() onEnter: (event) => void;
    @Input() loading: boolean;
    @Input() navigator: NavigatorComponent;

    index: number = 0;
    groups: Group[] = [];
    removableRegex: RegExp;

    constructor(private messageService: MessageService) {
    }

    get messages(): MessageService {
        return this.messageService;
    }

    get adding(): boolean {
        return this.groups.filter(g => g.adding).length > 0;
    }

    get editing(): boolean {
        return this.groups.filter(g => g.editing).length > 0;
    }

    get dynamicGroup(): boolean {
        return !!this.dynamicSource;
    }

    questionId(id: string, groupName: string, index: number): string {
        return `${groupName}[${index}].${id}`;
    }

    ngOnInit(): void {
        this.groups = [];
        this.index = 0;

        // Add a dynamic group if it has already been started i.e. charity questions
        if (this.dynamicGroup) {

            // Find related questions, if any, create group and add as associated answer
            const dynamicRegex = new RegExp(this.dynamicSource);
            this.baseForm.interview.qa
                .filter((answer: Answer) => dynamicRegex.test(answer.question))
                .forEach((answer: Answer) => {
                    const index: number = this.parseIndex(answer.question);
                    this.addGroup(index, answer.answer);
                });
        }

        if (this.groups.length === 0) {
            this.baseForm.interview.qa
                .filter((answer: Answer) => answer.question.startsWith(this.groupName))
                .sort((a: Answer, b: Answer) => a.question.localeCompare(b.question))
                .forEach((answer: Answer) => {
                    const index: number = this.parseIndex(answer.question);
                    const group: Group = this.findGroup(index);
                    if (group) {
                        group.locked = true;
                        group.adding = false;
                        group.editing = false;
                    } else {
                        this.addGroup(index);
                    }
                });
        }

        if (this.groups.length === 0) {
            if (!this.dynamicSource) {
                // If not dynamic, create a single group to begin with
                this.addGroup();
            } else {
                const answers: string[] = this.baseForm.getAnswer(this.dynamicSource, false);
                answers.forEach((a: string) => this.addGroup(this.groups.length, a));
            }
        }

        this.baseForm.controlGroup = this;
    }

    private findGroup(index: number): Group {
        try {
            return this.groups.filter((g: Group) => g.index === index)[0];
        } catch (err) {
            return null;
        }
    }

    private parseIndex(question: string): number {
        const firstPart: string = question.substring(this.groupName.length + 1, question.length);
        const index: string = firstPart.substring(0, firstPart.indexOf(']'));
        LOG.trace('parseIndex', `Parsed index: ${index} from ${question}`);
        return parseInt(index, 10);
    }

    addGroup(groupIndex: number = this.index, associatedAnswer?: string): Group {
        const group: Group = new Group({
            groupName: this.groupName,
            groupIndex: groupIndex,
            associatedAnswer: associatedAnswer || null,
            adding: true,
            editing: false,
            locked: false,
            questions: [],
            answers: {}
        });
        // New questions to ask within this group
        this.questions.forEach((q: Question) => {
            const question: Question = new Question(q);
            question.groupId = question.id;
            question.id = this.questionId(q.id, this.groupName, groupIndex);

            group.questions.push(question);
        });
        // Any previous answers given
        this.baseForm.interview.qa
            .filter((q: Answer) => q.question.startsWith(`${this.groupName}[${groupIndex}]`))
            .forEach((q: Answer) => group.answers[q.question] = q.answer);

        this.groups.push(group);

        if (LOG.levelEnabled('TRACE')) {
            LOG.trace('addGroup', `Adding group: ${JSON.stringify(group, null, '  ')}`);
        }
        this.index = groupIndex + 1;
        LOG.trace('addGroup', `Next group index: ${this.index}`);

        // Move focus to first control
        PageUtil.setFocus(`group-heading-${groupIndex}`, 100);

        return group;
    }

    /**
     * Checks if a repeating question can be deleted or edited
     */
    canDeleteOrEdit(group: Group): boolean {
        return group.locked && !this.dynamicGroup;
    }

    canDelete(): boolean {
        const lockedGroups: number = this.groups.filter((g: Group) => g.locked).length;

        return lockedGroups > 1 || (this.adding && lockedGroups > 0);
    }

    async save(group: Group, lockGroup: boolean = true): Promise<boolean> {
        const controls: BaseControl[] = this.baseForm.questionControls.filter(q => q.question.id.startsWith(`${this.groupName}[${group.index}]`));

        let errored: boolean = false;
        for (const c of controls) {
            await c.validate();
            if (c.controlHasError()) {
                errored = true;
            }

        }
        if (errored) {
            return false;
        }

        LOG.debug('save', `Saving group: ${group.name}[${group.index}]`);
        if (this.dynamicGroup) {
            controls.forEach((control: BaseControl) => group.answers[control.question.id] = control.getValue());
            return true;
        }

        controls.forEach((control: BaseControl) => {
            if (control.question.multiple) {
                // ToDo :: Handle multiple values - consider delta ?
                return;
            }
            const answer: Answer[] = this.baseForm.interview.findAnswers(control.question.id);
            if (answer.length === 0) {
                this.baseForm.interview.qa.push(new Answer({
                    question: control.question.id,
                    answer: control.getValue()
                }));
            } else {
                answer[0].answer = control.getValue() as string;
            }
            group.answers[control.question.id] = control.getValue();
        });

        if (lockGroup) {
            group.locked = true;
            group.adding = false;
            group.editing = false;
        }

        return true;
    }

    edit(group: Group): void {
        group.locked = false;
        group.editing = true;
    }

    cancel(group: Group): void {
        if (!group.editing && !group.locked) {
            this.groups.pop();
        } else {
            group.editing = false;
            group.locked = true;
        }
    }

    delete(group: Group): void {
        for (let i: number = 0; i < this.groups.length; i++) {
            if (group.index === this.groups[i].index) {
                this.groups.splice(i, 1);
                break;
            }
        }

        // Remove any related controls etc
        const groupName: string = `${group.name}[${group.index}]`;
        for (let i: number = 0; i < this.baseForm.questionControls.length; i++) {
            const cntrl: BaseControl = this.baseForm.questionControls[i];
            if (cntrl.question.id.indexOf(groupName) === 0) {
                this.baseForm.questionControls.splice(i, 1);
                i--;
            }
        }
    }

    async applyValues(): Promise<boolean> {
        // If there's an active group / groups (i.e dynamic) then capture data
        let allSaved: boolean = true;
        for (const group of this.groups) {
            if (!group.locked) {
                if (!await this.save(group, true)) {
                    allSaved = false;
                }
            }
        }

        // If anything errored during save, stop and show errors
        if (!allSaved) {
            return allSaved;
        }

        if (this.dynamicGroup) {
            this.applyDynamicGroupAnswers();
        } else {
            this.applyGroupAnswers();
        }

        return true;
    }

    private applyDynamicGroupAnswers(): void {
        // Add or update answer
        this.groups.forEach((group: Group) =>
            group.questions.forEach((question: Question) => {
                const questionId: string = this.questionId(question.groupId, group.name, group.index);
                const answerValue: string = group.answers[question.id];
                const updated: boolean = this.baseForm.interview.qa.some((a: Answer) => {
                    if (answerValue && a.question === questionId) {
                        a.answer = answerValue.trim().replace(/\n\r|\n|\r/g, ' ');
                        return true;
                    }
                    return false;
                })
                if (!updated) {
                    this.baseForm.interview.qa.push(new Answer({
                        question: questionId,
                        answer: answerValue,
                        repeatGroupIndex: group.index,
                        repeatGroupName: group.name
                    }));
                }
            })
        );
    }

    private applyGroupAnswers(): void {
        // Remove existing answers for given group from QA, so we can reset the indexes (don't need to do this for a dynamic group as the indexes are set elsewhere)
        for (let i: number = 0; i < this.baseForm.interview.qa.length; i++) {
            const answer: Answer = this.baseForm.interview.qa[i];
            LOG.trace('applyValues', `Checking if "${JSON.stringify(answer)}" is in group ${this.groupName}`);
            if (answer.question.indexOf(this.groupName) === 0) {
                LOG.debug('applyValues', `Removing: ${answer.question} as group matches`)
                this.baseForm.interview.qa.splice(i, 1);
                --i;
            }
        }

        // Apply new variable names
        let groupIndex: number = 0;
        this.groups
            .filter((g: Group) => g.locked)
            .forEach((group: Group) => {
                group.questions.forEach((question: Question) => {
                    if (question.multiple) {
                        return;
                    }
                    group.index = groupIndex;
                    const questionId: string = this.questionId(question.groupId, group.name, group.index);
                    // Don't re-add the dynamic source
                    const answer: string = group.answers[question.id];
                    LOG.trace('applyValues', `Adding answer: ${questionId} -> ${answer}`)
                    this.baseForm.interview.qa.push(new Answer({
                        question: questionId,
                        answer: answer,
                        repeatGroupIndex: group.index,
                        repeatGroupName: group.name
                    }));
                });
                groupIndex++;
            });
    }

    destroy(): void {
        this.groupName = null;
        this.dynamicSource = null;
        this.groups = [];
        this.questions = [];
        this.index = 0;
    }
}
