import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LoggerService } from '../../core/logger/logger.service';
import { InterviewService } from '../../core/services/interview.service';
import { ApplicationType, ENV } from "../../models/environment.model";
import { CharityService } from '../../core/services/charity.service';

const LOG: LoggerService = LoggerService.get('CharityGivingGuard');

/**
 * Guard to protect access to another users interview
 *
 * @author Dan Bennett (dbennett)
 */
@Injectable({
    providedIn: 'root'
})
export class CharityGivingGuard  {

    constructor(
        private interviewService: InterviewService,
        private charityService: CharityService,
        private router: Router) {
    }

    async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
        if (!next.params.type && !next.params.id) {

            // Check if an initiator has been set in the URL
            if (next.params.charity) {
                this.interviewService.initiatorParams['beq_initiator_type'] = 'charity';
                this.interviewService.initiatorParams['beq_initiator'] = next.params.charity;
            }
            // Standalone page
            return true;
        }

        const hasAccess: boolean = await this.interviewService.hasAccess(next.params.type, next.params.id);
        if (!hasAccess) {
            LOG.warn('User does NOT have access to this interview!');
            window.location.href = `${ENV.applications.get(ApplicationType.HOME).baseUrl}/my-wishes`;
            return false;
        }

        const isComplete: boolean = await this.interviewService.isComplete(next.params.type, next.params.id);
        if (!isComplete) {
            LOG.warn('Interview is not yet complete, redirecting back to the interview...');
            await this.router.navigate(['interview', next.params.type, next.params.id]);
            return false;
        }

        const alreadyDonated: boolean = await this.charityService.hasDonatedToPPGF(next.params.type, next.params.id);
        if (alreadyDonated) {
            LOG.warn('User has already made a donation, redirecting to the completion page...');
            await this.router.navigate(['interview', next.params.type, next.params.id, 'complete']);
            return false;
        }

        // Prevents Donation Ask screen flickering when initiator charity not linked or has opted out
        if (!this.interviewService.willAttributes['charity']) {
            // Shown Donation Ask screen when no will does not have charity initiator
            return true;
        } else {
            const response: any = await this.charityService.findPayPalCharityByInitiator(this.interviewService.willAttributes['charity']);
            if (['NOT_LINKED', 'OPT_OUT'].reduce((acc: boolean, val: string) => val === response.responseCode || acc, false) || !response.charity || response.charity.optOut) {
                LOG.warn('Charity is either not linked or has opted out from donation plus, redirecting to the completion page...');
                await this.router.navigate(['interview', next.params.type, next.params.id, 'complete']);
                return false;
            }
        }


        return true;
    }

}
