import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { CoreModule } from '../core.module';

const LOG: LoggerService = LoggerService.get('BroadcastService');

/**
 * Service to broadcast messages to anyone listening
 *
 * @author Dan Bennett (dbennett)
 */
@Injectable({
    providedIn: CoreModule
})
export class BroadcastService {

    broadcaster: ReplaySubject<any> = new ReplaySubject();

    /**
     * Send a message in a particular format
     *
     * @param message to broadcast
     */
    broadcast(message: BroadcastMessage): void {
        LOG.trace('broadcast', `Broadcasting: ${JSON.stringify(message)}`);
        this.broadcaster.next(message);
    }
}

/**
 * Class to contain a 'message'. The title and body fields should map to an item in the message bundle to avoid
 * displaying plain non-internationalised text in the application.
 *
 * Message types:
 *  - MESSAGE : Standard message
 *  - ALERT : Important message
 *  - ERROR : Error message
 *
 * If the redirectUrl is included then the receiver can use this URL to forward the user onto a new page once displaying
 *
 * @author Dan Bennett (dbennett)
 */
export class BroadcastMessage {

    type: string;
    msg: string;
    redirectUrl: string;

    constructor(msg: string, type?: string) {
        this.type = type || 'MESSAGE';
        this.msg = msg;
    }

    redirect(url: string): BroadcastMessage {
        this.redirectUrl = url;
        return this;
    }

    error(): BroadcastMessage {
        this.type = 'ERROR';
        return this;
    }

    alert(): BroadcastMessage {
        this.type = 'ALERT';
        return this;
    }
}
