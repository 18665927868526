import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { ThreadUtils } from "../../../util/thread.utils";
import { BaseInputControl } from "../base-input-control/base-input-control.component";
import { ContentService } from "../../../services/content.service";
import { InfoMessage } from "../../form-control-info/form-control-info.component";
import {Answer} from "../../../../models/interview.model";

const LOG: LoggerService = LoggerService.get('VideoControl');

export enum VideoStage {
    LOADING,
    RECORDING,
    PAUSED,
    SAVING,
    SAVED,
    FAILED_TO_RECORD,
    FAILED_TO_UPLOAD,
    ERRORED_WHILE_SAVING,
    COMPLETE
}

/**
 * Control to display radio buttons
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'video-control',
    templateUrl: './video-control.component.html',
    styleUrls: [
        './video-control.component.sass',
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class VideoControl extends BaseInputControl implements OnInit {

    @Input() interviewId: number;

    @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
    @ViewChild('video') videoElementRef: ElementRef;

    timeLeft: number = 60;
    interval: any;

    videoElement: HTMLVideoElement;
    recordVideoElement: HTMLVideoElement;
    mediaRecorder: any;
    stream: MediaStream;
    recordedBlobs: Blob[];

    stage: VideoStage;

    infoMessages: InfoMessage[] = [
        { code: 'video.saving', type: 'error', match: () => this.stage === VideoStage.SAVING },
        { code: 'video.paused', type: 'error', match: () => this.stage === VideoStage.PAUSED, },
        { code: 'video.errored', type: 'error', match: () => this.stage === VideoStage.ERRORED_WHILE_SAVING, },
        { code: 'video.failed-to-start', type: 'error', match: () => this.stage === VideoStage.FAILED_TO_RECORD, },
        { code: 'video.failed-to-upload', type: 'error', match: () => this.stage === VideoStage.FAILED_TO_UPLOAD, },
        { code: 'video.saved', type: 'success', match: () => this.stage === VideoStage.COMPLETE },
        { code: 'error.required.video', type: 'error', match: () => !this.stage && this.controlHasError() },
    ]

    constructor(messageService: MessageService,
        private contentService: ContentService) {
        super(messageService);
    }

    ngOnInit(): void {
        this.determineValidators();
        super.ngOnInit();
        this.stage = null;
    }

    async setupVideo(): Promise<void> {
        LOG.debug('setupVideo', 'Setting up for video capture...');
        const constraints = {
            audio: true,
            video: {
                width: 360,
            },
        };

        const stream: MediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        this.stream = new MediaStream(stream);
        this.videoElement = this.videoElementRef.nativeElement;
        this.videoElement.srcObject = this.stream;
        this.videoElement.muted = true;
        this.recordVideoElement = this.recordVideoElementRef.nativeElement;
        LOG.debug('setupVideo', 'Setting up complete');
    }

    getSupportedMimeTypes(): string[] {
        return [
            'video/webm;codecs=av1,opus',
            'video/webm;codecs=vp9,opus',
            'video/webm;codecs=vp8,opus',
            'video/webm;codecs=h264,opus',
            'video/mp4;codecs=h264,aac',
            'video/mp4'
        ]
        .filter(mimeType => MediaRecorder.isTypeSupported(mimeType));
    }

    async startRecording(): Promise<void> {

        this.stage = VideoStage.LOADING;
        LOG.debug('startRecording', 'Video capture loading...');
        this.baseForm.errorMap.clear();
        this.control.markAllAsTouched();
        this.control.setValue(null);
        this.recordedBlobs = [];
        this.timeLeft = 60;

        try {
            await this.setupVideo();

            this.mediaRecorder = new MediaRecorder(this.stream);
            this.stage = VideoStage.RECORDING;
            LOG.debug('startRecording', 'Video capture recording...');

            this.preventSave.emit(true);
            this.mediaRecorder.start();
            this.startTimer();

            // Bind recording events
            this.mediaRecorder.ondataavailable = (event: any) => this.onDataAvailable(event);
            this.mediaRecorder.onstop = () => { this.onStopRecordingEvent(); };

        } catch (err) {
            LOG.error(err);
            this.stage = VideoStage.FAILED_TO_RECORD;
        }
    }

    stopRecording() {
        LOG.debug('stopRecording', 'Video capture stopped');
        this.mediaRecorder.stop();
        this.stopTimer();

        this.stage = VideoStage.SAVING;
        LOG.debug('stopRecording', `Recorded Blobs: ${this.recordedBlobs}`);
    }

    playPauseRecording() {
        if (this.stage !== VideoStage.PAUSED) {
            LOG.debug('playPauseRecording', 'Video capture paused');
            this.stage = VideoStage.PAUSED;
            this.mediaRecorder.pause();
            this.pauseTimer();
        } else {
            LOG.debug('playPauseRecording', 'Video capture resumed');
            this.stage = VideoStage.RECORDING;
            this.mediaRecorder.resume();
            this.startTimer();
        }
    }

    onDataAvailable(event: any) {
        if (event?.data?.size) {
            LOG.trace('onDataAvailable', 'Data stream blob available, capturing...');
            this.recordedBlobs.push(event.data);
        }
    }

    onStopRecordingEvent() {
        this.stage = VideoStage.SAVING;
        const videoBuffer = new Blob(this.recordedBlobs, {
            type: this.getSupportedMimeTypes()[0],
        });
        this.recordVideoElement.src = window.URL.createObjectURL(videoBuffer);

        this.loading.emit(true);
        this.saveDocument(videoBuffer).then(response => {
            if (!!response && typeof response === 'string') {
                LOG.debug('onStopRecordingEvent', 'Video saved and transcribed successfully.');
                this.control.setValue(response);
                this.stage = VideoStage.COMPLETE;
                this.preventSave.emit(false);
            } else if (typeof response === 'string' && response ==='') {
                this.stage = VideoStage.COMPLETE;
                this.control.setValue("[no audio recorded]");
                this.preventSave.emit(false);
            } else {
                LOG.error(`[onStopRecordingEvent] Error trying to save and transcribe video \n${response}`);
                this.stage = VideoStage.FAILED_TO_UPLOAD;
                this.control.setValue("[no audio recorded]");
                this.preventSave.emit(true);
            }
            this.loading.emit(false);
            this.control.clearValidators();
        });

        this.mediaRecorder = undefined;
        this.stream.getTracks().forEach((track) => {
            if (track.readyState == 'live') {
                track.stop();
            }
        });
    }

    async saveDocument(blob: any) {
        LOG.debug('saveDocument', 'Saving video...');
        let content = await this.blobToBase64(blob);
        content = content.substring(content.indexOf('base64,') + 7);
        const name = this.question.id.replace(/\./g, '_') + '.' + this.getRecordingFileType(blob.type);
        LOG.debug('saveDocument', 'Transcribing video...')
        const res: any = await this.contentService.startTranscription(this.interviewId, this.getSupportedMimeTypes()[0], name, content);
        if (!res || res.errored) {
            LOG.debug('saveDocument', `Failed to start transcription: "${name}"`);
            this.stage = VideoStage.FAILED_TO_UPLOAD;
            this.loading.emit(false);
        } else {
            const transcription: string = await this.waitForTranscription(res);
            this.loading.emit(false);
            LOG.debug('saveDocument', `Video transcribed: "${transcription}"`);
            return transcription;
        }
        return null;
    }

    async waitForTranscription(res: { job: string, location: string }): Promise<string> {
        const result: string  = await this.contentService.getTranscription(this.interviewId, res.job, res.location);
        if (result === 'Transcription in progress...') {
            // Wait 2 seconds to reduce pressure on back-end
            await ThreadUtils.sleep(2000);
            return await this.waitForTranscription(res);
        }
        return result;
    }

    blobToBase64(blob: any): Promise<any> {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    startTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                this.stopRecording();
            }
        }, 1000)
    }

    pauseTimer() {
        clearInterval(this.interval);
    }

    stopTimer() {
        clearInterval(this.interval);
        this.timeLeft = 60;
    }

    getRecordingFileType(mimeType: string): string {
        if (mimeType.startsWith('video/mp4')) {
            return 'mp4';
        }
        return 'webm';
    }

    public readonly VideoStage = VideoStage;

    async turnVideoOff() {
        if (this.stage === VideoStage.PAUSED) {
            this.stopRecording();
        }
        this.preventSave.emit(false);

        this.questionOverrides.next([new Answer({
            question: 'consent.permission_to_record',
            answer: 'no'
        })]);
    }
}
