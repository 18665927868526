<!--<p class="question" [attr.aria-details]="'Answer to ' + messages.getText(question.messageCode) + ' for group ' + group.index">{{ messages.getText(question.messageCode) }}</p>-->
<p *ngIf="question.type === 'TEXT' || question.type === 'NUMBER' || question.type === 'POSTCODE'"
   [class.first]="first"
   [attr.aria-label]="'Answer to ' + messages.getText(question.messageCode) + ' for group ' + group.index">
  {{ value }}
</p>
<p *ngIf="question.type === 'LARGE_TEXT'"
   [class.first]="first"
   [attr.aria-label]="'Answer to ' + messages.getText(question.messageCode) + ' for group ' + group.index">
  {{ value }}
</p>
<p *ngIf="question.type === 'CURRENCY'"
   [class.first]="first"
   [attr.aria-label]="'Answer to ' + messages.getText(question.messageCode) + ' for group ' + group.index">£{{ value }}</p>
<p *ngIf="question.type === 'PERCENTAGE'"
   [class.first]="first"
   [attr.aria-label]="'Answer to ' + messages.getText(question.messageCode) + ' for group ' + group.index">{{ value }}%</p>
<p *ngIf="question.type === 'DROPDOWN' || question.type === 'RADIO' || question.type === 'CHECKBOX'"
   [class.first]="first"
   [attr.aria-label]="'Answer to ' + messages.getText(question.messageCode) + ' for group ' + group.index">{{ optionValue }}</p>
