/**
 * Standard error class
 *
 * @author Dan Bennett
 */
export class WebServiceError {

    public errored :boolean = false;
    public status :number = 200;
    public type: string = 'All good here!';
    public args: string = '';

    constructor(errored :boolean, status? :number, type?: string, args?: string) {
        this.errored = errored;
        this.status = status || 200;
        this.type = type || '';
        this.args = args || '';
    }
}

/**
 * Standard response class from the microservices
 *
 * @author Dan Bennett
 */
export class RestResponse<T> {

    success: boolean = true;
    body: T | undefined | null | any;
}
