<fieldset *ngIf="!!control" [attr.id]=" question.id ">
  <legend>
    <div [attr.aria-labelledby]=" question.id +'-err'"></div>
    <span *ngIf="!!question.messageCode">
      <p #label id="{{ question.id }}-label" class="field-label">{{ messages.getText(question.messageCode) }}</p>
    </span>
  </legend>
  <div *ngIf="!!question.noteMessageCode" [attr.aria-labelledby]=" question.id +'-note'"></div>
  <span *ngIf="!!question.noteMessageCode">
    <div id="{{ question.id }}-desc" class="field-description" [innerHTML]="messages.getText(question.noteMessageCode)"></div>
  </span>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text" id="basic-addon1">{{ messages.getText('search.placeholder') }}</span>
    </div>
    <input id="charity-search" type="text" class="form-control"
           [(formControl)]="charitySearchControl"
           [placeholder]="messages.getText('label.find-your-charity')"
           [attr.aria-label]="messages.getText('label.find-your-charity')">
  </div>
  <div *ngIf="controlHasError()" class="alert-danger error pill form-error-list">
    <div id="{{ question.id }}-err" class="error-list-wrapper" [attr.aria-live]="'assertive'">
      <ul>
        <li *ngFor="let err of baseForm.errorMap.get(question.id)">
          <span>{{ determineErrorMessage(err.error, control, question) }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div [ngClass]="inputClass" class="container">
    <div class="option-row row align-items-center" *ngFor="let option of selectedCharities;let i = index; let last = last; odd as isOdd; even as isEven">

      <label class="option " for="{{ question.id }}-{{ option.nonProfitId }}">
        <div class="simple-option-content">{{ option.name }}</div>
        <input #input type="checkbox"
               [attr.name]="question.id + '-' + option.nonProfitId"
               name="{{ question.id }}-{{ option.nonProfitId }}"
               id="{{ question.id }}-{{ option.nonProfitId }}"
               [value]="option.name"
               [attr.aria-invalid]="controlHasError()"
               (keydown.enter)="onEnter($event)"
               (change)="toggleCharity(option)"
               [attr.aria-label]="getLabel()"
               checked />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="option-row row align-items-center" *ngFor="let option of charities;let i = index; let last = last; odd as isOdd; even as isEven">

      <label class="option " for="{{ question.id }}-{{ option.nonProfitId }}">
        <div class="simple-option-content">{{ option.name }}</div>
        <input #input type="checkbox"
               [attr.name]="question.id + '-' + option.nonProfitId"
               name="{{ question.id }}-{{ option.nonProfitId }}"
               id="{{ question.id }}-{{ option.nonProfitId }}"
               [value]="option.name"
               [attr.aria-invalid]="controlHasError()"
               (keydown.enter)="onEnter($event)"
               (change)="toggleCharity(option)"
               [attr.aria-label]="getLabel()"/>
        <span class="checkmark"></span>
      </label>
      <div *ngIf="last && baseForm.isSubmitted(form) && control.touched" #feedback class="feedback-icon form-group radio" aria-hidden="true">
        <i class="fa" aria-hidden="true"
           [ngClass]="{ 'fa-check-circle' : !controlHasError(), 'fa-times-circle' :  controlHasError() }"></i>
      </div>
    </div>
  </div>
</fieldset>

<ng-content></ng-content>
