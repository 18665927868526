import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MessageService } from 'src/app/core/message/message.service';
import { LoggerService } from '../../../logger/logger.service';
import { BaseInputControl } from '../base-input-control/base-input-control.component';

const LOG: LoggerService = LoggerService.get('CurrencyControl');

/**
 * Basic text input field component with label, description element,
 * validation message area and the input element styling (feedback icon, border change)
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'currency-control',
    templateUrl: './currency-control.component.html',
    styleUrls: [
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class CurrencyControl extends BaseInputControl implements OnInit {

    constructor(messageService: MessageService) {
        super(messageService);

        this.LOG = LOG;
    }

    ngOnInit(): void {
        this.determineValidators();
        this.validators.push(Validators.pattern(/^(0|[1-9]([0-9]*))$/));
        super.ngOnInit();
    }
}
