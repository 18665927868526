import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationUtils } from '../../util/validation.util';

/**
 * Validator for email control
 *
 * @author Mateusz Jablonski (mjablonski)
 */
export function validateEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isEmailValid = ValidationUtils.validEmail(control.value);
        return !isEmailValid ? {
            invalid_email: true
        } : null;
    }
}
