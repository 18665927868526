import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationUtils } from '../../util/validation.util';

export function validatePostcode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isPostcodeValid = ValidationUtils.validPostCode(control.value);
        return !isPostcodeValid ? {
            invalid_postcode: true
        } : null;
    }
}
