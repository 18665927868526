<div class="col-12 group-title">
  <h4>{{ getGroupTitle() }}</h4>
</div>
<ng-container *ngFor="let question of questions | notHidden">
  <div class="col-md-4 col-sm-12">
    <p class="bold" [tabindex]="active ? 0 : -1" [attr.data-question]="question.id">{{ getMessage(question.id, 'question', question.messageCode) }}</p>
  </div>
  <div class="col-md-8 col-sm-12">
    <p [tabindex]="active ? 0 : -1" *ngIf="question.type === 'TEXT' || question.type === 'LARGE_TEXT' || question.type === 'NUMBER' || question.type === 'POSTCODE' || question.type === 'CHARITY' || question.type === 'CHARITY_SEARCH' || question.type === 'DATE'"
       [attr.aria-label]="'Answer to ' + messages.getText(question.messageCode)"
       [innerHTML]="getAnswer(question)"></p>
    <p [tabindex]="active ? 0 : -1" *ngIf="question.type === 'CURRENCY'"
       [attr.aria-label]="'Answer to ' + messages.getText(question.messageCode)">£{{ getAnswer(question) }}</p>
    <p [tabindex]="active ? 0 : -1" *ngIf="question.type === 'PERCENTAGE'"
       [attr.aria-label]="'Answer to ' + messages.getText(question.messageCode)">{{ getAnswer(question) }}%</p>
    <p [tabindex]="active ? 0 : -1" *ngIf="question.type === 'DROPDOWN' || question.type === 'RADIO' || question.type === 'CHECKBOX'"
       [attr.aria-label]="'Answer to ' + messages.getText(question.messageCode)" [innerHTML]="getAnswer(question)"></p>
    <p [tabindex]="active ? 0 : -1" *ngIf="question.type === 'VIDEO'"
       [attr.aria-label]="'You said: ' + messages.getText(question.messageCode)" [innerHTML]="getAnswer(question)"></p>
  </div>
</ng-container>
