import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "../../app-routing.module";
import { CoreModule } from "../../core/core.module";
import { InterviewService } from "../../core/services/interview.service";
import { AdviceControl } from "../../core/ui/control/advice-control/advice-control.component";
import { AdviceService } from "../../core/services/advice.service";
import { RecommendationsComponent } from './recommendations/recommendations.component';
import {UIModule} from "../../core/ui/ui.module";


@NgModule({
    declarations: [
        RecommendationsComponent,
        AdviceControl
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        // Shared application modules
        CoreModule,
        NgbModule,
        UIModule
    ],
    providers: [
        NgbModule,
        InterviewService,
        AdviceService
    ]
})
export class AdviceModule {
}
