<div class="container-fluid interview-summary">
  <div class="row">
    <div class="content-panel col-md-12 col-sm-12 col-12">
      <div class="row">
        <div class="col">
          <h1 class="underline">{{ messages.getText('title.interview.' + type) }}</h1> <!-- aria -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 interview-finished">
          <h2 class="stageTitle" id="interview-summary-header" tabindex="-1">{{ messages.getText('title.interview.summary') }}</h2>
          <div class="topic-form">
            <div class="info" [innerHTML]="messages.getText('body.interview.summary')"></div>
            <dl class="accordion" *ngIf="summary?.topics">
              <ng-container *ngFor="let topic of summary.topics; let i = index">
                <dt>
                  <button class="btn" tabindex="0"
                          [attr.aria-controls]="topic.id"
                          (click)="toggleTopic(topic)">
                    {{ messages.getText(topic.messageCode) }}
                    <i class="fa fa-1"
                       [class.fa-angle-up]="topic.active"
                       [class.fa-angle-down]="!topic.active"></i>
                  </button>
                  <button class="btn-repeating-action btn edit" id="edit-{{topic.id}}"
                          type="button" (click)="editTopic(topic)" (keyup.enter)="editTopic(topic)"
                          [attr.aria-label]="'Edit ' + messages.getText(topic.messageCode) + ' topic'">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ messages.getText('btn.edit', 'Edit') }}
                    <i class="fa fa-pencil"></i>
                  </button>
                </dt>
                <dd [class.active]="topic.active" [class.collapsed]="!topic.active">
                  <div class="content">
                    <summary-questions class="row"
                                       *ngIf="topic.questions?.length"
                                       [questions]="topic.questions"
                                       [active]="topic.active"></summary-questions>
                    <ng-container *ngFor="let group of topic.groups">
                      <summary-questions class="row group"
                                         [groupId]="group.id"
                                         [groupIndex]="group.index"
                                         [groupTitle]="group.title"
                                         [questions]="group.questions"
                                         [active]="topic.active"></summary-questions>
                    </ng-container>
                    <ng-container *ngFor="let subTopic of topic.subTopics">
                      <h3 class="subtopic">
                        {{ messages.getText(subTopic.messageCode) }}
                        <button class="btn-repeating-action btn edit" id="edit-{{subTopic.id}}"
                                type="button" (click)="editTopic(topic, subTopic)" (keyup.enter)="editTopic(topic, subTopic)"
                                [tabindex]="topic.active ? 0 : -1"
                                [attr.aria-label]="'Edit ' + messages.getText(subTopic.messageCode) + ' topic'">
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                          {{ messages.getText('btn.edit', 'Edit') }}
                          <i class="fa fa-pencil"></i>
                        </button>
                      </h3>
                      <summary-questions class="row"
                                         *ngIf="subTopic.questions?.length"
                                         [questions]="subTopic.questions"
                                         [active]="topic.active"></summary-questions>
                      <ng-container *ngFor="let group of subTopic.groups">
                        <summary-questions class="row group"
                                           *ngIf="visibleQuestions(group)"
                                           [groupId]="group.id"
                                           [groupIndex]="group.index"
                                           [groupTitle]="group.title"
                                           [questions]="group.questions"
                                           [active]="topic.active"></summary-questions>
                      </ng-container>
                    </ng-container>
                  </div>
                </dd>
              </ng-container>
            </dl>
          </div>
        </div>
      </div>
      <div class="form-group position-relative row">
        <div class="col-6">
          <div class="outer-button">
            <button [disabled]="loading" class="btn btn-primary rounded-pill submit" id="save"
                    type="button" (click)="saveAndExit()" (keyup.enter)="saveAndExit()">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{ messages.getText('btn.save-and-exit', 'Save and exit') }}
            </button>
          </div>
        </div>
        <div class="col-6 text-right">
          <div class="outer-button">
            <button class="btn btn-primary rounded-pill submit" id="prev"
                    type="button" (click)="completeInterview()" (keyup.enter)="completeInterview()">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{ messages.getText('btn.complete', 'Complete interview') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
