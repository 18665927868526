<fieldset [attr.id]="question.id + '-fieldset'">
  <legend>
    <span *ngIf="!!question.messageCode">
      <p #label id="{{ question.id }}-label-content" class="field-label">{{ messages.getText(question.messageCode) }}</p>
    </span>
  </legend>
  <span *ngIf="!!question.noteMessageCode">
    <div id="{{ question.id }}-desc" class="field-description" [innerHTML]="messages.getText(question.noteMessageCode)"></div>
  </span>
  <div *ngIf="formHasError()" class="alert-danger error pill form-error-list">
    <div id="{{question.id}}-err" class="error-list-wrapper" [attr.aria-live]="'assertive'">
      <div>
        <ul>
          <li *ngFor="let err of baseForm.errorMap.get(question.id)">
            <span> {{ determineErrorMessage(err.error, control, question) }}</span>
          </li>
          <ng-container *ngIf="controlNameHasError('day')">
            <li *ngFor="let err of baseForm.errorMap.get(question.id + '-day')">
              <span>{{ determineErrorMessage(err.error, control, question) }}</span>
            </li>
          </ng-container>
          <ng-container *ngIf="controlNameHasError('month')">
            <li *ngFor="let err of baseForm.errorMap.get(question.id + '-month')">
              <span>{{ determineErrorMessage(err.error, control, question) }}</span>
            </li>
          </ng-container>
          <ng-container *ngIf="controlNameHasError('year')">
            <li *ngFor="let err of baseForm.errorMap.get(question.id + '-year')">
              <span>{{ determineErrorMessage(err.error, control, question) }}</span>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <form [formGroup]="control">
    <div class="horizontal" id="{{question.id}}">
      <div class="form-group position-relative">
        <label id="{{getDateFieldName('day')}}-label" aria-label="Day of Month (dd)" #labelDay [for]="question.id + '-day'" class="field-label">{{ dateLabels[0] }} </label>
        <input #input type="text" integer
               class="form-control very-short {{inputClass}}"
               [(formControl)]="day"
               [attr.name]="question.id"
               [attr.id]="question.id + '-day'"
               [attr.autocomplete]="question.id + '-day'"
               [attr.aria-invalid]="day.touched && day.invalid"
               [attr.aria-labelledby]="question.id + '-day-err ' + question.id + '-day-label '"
               aria-live="polite"
               [attr.aria-required]="question.required"
               [required]="question.required"
               [maxLength]="2"
               [ngClass]="{ 'is-invalid': day.touched && day.invalid }"
               (keydown.enter)="onEnter($event)"/>
      </div>
      <div class="form-group position-relative">
        <label id="{{getDateFieldName('month')}}-label" aria-label="Month of Year (mm)" #labelMonth [for]="getDateFieldName('month')" class="field-label">{{ dateLabels[1] }} </label>
        <input #input type="text" integer
               [(formControl)]="month"
               [attr.name]="question.id"
               [attr.id]="getDateFieldName('month')" class="form-control very-short {{inputClass}}"
               [attr.autocomplete]="getDateFieldName('month')"
               [attr.aria-invalid]="controlNameHasError('month')"
               [attr.aria-required]="question.required"
               [attr.aria-labelledby]="getDateFieldName('month')+'-label ' + getDateFieldName('month')+'-err'"
               [required]="question.required"
               [maxLength]="2"
               [ngClass]="{ 'is-invalid': controlNameHasError('month') }"
               (keydown.enter)="onEnter($event)"/>
      </div>

      <div class="form-group position-relative">
        <label id="{{getDateFieldName('year')}}-label" aria-label="Year (yyyy)" #labelYear [for]="getDateFieldName('year')" class="field-label">{{ dateLabels[2] }} </label>
        <input #input type="text" integer
               [(formControl)]="year"
               [attr.name]="question.id"
               [attr.id]="getDateFieldName('year')" class="form-control short {{inputClass}}"
               [attr.autocomplete]="getDateFieldName('year')"
               [attr.aria-required]="question.required"
               [attr.aria-invalid]="controlNameHasError('year')"
               [attr.aria-labelledby]="getDateFieldName('year')+'-label ' + getDateFieldName('year')+'-err'"
               [required]="question.required"
               [maxLength]="4"
               [ngClass]="{ 'is-invalid': controlNameHasError('year') }"
               (keydown.enter)="onEnter($event)"
               (select)="revalidateInput()"/>
      </div>

      <div #feedback class="feedback-icon form-group" aria-hidden="true" *ngIf="(anyTouched() || baseForm.isSubmitted())">
        <i class="fa" aria-hidden="true"
           [ngClass]="{ 'fa-check-circle' : !formHasError() && allValid(), 'fa-times-circle' : formHasError() || !allValid() }"></i>
      </div>
    </div>
  </form>
</fieldset>
