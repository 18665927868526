import { Component, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from '../../../core/logger/logger.service';
import { MessageService } from '../../../core/message/message.service';
import { AdviceControl } from "../../../core/ui/control/advice-control/advice-control.component";
import { PageUtil } from '../../../core/util/page.util';
import { InterviewService } from "../../../core/services/interview.service";
import { AdviceOption } from "../../../models/interview.model";
import { RulesService } from '../../../core/services/rules.service';
import { AdviceService } from "../../../core/services/advice.service";
import { Advice, Recommendation } from "../../../models/advice.model";

const LOG: LoggerService = LoggerService.get('RecommendationsComponent');

@Component({
    selector: 'app-advice',
    templateUrl: './recommendations.component.html',
    styleUrls: ['./recommendations.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class RecommendationsComponent implements OnInit {

    type: string = 'ADVICE';
    interviewId: number;
    loading: boolean = false;
    saving: boolean = false;
    errorSaving: boolean = false;
    requirementsUnmet: boolean = false;
    requirementsUnmetChecked: boolean = false;

    @ViewChildren("recommendation") recommendations: QueryList<AdviceControl>;
    adviceOptionsConfig: AdviceOption[];
    advice: Advice;

    constructor(
        private router: Router,
        private title: Title,
        protected interviewService: InterviewService,
        private rulesService: RulesService,
        private adviceService: AdviceService,
        protected messageService: MessageService,
        private route: ActivatedRoute
    ) {
        this.title.setTitle('Advice : Recommendations for your Will');
    }

    ngOnInit(): void {
        this.loading = true;
        this.requirementsUnmet = false;
        this.requirementsUnmetChecked = false;

        this.route.params.subscribe((params: { id: number; type: string; action: string }) => {
            this.type = params.type;
            this.interviewId = params.id;
        });
        this.setupAdviceInterview().then(() => {
            LOG.debug('init', 'Loaded Advice interview...');
            this.loading = false;
        });

        PageUtil.setFocus('adviceRecommendationsHeader');
    }

    get messages(): MessageService {
        return this.messageService;
    }

    async setupAdviceInterview(): Promise<void> {
        const interview = await this.interviewService.get('WILL', this.interviewId);
        await this.loadAdviceRecommendations(interview.qa);

        const optionNames: string[] = [];
        this.adviceOptionsConfig.forEach(option => {
            optionNames.push(option.id);
        });

        this.advice = await this.adviceService.start(interview.id, optionNames);
        // await this.completeInterview();
    }

    async loadAdviceRecommendations(qa: any) {
        try {
            const res = await this.rulesService.getAdviceRecommendations(qa);
            if (!res) {
                LOG.error('No response!');
            } else {
                this.adviceOptionsConfig = res;
            }
        } catch (err) {
            setTimeout(() => this.loadAdviceRecommendations(qa), 3000);
            LOG.error('Error retrieving Advice Recommendations! Retrying in 3 seconds...');
            return;
        }
    }

    getRecommendation(name: string): Recommendation {
        return this.advice.recommendations.find(rec => rec.name == name)
    }


    isLastOption(rec: any): boolean {
        return this.adviceOptionsConfig.length - 1 === this.adviceOptionsConfig.indexOf(rec);
    }

    async completeInterview(): Promise<void> {
        if (!this.recommendations.reduce((acc: boolean, cntl: AdviceControl) => !cntl.invalid && acc, true)) {
            LOG.debug('completeInterview', 'Not completing yet, some items don\'t have a decision yet');
            this.requirementsUnmet = true;
            return;
        }
        this.requirementsUnmet = false;
        this.saving = true;

        if (!(await this.adviceService.saveAnswers(this.interviewId, this.advice))) {
            this.errorSaving = true;
            this.saving = false;
            return;
        }

        const allRequirementsMet = await this.adviceService.complete(this.interviewId, this.advice.id);
        this.saving = false;
        if (allRequirementsMet) {
            await this.router.navigate(['interview', 'WILL', this.interviewId, 'complete']);
        } else {
            await this.router.navigate(['interview', 'WILL', this.interviewId, 'cannot-complete']);
        }
        this.saving = false;
    }


}
