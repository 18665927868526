import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { fakeBackendProvider } from './_helpers/fake-interview-backend';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderService } from "./core/services/loader.service";
import { CharityModule } from './features/charity/charity.module';

import { CoreModule } from './core/core.module';
import { BaseForm } from './core/ui/base-form/base-form.component';
import { SafeHtmlPipe } from "./core/util/safehtml.pipe";
import { AdviceModule } from "./features/advice/advice.module";
import { InterviewModule } from './features/interview/interview.module';

function setup(loaderService: LoaderService) {
    return () => loaderService.initialiseApp();
}

@NgModule({
    declarations: [
        AppComponent,
        BaseForm,
        SafeHtmlPipe
    ],
    imports: [
        NgbModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        // Shared application modules
        CoreModule,
        InterviewModule,
        AdviceModule,
        CharityModule
    ],
    providers: [
        fakeBackendProvider,
        LoaderService,
        {
            provide: APP_INITIALIZER,
            useFactory: setup,
            deps: [LoaderService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
