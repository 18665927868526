import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CharityService } from '../../../services/charity.service';
import { MessageService } from '../../../message/message.service';
import { PageUtil } from '../../../util/page.util';
import { BaseInputControl } from '../base-input-control/base-input-control.component';
import { NavigatorComponent } from '../../../../features/interview/navigator/navigator.component';
import { LoggerService } from '../../../logger/logger.service';
import { Answer, Charity, Interview } from '../../../../models/interview.model';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ValidationUtils } from '../../../util/validation.util';

const LOG: LoggerService = LoggerService.get('CharitySearchControl');

/**
 * Control to allow users to search for a charity that they want to add rather than entering the details manually.
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'charity-search-control',
    templateUrl: './charity-search-control.component.html',
    styleUrls: [
        './charity-search-control.component.sass',
        '../checkbox-control/checkbox-control.component.sass',
        '../../base-form/base-form.component.sass',
        '../base-input-control/base-input-control.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class CharitySearchControl extends BaseInputControl implements OnInit {

    @Input() navigator: NavigatorComponent;

    charitySearchControl: UntypedFormControl;
    charities: Charity[];
    previousSearch: string;

    selectedCharities: Charity[];

    constructor(
        private messageService: MessageService,
        private charityService: CharityService
    ) {
        super(messageService);
        this.LOG = LOG;
        this.inputClass = '';
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.charities = [];
        this.selectedCharities = [];
        this.charitySearchControl = new UntypedFormControl();
        // ToDo :: Get the rest of the details
        if (this.previousAnswer?.length) {
            this.charityService
                .loadPreselectedCharities(this.previousAnswer[0].split('|'))
                .then((charities: Charity[]) => this.selectedCharities = charities );
        }
        this.charitySearchControl.valueChanges
            .pipe(debounceTime(500))
            .subscribe((newValue: string) => {
                // Only search if:
                // - value is blank OR value is at least 3 characters long AND is not the same as previously searched
                LOG.trace('init', `Checking value [${newValue}]...`);
                if (ValidationUtils.validMinLength(newValue, 2) && ValidationUtils.isNotEqual(this.previousSearch, newValue)) {
                    this.previousSearch = newValue;
                    this.searchCharityByName(newValue).then(() => LOG.debug('searchCharities', `Found ${this.charities.length} charities`));
                }
            });
    }

    async searchCharityByName(name: string): Promise<void> {
        this.charities = await this.charityService.findPayPalCharityByName(name, true);
    }

    applyValues(interview: Interview): void {

        if (!this.question.targetGroup || !this.question.multiple) {
            this.applySingleValue(interview);
        } else {
            this.applyMultipleValues(interview);
        }
    }

    applySingleValue(interview: Interview): void {
        for (let i = 0; i < interview.qa.length; i++) {
            // Check QA to remove any questions for the same group
            if (new RegExp(`${this.question.targetName}.[a-zA-Z0-9_]*`).test(interview.qa[i].question)) {
                interview.qa.splice(i, 1);
                i--;
            }
            // Check QA to remove any duplicate answers for this question
            if (interview.qa[i].question === this.question.id) {
                interview.qa.splice(i, 1);
                i--;
            }
        }

        // Add a record for each name but also pre-emptively within the group
        for (let i = 0; i < this.selectedCharities.length; i++) {
            const charity: Charity = this.selectedCharities[i];
            interview.qa.push(new Answer({ question: `${this.question.targetName}.charity_name`, answer: charity.name }));
            interview.qa.push(new Answer({ question: `${this.question.targetName}.charity_number`, answer: charity.number }));
            interview.qa.push(new Answer({ question: `${this.question.targetName}.address.line1`, answer: charity.addressLine1 }));
            if (charity.addressLine2) {
                interview.qa.push(new Answer({ question: `${this.question.targetName}.address.line2`, answer: charity.addressLine2 }));
            }
            interview.qa.push(new Answer({ question: `${this.question.targetName}.address.city`, answer: charity.city }));
            if (charity.county) {
                interview.qa.push(new Answer({ question: `${this.question.targetName}.address.county`, answer: charity.county }));
            }
            interview.qa.push(new Answer({ question: `${this.question.targetName}.address.postcode`, answer: charity.postcode }));
        }
        interview.qa.push(new Answer({ question: this.question.id, answer: this.selectedCharities.map((c: Charity) => c.name).join('|') }));
    }

    applyMultipleValues(interview: Interview): void {
        for (let i = 0; i < interview.qa.length; i++) {
            // Check QA to remove any questions for the same group
            if (new RegExp(`${this.question.targetGroup}\\[[0-9]+]\\.[a-zA-Z0-9._]+`).test(interview.qa[i].question)) {
                interview.qa.splice(i, 1);
                i--;
            }
            // Check QA to remove any duplicate answers for this question
            if (interview.qa[i].question === this.question.id) {
                interview.qa.splice(i, 1);
                i--;
            }
        }

        // Add a record for each name but also pre-emptively within the group
        for (let i = 0; i < this.selectedCharities.length; i++) {
            const charity: Charity = this.selectedCharities[i];
            interview.qa.push(new Answer({ question: `${this.question.targetGroup}[${i}].${this.question.targetName}_charity_name`, answer: charity.name }));
            interview.qa.push(new Answer({ question: `${this.question.targetGroup}[${i}].${this.question.targetName}_charity_number`, answer: charity.number }));
            interview.qa.push(new Answer({ question: `${this.question.targetGroup}[${i}].${this.question.targetName}_address.line1`, answer: charity.addressLine1 }));
            if (charity.addressLine2) {
                interview.qa.push(new Answer({ question: `${this.question.targetGroup}[${i}].${this.question.targetName}_address.line2`, answer: charity.addressLine2 }));
            }
            interview.qa.push(new Answer({ question: `${this.question.targetGroup}[${i}].${this.question.targetName}_address.city`, answer: charity.city }));
            if (charity.county) {
                interview.qa.push(new Answer({ question: `${this.question.targetGroup}[${i}].${this.question.targetName}_address.county`, answer: charity.county }));
            }
            interview.qa.push(new Answer({ question: `${this.question.targetGroup}[${i}].${this.question.targetName}_address.postcode`, answer: charity.postcode }));
        }
        interview.qa.push(new Answer({ question: this.question.id, answer: this.selectedCharities.map((c: Charity) => c.name).join('|') }));
    }

    focus(): void {
        const nativeElement: any = PageUtil.findNativeElement('charity-search');
        if (nativeElement) {
            nativeElement.focus();
        }
    }

    toggleCharity(charity: Charity) {

        let alreadySelected: boolean = false;
        for (let i = 0; i < this.selectedCharities.length; i++) {
            if (this.selectedCharities[i].name === charity.name) {
                this.selectedCharities.splice(i, 1);
                alreadySelected = true;
                break;
            }
        }
        if (alreadySelected) {
            this.charities.push(charity);
        } else {

            for (let i = 0; i < this.charities.length; i++) {
                if (this.charities[i].name === charity.name) {
                    this.charities.splice(i, 1);
                    break;
                }
            }
            let value: string | string[] = this.control.value;
            if (!!value && this.question.multiple) {
               value  = (value as string).split('|');
               (value as string[]).push(`${charity.name}`);
               value = (value as string[]).join('|');

            } else {
               value = `${charity.name}`;
            }

            LOG.debug('toggleCharity', `Setting value: ${value}`);
            if (!this.question.multiple) {
                this.charities.push(...this.selectedCharities);
                this.charities.sort((c: Charity, d: Charity) => c.name.localeCompare(d.name))
                this.selectedCharities = [];
            }
            this.control.setValue(value);
            this.selectedCharities.push(charity);
        }
    }
}
