import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { TokenService } from '../../../core/authentication/token.service';
import { LoggerService } from '../../../core/logger/logger.service';
import { MessageService } from '../../../core/message/message.service';
import { User } from '../../../models/user.model';
import { PageUtil } from '../../../core/util/page.util';
import { ValidationUtils } from '../../../core/util/validation.util';
import { InterviewService } from '../../../core/services/interview.service';
import { Charity, Interview } from '../../../models/interview.model';
import { ApplicationType, ENV } from "../../../models/environment.model";
import { CharityService } from '../../../core/services/charity.service';

const LOG: LoggerService = LoggerService.get('CharityGivingComponent');

/**
 * Component to handle interaction with the PayPal Giving app
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'app-charity-giving-component',
    templateUrl: './charity-giving.component.html',
    styleUrls: ['./charity-giving.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class CharityGivingComponent implements OnInit {

    @ViewChild('confirmationModal') confirmationModal: any;

    userId: number;
    interviewId: number;
    interviewType: string;
    interview: Interview;
    searchControl: UntypedFormControl;
    confirmDonationTrigger: EventEmitter<Charity>;
    paymentMadeTrigger: EventEmitter<any>;
    paymentErrorTrigger: EventEmitter<any>;
    paymentCancelledTrigger: EventEmitter<any>;

    charity: Charity;
    charities: Charity[];
    searching: boolean;
    loading: boolean = true;
    consent: boolean = false;
    skip: boolean = false;

    selectedCharity: Charity;

    private initiator: string;
    private initiatorType: string;
    private previousSearch: string;
    private paramCharityInitiator: string;

    constructor(
        private title: Title,
        private router: Router,
        private route: ActivatedRoute,
        private charityService: CharityService,
        private interviewService: InterviewService,
        private messageService: MessageService,
        private tokenService: TokenService,
        private modalService: NgbModal) {

        this.title.setTitle(messageService.getText('title.interview.give-to-charity'));
    }

    get messages(): MessageService {
        return this.messageService;
    }

    get user(): User {
        return this.tokenService.getUser();
    }

    ngOnInit(): void {
        this.loading = true;
        this.reset();

        PageUtil.setFocus('confirmation-panel-title');

        this.confirmDonationTrigger = new EventEmitter();
        this.confirmDonationTrigger.subscribe(async (charity: Charity) => {
            LOG.debug('init', `Confirming donation to: ${charity.name}`);
            this.loadConfirmationDialog(charity);
        });

        this.paymentMadeTrigger = new EventEmitter();
        this.paymentMadeTrigger.subscribe(() => {
            LOG.debug('init', 'Payment made, moving to completion screen!');
            if (this.initiatorType && this.interviewId) {
                this.router.navigate(['interview', this.interviewType, this.interviewId, 'complete']);
            } else {
                window.location.href = ENV.applications.get(ApplicationType.HOME).baseUrl
            }
        });

        this.paymentErrorTrigger = new EventEmitter();
        this.paymentErrorTrigger.subscribe(() => {
            LOG.debug('init', 'Payment errored, moving to completion screen!');
            if (this.initiatorType && this.interviewId) {
                this.router.navigate(['interview', this.interviewType, this.interviewId, 'complete']);
            } else {
                window.location.href = ENV.applications.get(ApplicationType.HOME).baseUrl
            }
        });

        this.paymentCancelledTrigger = new EventEmitter();
        this.paymentCancelledTrigger.subscribe(() => {
            LOG.debug('init', 'Payment cancelled, moving to completion screen!');
            if (this.initiatorType && this.interviewId) {
                this.router.navigate(['interview', this.interviewType, this.interviewId, 'complete']);
            } else {
                window.location.href = ENV.applications.get(ApplicationType.HOME).baseUrl
            }
        });

        this.searchControl = new UntypedFormControl();

        this.route.params.subscribe((params: { id: number; type: string; charity: string }) => {
            this.interviewId = params.id;
            this.interviewType = params.type;
            this.skip = (!params.id && ValidationUtils.isNotBlank(params.charity));
            this.paramCharityInitiator = params.charity;
        });

        LOG.debug('init', `Looking for initiator type: ${JSON.stringify(this.interviewService.initiatorParams['beq_initiator_type'])}`);
        LOG.debug('init', `Looking for initiator: ${JSON.stringify(this.interviewService.initiatorParams['beq_initiator'])}`);
        this.initiatorType = this.interviewService.initiatorParams['beq_initiator_type'] || '';
        this.initiator = this.interviewService.initiatorParams['beq_initiator'] || this.interviewService.willAttributes.charity;
        if (!!this.initiator && this.initiator !== 'undefined' && this.initiator !== 'null') {
            this.initialiseCharity().then(() => {
                this.loading = false;
                LOG.debug('init', 'Loaded charity...');
            });
            return;
        } else {
            this.loadFeaturedCharities().then(() => {
                this.loading = false;
                PageUtil.setFocus('confirmation-panel-title');
            });
        }
    }

    async initialiseCharity(): Promise<void> {
        const response: any = await this.charityService.findPayPalCharityByInitiator(this.initiator);
        if (['NOT_LINKED', 'OPT_OUT'].reduce((acc: boolean, val: string) => val === response.responseCode || acc, false) || !response.charity || response.charity.optOut) {
            // load featured and do nothing
            this.skip = false;
            await this.loadFeaturedCharities();
            return;
        }

        LOG.debug('initialiseCharity', `Found charity: ${JSON.stringify(response.charity)}`);
        this.charity = new Charity(response.charity);

        await this.loadFeaturedCharities();
    }

    async loadFeaturedCharities(): Promise<void> {
        if (!this.charity) {
            this.charities = await this.charityService.getFeaturedCharities();
            if (!this.charity && ValidationUtils.listIsEmpty(this.charities)) {
                return this.navigateToComplete();
            }
        }

        // Remove sponsor charity if included in the list
        if (!!this.charity && ValidationUtils.listIsNotEmpty(this.charities)) {
            this.charities = this.charities.filter((c: Charity) => c.nonProfitId !== this.charity.nonProfitId);
        }
        this.searchControl.valueChanges
            .pipe(debounceTime(500))
            .subscribe((newValue: string) => {
                // Only search if:
                // - value is blank OR value is at least 3 characters long AND is not the same as previously searched
                LOG.trace('init', `Checking value [${newValue}]...`);
                if (ValidationUtils.validMinLength(newValue, 2) && ValidationUtils.isNotEqual(this.previousSearch, newValue)) {
                    const query: string = newValue;
                    this.previousSearch = newValue;
                    this.search(query);
                } else if (ValidationUtils.isBlank(newValue)) {
                    this.previousSearch = '';
                    if (this.charity) {
                        this.charities = [];
                    } else {
                        // Only load featured charities if an initiator charity is not present
                        this.loadFeaturedCharities();
                    }
                }
            });
    }

    async search(name: string): Promise<void> {
        this.searching = true;
        LOG.debug('search', `Searching for charities by name: ${name}`);
        try {
            this.charities = await this.charityService.findPayPalCharityByName(name);
            if (this.charity) {
                this.charities = this.charities.filter((c: Charity) => c.nonProfitId !== this.charity.nonProfitId);
            }
            LOG.debug('search', `Found ${this.charities.length} charities for name: ${name}`);
        } catch (e) {
            LOG.error('Failed to find charities!');
            this.charities = [];
        }
        this.searching = false;
    }

    private async loadConfirmationDialog(charity: Charity): Promise<void> {
        this.selectedCharity = charity;
        await this.modalService.open(this.confirmationModal, { size: 'lg', backdrop: 'static' }).result;
    }

    navigateToDonate(): void {
        this.consent = true;
        PageUtil.setFocus('donate-title');
    }

    navigateToComplete(): void {
        if (!!this.interviewType && !!this.interviewId) {
            this.router.navigate(['interview', this.interviewType, this.interviewId, 'complete']);
        } else {
            window.location.href = ENV.applications.get(ApplicationType.HOME).baseUrl
        }
    }

    reset(): void {

        this.interviewId = null;
        this.interviewType = null;
        this.interview = null;
        this.charity = null;
        this.charities = [];
        this.initiator = null;
        this.previousSearch = null;
        this.selectedCharity = null;
    }
}
