<div *ngIf="success?.length > 0" class="alert-success error pill form-error-list">
  <div id="{{ id }}-success" class="error-list-wrapper" [attr.aria-live]="'assertive'">
    <ul>
      <li *ngFor="let msg of success" [id]="msg.code"><span [innerHtml]="messages.getText(msg.code)"></span></li>
    </ul>
  </div>
</div>

<div *ngIf="errors?.length > 0" class="alert-error error pill form-error-list">
  <div id="{{ id }}-err" class="error-list-wrapper" [attr.aria-live]="'assertive'">
    <ul>
      <li *ngFor="let msg of errors" [id]="msg.code"><span [innerHtml]="messages.getText(msg.code)"></span></li>
    </ul>
  </div>
</div>
