import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Answer, Interview } from '../../../models/interview.model';
import { Question } from '../../../models/navigator.model';
import { ApplicationType, ENV } from "../../../models/environment.model";
import { RulesService } from '../../services/rules.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { LoggerService } from '../../logger/logger.service';
import { MessageService } from '../../message/message.service';
import { ControlGroup } from '../control-group/control-group.component';
import { BaseControl } from '../control/base-control/base-control.component';

const LOG: LoggerService = LoggerService.get('BaseFormComponent');

/**
 * Generic base UI component for form input validation and styling
 */
@Component({
    selector: 'base-form',
    templateUrl: './base-form.component.html',
    styleUrls: ['./base-form.component.sass']
})
export class BaseForm implements OnInit {

    protected pageTitle: string;

    loading: boolean = false;
    preventSave: boolean = false;
    submitted: boolean = false;

    // keeps up-to-date input validation state
    errorMap: Map<string, ErrorInfo[]> = new Map<string, ErrorInfo[]>();
    // keeps copy of input errors when Submit is pressed and displayed above the form
    errorList: Map<string, ErrorInfo[]> = new Map<string, ErrorInfo[]>();
    form: UntypedFormGroup;
    fieldTextType: boolean;

    actionSuccess: any = undefined;
    actionMessage: string;
    homeAppUri: string;
    formName: string;

    interview: Interview;
    questionControls: BaseControl[] = [];

    groupName: string;
    dynamicGroupSource: string;
    groupQuestions: Question[];
    @ViewChild('controlGroup') controlGroup: ControlGroup;

    protected redirectCookieName: string = 'beq_redirect';
    protected redirectUri: string = '/my-wishes';

    constructor(
        protected messageService: MessageService,
        protected changeDetector: ChangeDetectorRef,
        protected cookieService: CookieService,
        protected authenticationService: AuthenticationService,
        public rulesService: RulesService) {
    }

    get messages(): MessageService {
        return this.messageService;
    }

    /**
     * Initializes only a message bundle
     */
    ngOnInit(): void {
        this.questionControls = [];
        this.homeAppUri = ENV.applications.get(ApplicationType.HOME).baseUrl;

        const redirectUriCookie: string = this.cookieService.get(this.redirectCookieName);
        if (redirectUriCookie) {
            this.redirectUri = redirectUriCookie.replace(/[']+/g, '');
            LOG.debug('ngOnInit', `Redirect [${this.redirectUri}]`);
        }

        // MB-801 : when switching components, reset focus to the top page element
        document.getElementById('main-logo').focus();
        document.getElementById('main-logo').blur();
    }

    toggleSave(preventSave: boolean): void {
        this.preventSave = preventSave;
        this.changeDetector.detectChanges();
        LOG.debug('toggleSave', `Preventing save? ${preventSave}`);
    }

    toggleLoading(loading: boolean): void {
        this.loading = loading;
        this.changeDetector.detectChanges();
        LOG.debug('toggleLoading', `Loading? ${loading}`);
    }

    /**
     * Updates the validation error list above the form
     * @param form A formgroup instance representing main or sub-form on the current page
     * (base-form-component). By default it uses main FormGroup from the base-form instance
     */
    setErrorList(form: UntypedFormGroup = this.form, cleanErrors: boolean = true): void {
        LOG.trace('setErrorList', 'Reset errors ...');
        if (cleanErrors) {
            this.errorMap.clear();
            this.errorList.clear();
        }
        LOG.trace('setErrorList', `... got controls [${!!form.controls}]`);
        if (form.controls) {
            form.markAllAsTouched();
            for (const controlName of Object.keys(form.controls)) {
                if (form.controls[controlName] instanceof UntypedFormGroup) {
                    this.setErrorList(form.controls[controlName] as UntypedFormGroup, false);
                } else {
                    if (!this.revalidateControl(controlName, form)) {
                        this.errorList.set(controlName, this.errorMap.get(controlName));
                    }
                }
            }
        }
    }

    /**
     * Creates an intermediate object for detailed input's error behavior
     */
    createErrorInfo(err: string, controlName: string): ErrorInfo {
        return { field: controlName, error: err } as ErrorInfo;
    }

    /**
     * Revalidates the form upon submit
     */
    async revalidateForm(): Promise<void> {
        // Clear previous errors
        this.errorMap.clear();
        this.form.updateValueAndValidity();

        const errored: BaseControl[] = [];
        for (const cntrl of this.questionControls) {
            const errors: ErrorInfo[] = await cntrl.validate();
            if (errors?.length) {
                errored.push(cntrl);
                this.errorMap.set(cntrl.question.id, errors);
                LOG.warn(`Form contains errors: ${JSON.stringify(errors)}`);
            }
        }

        if (errored.length > 0) {
            LOG.warn(`Form contains ${errored.length} errors!`);
            errored[0].focus();
        }
    }

    /**
     * Used for showing/hiding password
     */
    toggleFieldTextType(): void {
        this.fieldTextType = !this.fieldTextType;
    }

    getAnswer(questionId: string, includeRemoved: boolean = true): string[] {
        let answers: string[] = this.interview.qa.filter(q => q.question === questionId).map((q: Answer) => q.answer);
        if (includeRemoved && answers.length === 0 && !!this.interview.removed) {
            answers = this.interview.removed.filter((q: Answer) => q.question === questionId).map(q => q.answer);
        }
        return answers;
    }

    /**
     * Revalidates the whole form
     * @param focusList true if should set focus on the list above the form
     * @param form A formgroup instance representing main or sub-form on the current page (base-form-component). By default it
     *             uses main FormGroup from the base-form instance
     */
    async revalidate(focusList?: boolean, form: UntypedFormGroup = this.form): Promise<boolean> {
        if (form.invalid) {
            LOG.trace('onSubmit', `... form valid ? [${!!form.valid}] ... set errors ...`);
            this.setErrorList(form);
            await this.revalidateForm();
            // this.revalidateForm(focusList, form);
            return true;
        }
        return false;
    }

    /**
     * Single control helper validity function used to determine if error message/block should be visible
     *
     * @param controlName
     * @param form A formgroup instance representing main or sub-form on the current page (base-form-component). By default it
     *             uses main FormGroup from the base-form instance
     */
    feedbackHasError(controlName: string, form: UntypedFormGroup = this.form): boolean {
        return form.controls[controlName].touched || this.isSubmitted(form);
    }

    /**
     * Revalidates single control
     *
     * @param controlName name of the control field
     * @param form A formgroup instance representing main or sub-form on the current page (base-form-component). By default it
     *             uses main FormGroup from the base-form instance
     */
    revalidateControl(controlName: string, form: UntypedFormGroup = this.form) {
        const control: AbstractControl = form.controls[controlName];
        this.errorMap.delete(controlName);
        LOG.debug('revalidateControl', `validation pending? ${control?.pending}`);
        if (!!control && !control.disabled && !control.valid) {
            LOG.debug('revalidateControl', `... controls [${controlName}] valid [${!!control.valid}]`);

            const errors: ErrorInfo[] = [];
            form.controls[controlName].updateValueAndValidity();
            if (control.errors) {
                for (const err of Object.keys(control.errors)) {
                    errors.push(this.createErrorInfo(err, controlName));
                }
            }

            this.errorMap.set(controlName, errors);
        }

        return control?.valid;
    }

    /**
     * Sets a list of errors on the given control.
     *
     * @param control, the AbstractControl
     * @param errors, the list of errors to add to the control
     */
    setFieldErrors(control: AbstractControl, errors: ErrorInfo[]): void {
        LOG.debug('setFieldErrors', `Is valid [${!!control.valid}] with [${control.errors != null ? (Object.keys(control.errors)).length : 0}] errors`);
        const fieldErrors = {};
        errors.forEach((e: any) => {
            fieldErrors[e.error] = e.message;
        });

        control.setErrors(fieldErrors);
    }

    /**
     * Shortcut for revalidateControl used in the template
     * @param $inputName
     * @param form A formgroup instance representing main or sub-form on the current page
     * (base-form-component). By default it uses main FormGroup from the base-form instance
     */
    revalidateInput($inputName: any, form: UntypedFormGroup = this.form) {
        this.revalidateControl($inputName.target.id, form);
    }

    /**
     * Retrieves error message for given control and validation error name
     * @param err
     * @param formName
     */

    getValidationMessage(err: ErrorInfo, formName: string): string {
        return err ? this.messages.getText(`message.error.${formName}.${err.field}.${err.error}`) : null;
    }

    /**
     * Sorted, constant list of invalid controls, needed to avoid concurrent modification in the template while submitting
     */
    getInvalidInputNames(): string[] {
        return Array.from(this.errorList.keys());
    }

    isSubmitted(form: UntypedFormGroup = this.form): boolean {
        return this.submitted || (form['submitted'] === true);
    }

    applyErrors(id: string, errors: ValidationErrors): void {
        if (!this.errorMap.has(id)) {
            this.errorMap.set(id, []);
        }

        if (!errors) {
            return;
        }

        const errs: ErrorInfo[] = this.errorMap.get(id);
        Object.keys(errors)
              .filter((err: any) => errs.filter((e: ErrorInfo) => e.field === id && e.error === err).length === 0)
              .map((key: string) => {
                  return {
                      field: id,
                      error: key
                  } as ErrorInfo;
              })
              .forEach((err: any) => errs.push(err));

    }
}

/**
 * Detailed error information and message
 */
export class ErrorInfo {
    field: string;
    error: string;
    message: string;
    value: string;
    lines: string[];
}
