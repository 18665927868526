import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLoadedGuard } from './core/app-loaded.guard';
import { AuthGuard } from './core/authentication/auth.guard';
import { RecommendationsComponent } from "./features/advice/recommendations/recommendations.component";
import { CharityGivingComponent } from "./features/charity/charity-giving-component/charity-giving.component";
import { CharityGivingGuard } from "./features/charity/charity-giving.guard";
import { DataCaptureComponent } from "./features/interview/data-capture/data-capture.component";
import { InterviewCannotCompleteComponent } from "./features/interview/interview-cannot-complete/interview-cannot-complete.component";
import { InterviewCompleteComponent } from './features/interview/interview-complete/interview-complete.component';
import { InterviewSummaryComponent } from "./features/interview/interview-summary/interview-summary.component";
import { InterviewGuard } from "./features/interview/interview.guard";

const routes: Routes = [
    { path: '', redirectTo: '/interview/WILL/start', pathMatch: 'full' },
    { path: 'interview/:type/start', component: DataCaptureComponent, canActivate: [AuthGuard, AppLoadedGuard, InterviewGuard] },
    { path: 'interview/:type/start/:charity', component: DataCaptureComponent, canActivate: [AuthGuard, AppLoadedGuard] },
    { path: 'interview/:type/:id/edit', component: DataCaptureComponent, canActivate: [AuthGuard, AppLoadedGuard] },
    { path: 'interview/:type/:id', component: DataCaptureComponent, canActivate: [AuthGuard, AppLoadedGuard, InterviewGuard] },
    { path: 'interview/:type/:id/summary', component: InterviewSummaryComponent, canActivate: [AuthGuard, AppLoadedGuard] },
    { path: 'interview/:type/:id/charity-giving', component: CharityGivingComponent, canActivate: [AuthGuard, AppLoadedGuard, CharityGivingGuard] },
    { path: 'interview/:type/:id/recommendations', component: RecommendationsComponent, canActivate: [AuthGuard, AppLoadedGuard, InterviewGuard] },
    { path: 'interview/:type/:id/complete', component: InterviewCompleteComponent, canActivate: [AuthGuard, AppLoadedGuard, InterviewGuard] },
    { path: 'interview/:type/:id/cannot-complete', component: InterviewCannotCompleteComponent, canActivate: [AuthGuard, AppLoadedGuard, InterviewGuard] },
    { path: 'another-way-to-do-good', component: CharityGivingComponent, canActivate: [AppLoadedGuard] },
    { path: 'another-way-to-do-good/:charity', component: CharityGivingComponent, canActivate: [AppLoadedGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AppLoadedGuard]
})
export class AppRoutingModule {
}
