import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APPLICATION_CONSTANTS } from '../../models/application.constants';
import { ApplicationType, ENV } from "../../models/environment.model";
import { LoggerService } from '../logger/logger.service';
import { AuthenticationService } from './authentication.service';

const LOG: LoggerService = LoggerService.get('AuthGuard');

/**
 * Protects the route components by checking that there is a valid token present.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private cookieService: CookieService,
        private authService: AuthenticationService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (`${next.url}`.indexOf('charity-giving') > -1 && !next.params.type && !next.params.id) {
            return true; // Standalone page

        } else if (!this.authService.isLoggedIn()) {
            LOG.debug('canActivate', `URL to return to: ${state.url}`);
            const timeout = APPLICATION_CONSTANTS.cookieTimeoutDays;
            const domain = ENV.config.get('mainDomain');

            this.cookieService.set('beq_redirect', state.url, timeout, '/', domain);
            window.location.href = `${ENV.applications.get(ApplicationType.HOME).baseUrl}/register`;
            return false;
        }

        return true;
    }

}
