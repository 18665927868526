import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { BaseInputControl } from '../base-input-control/base-input-control.component';
import {Answer} from "../../../../models/interview.model";

const LOG: LoggerService = LoggerService.get('TextControl');

/**
 * Basic textarea input field component with label, description element, validation message area and the input element styling (feedback icon, border change)
 *
 * @author Adam Jennings (ajennings)
 */
@Component({
    selector: 'large-text-control',
    templateUrl: '/large-text-control.component.html',
    styleUrls: [
        './large-text-control.component.sass',
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class LargeTextControl extends BaseInputControl implements OnInit {

    constructor(messageService: MessageService) {
        super(messageService);
        this.inputType = 'textarea';
        this.inputClass='textarea';
        this.LOG = LOG;
    }

    ngOnInit(): void {
        this.determineValidators();
        super.ngOnInit();
    }

    async turnVideoOn() {
        this.questionOverrides.next([new Answer({
            question: 'consent.permission_to_record',
            answer: 'yes'
        })]);
    }
}
