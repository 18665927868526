import { Injectable } from '@angular/core';
import { TokenService } from 'src/app/core/authentication/token.service';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { WebServiceError } from "../../models/http.model";
import { ServiceType } from "../../models/environment.model";
import { HttpService } from "../http/http.service";
import { ThreadUtils } from "../util/thread.utils";

const LOG: LoggerService = LoggerService.get('ContentService');

@Injectable({
    providedIn: 'root'
})
export class ContentService {

    constructor(
        private http: HttpService,
        private tokenService: TokenService) {}

    /**
     * Saves video to S3 and retrieves transcription for video answer
     */
    async startTranscription(id: number, contentType: string, name: string, content: string, retryCount: number = 0): Promise<any> {
        LOG.debug('getTranscription', `Saving video for ${id}, ${contentType}, ${name}`)
        const user = this.tokenService.getUser();
        let response: any = null;
        try {
            response = await this.http.post(ServiceType.CONTENT, 'start-transcription',
                {
                    userId: user.userId,
                    interviewId: id,
                    contentType: contentType,
                    name: name,
                    content: content
                })
        } catch (err) {
            LOG.error(`Error retrieving transcript... ${err}`);
        }

        if ((!response || response.errored) && retryCount < 3) {
            await ThreadUtils.sleep(500); // slow down retry attempts as were instant
            return this.startTranscription(id, contentType, name, content, ++retryCount);
        }

        return response;
    }

    /**
     * Saves video to S3 and retrieves transcription for video answer
     */
    async getTranscription(interviewId: number, job: string, location: string): Promise<string> {
        LOG.debug('getTranscription', `Getting transcription for: interview -> ${interviewId}, job -> ${job}`);
        try {
            return await this.http.get(
                ServiceType.CONTENT,
                'get-transcription',
                { interviewId },
                { job, location }
            );
        } catch (err) {
            LOG.error(`Error retrieving transcript... ${err}`);
        }
        return null;
    }

    /**
     * Loads footer.html from S3
     */
    async loadFooter(): Promise<string> {
        try {
            const response: any = await this.http.get(
                ServiceType.CONTENT,
                'get-asset',
                {
                    name: 'footer2.html',
                    location: 'homepage/home'
                }
            );

            if (!(response instanceof WebServiceError)) {
                return response?.content;
            }
        } catch (err) {
            LOG.error("Error loading footer!");
        }
        return '';
    }
}
