import { Injectable } from "@angular/core";
import { ApplicationType, ENV, Service, ServiceType } from "../../models/environment.model";
import { WebServiceError } from "../../models/http.model";
import { HttpService } from "../http/http.service";
import { LoggerService } from "../logger/logger.service";

const LOG: LoggerService = new LoggerService('ContentService');

/**
 * Communicates with the content-services
 *
 * @author Dan Bennett (dbennett)
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor(private http: HttpService) {}

    async loadEnvironment(): Promise<string> {
        try {

            if (!ENV.services?.size || !ENV.services.get(ServiceType.CONFIG) || !ENV.services.get(ServiceType.CONFIG)?.api['load-environment']) {
                const host = window.location.host;
                const baseUrl: string = ENV.configServiceHostMap[host];
                // const api: Map<string, string> = new Map<string, string>();
                const api: any = {};
                api['load-environment'] = '/public/application/environment';

                ENV.services.set(ServiceType.CONFIG, { baseUrl: baseUrl, tokenHeader: '', api: api } as Service);
            }
            const response: any = await this.http.get(
                ServiceType.CONFIG,
                'load-environment',
                null,
                {
                    s: Object.values(ServiceType),
                    a: Object.values(ApplicationType)
                },
                false
            );

            if (!(response instanceof WebServiceError)) {
                return response;
            }
        } catch (err) {
            LOG.error("Error loading environment config!");
        }
        return '';
    }
}
