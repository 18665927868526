import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CharityService } from '../../services/charity.service';
import { Charity } from '../../../models/interview.model';
import { LoggerService } from '../../logger/logger.service';
import { MessageService } from '../../message/message.service';

const LOG: LoggerService = LoggerService.get('CharityComponent');

/**
 * Component to handle interaction with the PayPal Giving app
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'charity-display',
    templateUrl: './charity-display.component.html',
    styleUrls: ['./charity-display.component.sass']
})
export class CharityDisplayComponent implements OnInit {

    @Input() interviewId: number;
    @Input() charity: Charity;
    @Input() confirmDonationTrigger: EventEmitter<Charity>;

    constructor(
        private charityService: CharityService,
        private messageService: MessageService) {
    }

    get messages(): MessageService {
        return this.messageService;
    }

    ngOnInit(): void {
        LOG.debug('init', `Loading charity: ${this.charity.initiator}`);
    }

    confirmDonation(): void {
        this.confirmDonationTrigger.next(this.charity);
    }
}
