<div class="container-fluid">
  <div class="row">
    <div class="content-panel col-md-12 col-sm-12 col-12">
      <div class="row">
        <div class="col">
          <h1 class="underline">{{ messages.getText('title.interview.' + type) }}</h1> <!-- aria -->
        </div>
      </div>
      @if (!!interviewId && !!interview) {
        <div class="row">
          <div id="left-side-view" class="col-lg-3 col-md-11 col-sm-12 col-12">
            <navigator #navigator
                       [editing]="editing"
                       [interview]="interview"
                       [qaChanges]="qaChanges"
                       [questionChanges]="questionChanges"
            ></navigator>

          </div>
          <div class="col-md-11 col-lg-6 col-sm-12 col-12">
            <h2 class="stageTitle" id="topicTitle" tabindex="-1">{{ this.messages.getText(navigator.getCurrentTopic().messageCode) }}</h2>
            <form [formGroup]="form" (ngSubmit)="doNothing()">
              @if (!!navigator.currentTopic && navigator.progress > 0) {
                <div class="form-group position-relative topic-form" [id]="navigator.currentTopic.id">

                  <!-- Individual questions groups -->
                  @for (question of questions; track question.id) {
                    <div class="position-relative">
                      @if (question.type === 'CHARITY_SEARCH' || !question.groupId) {
                        @switch (question.type) {
                          @case('NOTE') {
                            <note-control
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></note-control>
                          }
                          @case('NUMBER') {

                            <number-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></number-control>
                          }
                          @case('CURRENCY') {
                            <currency-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></currency-control>
                          }
                          @case('PERCENTAGE') {
                            <percentage-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></percentage-control>
                          }
                          @case('POSTCODE') {
                            <postcode-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></postcode-control>
                          }
                          @case('TEXT') {
                            <text-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></text-control>
                          }
                          @case('LARGE_TEXT') {
                            <large-text-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [questionOverrides]="questionOverrides"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></large-text-control>
                          }
                          @case('DATE') {
                            <date-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></date-control>
                          }
                          @case('EMAIL') {
                            <email-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></email-control>
                          }
                          @case('CHECKBOX') {
                            <checkbox-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></checkbox-control>
                          }
                          @case('RADIO') {
                            <radio-button-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></radio-button-control>
                          }
                          @case('DROPDOWN') {
                            <dropdown-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"></dropdown-control>
                          }
                          @case('CHARITY') {
                            <charity-control
                              #questionControl
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [question]="question"
                              [navigator]="navigator"
                              [onEnter]="triggerEvaluation()"></charity-control>
                          }
                          @case('CHARITY_SEARCH') {<charity-search-control
                            #questionControl
                            [baseForm]="this"
                            [form]="form"
                            [id]="question.id + '-wrapper'"
                            [attr.data-order]="question.order"
                            [question]="question"
                            [navigator]="navigator"
                            [onEnter]="triggerEvaluation()"></charity-search-control>

                          }
                          @case('VIDEO') {
                            <video-control
                              #questionControl
                              [interviewId]="interviewId"
                              [baseForm]="this"
                              [form]="form"
                              [id]="question.id + '-wrapper'"
                              [attr.data-order]="question.order"
                              [questionOverrides]="questionOverrides"
                              [question]="question"
                              [onEnter]="triggerEvaluation()"
                              (loading)="toggleLoading($event)"
                              (preventSave)="toggleSave($event)"></video-control>
                          }
                        }
                      }
                    </div>
                  }
                <!-- Repeating groups - 1 group allowed per page -->
                  @if (!!groupName) {
                    <control-group #controlGroup
                                   [groupName]="groupName"
                                   [dynamicSource]="dynamicGroupSource"
                                   [questions]="groupQuestions"
                                   [form]="form"
                                   [baseForm]="this"
                                   [navigator]="navigator"
                                   [onEnter]="triggerEvaluation()"
                                   [loading]="loading"></control-group>
                  }
              </div>
              } @else {
                <div class="loading text-center mx-auto my-auto">
                  <div class="spinner-border" role="status"></div>
                  <h3>Loading, please wait...</h3>
                </div>
              }
              @if (navigator.progress > 0) {
                <div class="form-group position-relative row">
                <div class="col-6">
                  @if (!editing) {
                    <div class="outer-button">
                      <button [disabled]="loading || preventSave === true || navigator.currentTopic?.order === 1 && navigator.currentSubTopic?.order === 1" class="btn btn-primary rounded-pill submit" id="prev"
                              type="button" (click)="back()" (keyup.enter)="back()">
                        @if (loading) {
                          <span class="spinner-border spinner-border-sm mr-1"></span>
                        }
                        {{ messages.getText('btn.prev', 'Previous page') }}
                      </button>
                    </div>
                  }
                  <div class="outer-button" *ngIf="editing" [class.text-right]="navigator.getCurrentTopic().endInterview">
                    <button id="save"
                            [disabled]="loading || preventSave === true"
                            class="btn btn-primary rounded-pill submit"
                            type="button" (click)="saveAndExit()" (keyup.enter)="saveAndExit()">
                      @if (loading) {
                        <span class="spinner-border spinner-border-sm mr-1"></span>
                      }
                      {{ messages.getText('btn.save-and-exit', 'Save and exit') }}
                    </button>
                  </div>
                </div>
                @if (!navigator.getCurrentTopic().endInterview) {
                  <div class="col-6">
                    <div class="outer-button">
                      <button [disabled]="loading || preventSave === true" class="btn btn-primary rounded-pill submit pull-right" id="next"
                              type="button" (click)="evaluate()" (keyup.enter)="evaluate()">
                        @if (loading) {
                          <span class="spinner-border spinner-border-sm mr-1"></span>
                        }
                        {{ messages.getText('btn.save-and-continue', 'Save and continue') }}
                      </button>
                    </div>
                  </div>
                }
                <div class="col-6">
                  <div class="outer-button" *ngIf="!editing" [class.text-right]="navigator.getCurrentTopic().endInterview">
                    <button id="save"
                            [disabled]="loading === true"
                            class="btn btn-primary rounded-pill submit"
                            type="button" (click)="confirmSaveAndExit()" (keyup.enter)="confirmSaveAndExit()">
                      @if (loading) {
                        <span class="spinner-border spinner-border-sm mr-1"></span>
                      }
                      {{ messages.getText('btn.save-and-exit', 'Save and exit') }}
                    </button>
                  </div>
                </div>
              </div>
              }
              <div class="action-result alert" role="alert" [hidden]="actionSuccess === null"
                   [ngClass]="{'alert-success' : actionSuccess === true, 'alert-danger' : actionSuccess === false }">
                {{ actionMessage }}
              </div>
            </form>
          </div>
          <div class="sidebar-panel col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="row">
              <div class="col help-panel">
                <h2>Help</h2>
                <div>
                  <a onclick="Intercom('show');" href="javascript:;">{{ messages.getText('label.login.side.chat') }}</a>
                </div>
                <div>
                  <a href="https://calendly.com/bequeathedsupport/bequeathed-user-support-call" target="_blank">{{ messages.getText('link.support-call') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      } @else {
        <!-- Show loader -->
      }
    </div>
  </div>
</div>

<app-confirm-popup #confirmPopup (confirmAction)="saveAndExit()">
  <span>{{ messages.getText('dialog.save-and-exit.content') }}</span>
</app-confirm-popup>

<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">{{ messages.getText('title.profile-update') }}</h1>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">{{ messages.getText('label.date-of-birth') }}</label>
        <div class="input-group">
          <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
          <div class="input-group-append">
            <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{ messages.getText('btn.save') }}</button>
  </div>
</ng-template>
