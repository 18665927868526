/**
 * Class representing a user.
 */
export class User {

    userId: number;
    active: boolean;
    email: string;
    password: string;
    token: string;

    convert(user: any): User {
        if (!user) {
            return this;
        }

        this.userId = user.userId;
        this.email = user.email;
        this.active = user.active || false;

        return this;
    }
}
