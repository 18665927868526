import { objectToCamel } from 'ts-case-convert';

/**
 * Abstract Entity Parent
 *
 * @author J. R. Smith
 * @since 15/05/2024
 */
export abstract class Entity {

    static convert<T extends Entity>(this: { new(): T }, obj: object, snaked: boolean = false): T {
        if (snaked) {
            obj = objectToCamel(obj);
        }

        return Object.assign(new this(), obj);
    }

}
