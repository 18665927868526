import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CharityService } from '../../services/charity.service';
import { Charity } from '../../../models/interview.model';
import { LoggerService } from '../../logger/logger.service';
import { MessageService } from '../../message/message.service';
import { PageUtil } from '../../util/page.util';

const LOG: LoggerService = LoggerService.get('CharityComponent');
declare let paypal: any;

/**
 * Component to handle interaction with the PayPal Giving app
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'charity',
    templateUrl: './charity.component.html',
    styleUrls: ['./charity.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class CharityComponent implements OnInit {

    @Input() userId: number;
    @Input() interviewId: number;
    @Input() modal: any;
    @Input() charity: Charity;
    @Input() paymentErrorTrigger: EventEmitter<any>;
    @Input() paymentMadeTrigger: EventEmitter<any>;
    @Input() paymentCancelledTrigger: EventEmitter<any>;

    donationAmount: UntypedFormControl;
    consentToShareDetails: UntypedFormControl;
    includeGiftAid: UntypedFormControl;
    loading: boolean;
    errored: boolean;

    paymentStatus: string;
    paymentError: string;

    private orderId: string;

    constructor(
        private charityService: CharityService,
        private messageService: MessageService) {
    }

    get messages(): MessageService {
        return this.messageService;
    }

    ngOnInit(): void {
        if (!this.charity) {
            LOG.warn('Charity is null!!');
            return;
        }

        LOG.debug('init', `Loading charity: ${this.charity.initiator}`);

        this.donationAmount = new UntypedFormControl('', Validators.required);
        this.consentToShareDetails = new UntypedFormControl(false, Validators.requiredTrue);
        this.includeGiftAid = new UntypedFormControl();
        this.loading = false;
        this.errored = false;
        this.orderId = null;
        this.paymentStatus = null;
        this.paymentError = null;

        PageUtil.setFocus('modal-basic-title');
        setTimeout(() => this.donationAmount.markAsUntouched(), 55);

        // Delay added in attempt to handle possible race condition with the PayPal SDK
        setTimeout(
            () =>
                paypal.Buttons({
                    style: {
                        label: 'donate',
                        layout: 'horizontal',
                        color: 'blue',
                        shape: 'pill',
                        tagline: false
                    },

                    onInit: (data: any, actions: any) => {
                        actions.disable();

                        [this.donationAmount.valueChanges, this.consentToShareDetails.valueChanges]
                            .forEach(control =>
                                control.subscribe(() => {
                                    if (this.validate()) {
                                        actions.enable();
                                    } else {
                                        actions.disable();
                                    }
                                })
                            );

                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onClick: (data: any, actions: any) => {
                        this.errored = false;
                        if (!this.validate()) {
                            if (this.donationAmount.invalid) {
                                PageUtil.setFocus('charity-amount');
                            } else if (this.consentToShareDetails.invalid) {
                                PageUtil.setFocus('consent-to-share');
                            }
                        }

                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    createOrder: async (data: any, actions: any) => {
                        if (this.errored) {
                            return;
                        }

                        LOG.debug('init', `Creating order for: ${this.charity.name}`);
                        this.loading = true;
                        try {
                            this.orderId = await this.charityService.createPayPalOrder(
                                this.charity.nonProfitId,
                                this.charity.name,
                                this.donationAmount.value,
                                this.includeGiftAid.value,
                                this.consentToShareDetails.value,
                                this.userId,
                                this.interviewId
                            );
                        } catch (e) {
                            LOG.warn('Error creating order!');
                        }
                        if (!this.orderId) {
                            this.paymentStatus = 'FAILED';
                            PageUtil.setFocus('payment-made-text');
                            return;
                        }
                        return this.orderId;
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onApprove: async (data: any, actions: any) => {
                        LOG.debug('init', `Capturing order for: ${this.charity.name}`);
                        const donation: any = await this.charityService.capturePayPalOrder(this.orderId);

                        this.paymentStatus = donation.paymentStatus;

                        if (this.paymentStatus === 'CONFIRMED') {
                            setTimeout(
                                () => {
                                    this.paymentMadeTrigger.next();
                                    this.modal.close();
                                },
                                5000
                            );
                        }

                        PageUtil.setFocus('payment-made-text');
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onCancel: async (data: any) => {
                        LOG.debug('init', `Cancelling order for: ${this.charity.name}`);
                        await this.charityService.cancelPayPalOrder(this.orderId);
                        this.loading = false;

                        this.paymentStatus = 'CANCELLED';

                        PageUtil.setFocus('payment-made-text');
                    }
                })
                .render(`#charity-button-${this.charity.nonProfitId}`),
            250
        );
    }

    private validate(): boolean {
        LOG.debug('validate', 'Validating form...');
        const hasCurrentError: boolean = (this.donationAmount.invalid || this.consentToShareDetails.invalid);
        this.errored = this.errored || hasCurrentError;
        return !hasCurrentError;
    }

    confirmCancellation(): void {
        this.paymentCancelledTrigger.next();
        this.modal.close();
    }
}
