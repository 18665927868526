import { Injectable } from '@angular/core';
import { WebServiceError } from "../../models/http.model";
import { LoggerService } from '../logger/logger.service';
import { Charity } from '../../models/interview.model';
import { CharityOption } from '../../models/navigator.model';
import { ServiceType } from "../../models/environment.model";
import { HttpService } from "../http/http.service";

const LOG: LoggerService = LoggerService.get('CharityService');

/**
 * Service to handle interaction with the PayPal Giving app
 *
 * @author Dan Bennett (dbennett)
 */
@Injectable({
    providedIn: 'root'
})
export class CharityService {

    constructor(private http: HttpService) {}

    async findPayPalCharityByInitiator(initiator: string): Promise<Charity> {
        return this.http.get(ServiceType.CHARITY, 'ppgf-get-by-initiator', null, { initiator })
           .then((charity: any) => {
               if (charity instanceof WebServiceError) {
                   LOG.error('Error finding charity by initiator!');
                   return null;
               }
               return charity;
           });
    }

    async findPayPalCharityByName(name: string, excludePartners: boolean = false): Promise<Charity[]> {
        return this.http.get(ServiceType.CHARITY, 'ppgf-search-charities', null, { name, excludePartners })
            .then((charities: any) => {
               if (charities instanceof WebServiceError) {
                   LOG.error('Error finding charities by name!');
                   return [];
               }
               return charities
                   .filter((c: any) => !!c)
                   .map((c: any) => new Charity(c))
                   .sort((c: Charity, d: Charity) => c.name.localeCompare(d.name));
           });
    }

    async getFeaturedCharities(): Promise<Charity[]> {
        return this.http.get(ServiceType.INTERVIEW, 'ppgf-get-featured-charities')
            .then((charities: any) => {
               if (charities instanceof WebServiceError) {
                   LOG.error('Error retrieving featured charities!');
                   return [];
               }
               return charities
                   .filter((c: any) => !!c)
                   .filter((c: any) => !c.optOut)
                   .map((c: any) => new Charity(c))
                   .sort((c: Charity, d: Charity) => c.name.localeCompare(d.name));
           });
    }

    async createPayPalOrder(nonProfitId: number, name: string, amount: number, includeGiftAid: boolean, consentToShare: boolean, userId: number, interviewId: number): Promise<string> {
        const body = {
            interviewId: interviewId || null,
            userId: userId || null,
            nonProfitId: nonProfitId,
            charityName: name,
            amount: amount,
            includeGiftAid: includeGiftAid || false,
            consentToShare: consentToShare || false
        }

        return this.http.post(ServiceType.CHARITY, 'ppgf-create-order', body)
           .then((orderId: any) => {
               if (orderId instanceof WebServiceError) {
                   LOG.error(`Error creating order! ${orderId}`);
                   return null;
               }
               return orderId;
           });
    }

    async capturePayPalOrder(orderId: string): Promise<void> {
        return this.http.get(ServiceType.CHARITY, 'ppgf-capture-order', null, orderId);
    }

    async cancelPayPalOrder(orderId: string): Promise<void> {
        return this.http.get(ServiceType.CHARITY, 'ppgf-cancel-order', null, orderId);
    }

    async getCharityDetails(charities: string[]): Promise<CharityOption[]> {
        return this.http.post(ServiceType.CHARITY, 'get-charity-by-descriptions', charities)
            .then((response: any[] | WebServiceError) => {
               if (response instanceof WebServiceError) {
                   LOG.error('Error retrieving charity details!');
                   return [];
               }
               return response.map((c: any) => new CharityOption(c));
           });
    }

    async getPartnerCharities(): Promise<CharityOption[]> {
        return this.http.get(ServiceType.CHARITY, 'get-partner-charities')
            .then((response: any[] | WebServiceError) => {
                if (response instanceof WebServiceError) {
                    LOG.error('Error retrieving charity details!');
                    return [];
                }

                return response.map((c: any) => new CharityOption(c));
            });
    }

    async getCharityBy(id: string): Promise<CharityOption> {
        return this.http.get(ServiceType.CHARITY, 'get-charity-by-id', { id })
            .then((response: any[] | WebServiceError) => {
               if (response instanceof WebServiceError) {
                   LOG.error('Error retrieving charity details!');
                   return null;
               }
               return new CharityOption(response);
           });
    }

    async hasDonatedToPPGF(type: any, interviewId: any): Promise<boolean> {
        return this.http.get(ServiceType.CHARITY, 'ppgf-has-donated', { interviewId })
            .then((response: boolean | WebServiceError) => {
               if (response instanceof WebServiceError) {
                   LOG.error('Error retrieving charity details!');
                   return false;
               }
               return response;
           });
    }

    async loadPreselectedCharities(charities: string[]): Promise<Charity[]> {
        return this.http.post(ServiceType.CHARITY, 'ppgf-search-exact-match', charities)
           .then((charities: any) => {
               if (charities instanceof WebServiceError) {
                   LOG.error('Error finding charities by name!');
                   return [];
               }
               return charities
                   .filter((c: any) => !!c)
                   .map((c: any) => new Charity(c));
           });
    }
}
