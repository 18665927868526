import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { DateUtils } from '../../../util/date.utils';
import { PageUtil } from '../../../util/page.util';
import { ValidationUtils } from '../../../util/validation.util';
import { ErrorInfo } from '../../base-form/base-form.component';
import { DateValidator } from '../../validators/date.validator';
import { BaseInputControl } from '../base-input-control/base-input-control.component';

const LOG: LoggerService = LoggerService.get('DateControl');

/**
 * Displays a date control with details
 *
 * @author Mateusz Jablonski (mjablonski)
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'date-control',
    templateUrl: './date-control.component.html',
    styleUrls: [
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass',
        './date-control.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class DateControl extends BaseInputControl implements OnInit {

    @Input() dateLabels: string[] = ['dd', 'mm', 'yyyy'];
    @Input() day: UntypedFormControl;
    @Input() month: UntypedFormControl;
    @Input() year: UntypedFormControl;

    // Check if all controls are valid
    // ONLY then, apply further validations (in data capture?)
    // OR store validated value, if already validated don't run again

    constructor(messageService: MessageService) {
        super(messageService);
        this.inputType = 'text';

        this.LOG = LOG;
    }

    ngOnInit(): void {
        let existingAnswer: string | string[] = this.baseForm.getAnswer(this.question.id);
        if (existingAnswer.length > 0) {
            existingAnswer = existingAnswer[0];
        }

        const d: number = DateUtils.getDay(existingAnswer as string);
        const m: number = DateUtils.getMonth(existingAnswer as string);
        const y: number = DateUtils.getYear(existingAnswer as string);
        this.control = new UntypedFormGroup({
            day: new UntypedFormControl(d ? `${d}` : null, [DateValidator.isDayValid()]),
            month: new UntypedFormControl(m ? `${m}` : null, [DateValidator.isMonthValid()]),
            year: new UntypedFormControl(y ? `${y}` : null, [DateValidator.isYearValid()])
        });
        this.form.controls[this.question.id] = this.control;
        this.day = (this.control as UntypedFormGroup)?.controls['day'] as UntypedFormControl;
        this.month = (this.control as UntypedFormGroup)?.controls['month'] as UntypedFormControl;
        this.year = (this.control as UntypedFormGroup)?.controls['year'] as UntypedFormControl;

        this.baseForm.questionControls.push(this);

        this.checkForErrorsOnEdit();
    }

    getDateFieldName(name: string): string {
        return this.question.id + '-' + name;
    }

    controlNameHasError(name: string): boolean {
        const control = (this.control as UntypedFormGroup).controls[name];
        return control.touched && control.invalid;
    }

    formHasError(): boolean {
        this.revalidateDateInput('day');
        this.revalidateDateInput('month');
        this.revalidateDateInput('year');
        return this.controlNameHasError('day')
            || this.controlNameHasError('month')
            || this.controlNameHasError('year')
            || !!this.control.errors;
    }

    dayIsValid(): boolean {
        return ValidationUtils.valid(this.day.value, /((|0)?[1-9]|[12]\d|3[01])/);
    }

    monthIsValid(): boolean {
        return ValidationUtils.valid(this.month.value, /^((|0)?[1-9]|1[012])$/);
    }

    yearIsValid(): boolean {
        return ValidationUtils.valid(this.year.value, /^(18|19|20|21)\d{2}$/);
    }

    allValid(): boolean {
        return this.dayIsValid() && this.monthIsValid() && this.yearIsValid();
    }

    anyTouched(): boolean {
        return [this.day, this.month, this.year].reduce((acc: boolean, val: UntypedFormControl) => val.touched || acc, false);
    }

    allTouched(): boolean {
        return [this.day, this.month, this.year].reduce((acc: boolean, val: UntypedFormControl) => val.touched && acc, true);
    }

    /**
     * Shortcut for revalidateControl used in the template
     * @param controlName
     */
    async revalidateDateInput(controlName: string): Promise<void> {
        const form: UntypedFormGroup = (this.control as UntypedFormGroup);
        const control = form.controls[controlName];

        this.baseForm.errorMap.delete(controlName);
        LOG.trace('revalidateDateInput', `Validation pending? ${control?.pending}`);
        if (!!control && !control.pending && !control.disabled && !control.valid) {

            LOG.debug('revalidateDateInput', `Control [${controlName}] is valid? [${!!control.valid}]`);
            const errors: ErrorInfo[] = [];
            if (control.errors) {
                if (control.errors['required'] || control.errors[`required-${controlName}`] || control.errors[`invalid-${controlName}`]) {
                    errors.push(this.baseForm.createErrorInfo(`required-${controlName}`, controlName));
                }
            }

            this.baseForm.errorMap.set(`${this.question.id}-${controlName}`, errors);
        }
    }

    focus(): void {
        PageUtil.findNativeElement(this.question.id + '-day').focus();
    }

    getValue(): string | string[] {
        if (this.dayIsValid() && this.monthIsValid() && this.yearIsValid()) {
        return `${this.day.value}/${this.month.value}/${this.year.value}`;
    }
        return null;
    }
}
