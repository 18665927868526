<div class="container-fluid">
  <div class="row">
    <div *ngIf="loading" class="loading-panel col-12">
      <span class="spinner-border spinner-border-sm mr-1"></span>
      <h1>{{ messages.getText('title.advice.analysing') }}</h1>
    </div>
    <div *ngIf="!loading" class="content-panel col-12">
      <div class="row">
        <div class="col">
          <h1 class="underline">{{ messages.getText('title.recommendations') }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 advice-recommendations">
          <p tabindex="0" [innerHtml]="messages.getText('advice.recommendations')"></p>
          <div class="rounded-frame container" *ngIf="!loading">
            <advice-control *ngFor="let option of adviceOptionsConfig;" #recommendation
                            class="card"
                            [config]="option"
                            [recommendation]="getRecommendation(option.id)"
                            [adviceId]="advice.id"
                            [interviewId]="interviewId"
                            [lastOption]="isLastOption(option)"
                            (interviewCompleteEvent)="completeInterview()"
            ></advice-control>
            <div>
              <div class="alert-danger error pill form-error-list" *ngIf="requirementsUnmet">
                <div class="error-list-wrapper" id="err-requirements-unmet" aria-live="assertive">
                  <ul><li><span>{{ messages.getText('error.recommendations.answer-all', 'Please select an answer for each.') }}</span></li></ul>
                </div>
              </div>
              <div class="alert-danger error pill form-error-list" *ngIf="errorSaving">
                <div class="error-list-wrapper" id="err-saving" aria-live="assertive">
                  <ul><li><span>{{ messages.getText('error.recommendations.saving', 'There was an error submitting your decision, please try again.') }}</span></li></ul>
                </div>
              </div>
              <div class="col text-right">
                <button class="btn btn-primary rounded-pill"
                        [disabled]="saving"
                        (click)="completeInterview()">
                  {{ messages.getText('btn.submit-decisions', 'Submit my decisions') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
