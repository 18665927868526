import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from '../../core/logger/logger.service';
import { ApplicationType, ENV } from "../../models/environment.model";
import { InterviewService } from '../../core/services/interview.service';
import { AdviceService } from "../../core/services/advice.service";

const LOG: LoggerService = LoggerService.get('InterviewGuard');

/**
 * Guard to protect access to another users interview
 *
 * @author Dan Bennett (dbennett)
 */
@Injectable({
    providedIn: 'root'
})
export class InterviewGuard  {

    constructor(
        private interviewService: InterviewService,
        private adviceService: AdviceService,
        private router: Router) {
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const isStartPageUrl: boolean = `${next.url}`.indexOf('start') > -1;
        if (isStartPageUrl) {
            if (await this.interviewService.hasWill(next.params.type)) {
                LOG.warn('Interview already exists, redirecting to my-wishes page instead...');
                window.location.href = `${ENV.applications.get(ApplicationType.HOME).baseUrl}/my-wishes`;
                return false;
            }
            return true;
        }

        const hasAccess: boolean = await this.interviewService.hasAccess(next.params.type, next.params.id);
        if (!hasAccess) {
            LOG.warn('User does NOT have access to this interview!');
            window.location.href = `${ENV.applications.get(ApplicationType.HOME).baseUrl}/my-wishes`;
            return false;
        }

        const isWillComplete: boolean = await this.interviewService.isComplete(next.params.type, next.params.id);
        const isCompletePageUrl: boolean = `${next.url}`.indexOf('complete') > -1;

        const isAdviceComplete: boolean = await this.adviceService.isComplete(next.params.id);
        const isRecommendationsPageUrl: boolean = `${next.url}`.indexOf('recommendations') > -1;

        LOG.debug('canActivate', `Is complete? ${isWillComplete} Does '${next.url}' contain 'complete'? ${isCompletePageUrl}`);
        if (isWillComplete && isAdviceComplete && !isCompletePageUrl) {
            LOG.warn('Interview is complete, redirecting to completion page...');
            await this.router.navigate(['interview', next.params.type, next.params.id, 'complete']);
            return false;
        } else if (isWillComplete && !isAdviceComplete && !isRecommendationsPageUrl) {
            LOG.warn('Interview is not complete! Redirecting to interview page...');
            await this.router.navigate(['interview', 'ADVICE', next.params.id, 'recommendations']);
            return false;
        } else if (!isWillComplete && (isCompletePageUrl || isRecommendationsPageUrl)) {
            LOG.warn('Interview is not complete! Redirecting to interview page...');
            await this.router.navigate(['interview', next.params.type, next.params.id]);
            return false;
        }

        // Check if an initiator has been set in the URL
        if (next.params.charity) {
            this.interviewService.initiatorParams['beq_initiator_type'] = 'charity';
            this.interviewService.initiatorParams['beq_initiator'] = next.params.charity;
        }
        return true;
    }
}
