import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs/internal/Subject';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { LoggerService } from '../../../core/logger/logger.service';
import { MessageService } from '../../../core/message/message.service';
import { BaseForm, ErrorInfo } from '../../../core/ui/base-form/base-form.component';
import { ConfirmPopupComponent } from '../../../core/ui/confirm-popup/confirm-popup.component';
import { BaseControl } from '../../../core/ui/control/base-control/base-control.component';
import { CharityControl } from '../../../core/ui/control/charity-control/charity-control.component';
import { CharitySearchControl } from "../../../core/ui/control/charity-search-control/charity-search-control.component";
import { PageUtil } from '../../../core/util/page.util';
import { RulesService } from '../../../core/services/rules.service';
import { InterviewService } from '../../../core/services/interview.service';
import { Answer } from '../../../models/interview.model';
import { Question } from '../../../models/navigator.model';
import { NavigatorComponent } from '../navigator/navigator.component';
import { LawfirmService } from "../../../core/services/lawfirm.service";
import { HtmlUtils } from "../../../core/util/html.utils";

const LOG: LoggerService = LoggerService.get('DataCaptureComponent');

enum InterviewAction {
    start = 'start',
    edit = 'edit',
    summary = 'summary'
}

/**
 * Data capture component to render questions and manage the QA process
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'app-data-capture',
    templateUrl: './data-capture.component.html',
    styleUrls: [
        './data-capture.component.sass',
        '../../../core/ui/base-form/base-form.component.sass'
    ]
})
export class DataCaptureComponent extends BaseForm implements OnInit {

    @ViewChild('confirmPopup') confirmationPopup: ConfirmPopupComponent;
    @ViewChild('navigator') navigator: NavigatorComponent;

    interviewId: number;
    type: string = 'WILL'; // Set as default but should be retrieved from the route
    action: string = InterviewAction.edit;

    errorsOnEdit: Map<string, string[]>;
    questions: Question[];
    qaChanges: Subject<Answer[]> = new ReplaySubject<Answer[]>();
    questionChanges: Subject<Question[]> = new ReplaySubject<Question[]>();
    questionOverrides: Subject<Answer[]> = new ReplaySubject<Answer[]>();

    constructor(
        private elementRef: ElementRef,
        private title: Title,
        private router: Router,
        protected cookieService: CookieService,
        public rulesService: RulesService,
        protected authenticationService: AuthenticationService,
        protected messageService: MessageService,
        private lawfirmService: LawfirmService,
        protected changeDetector: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private interviewService: InterviewService,
        private modalService: NgbModal,
        private route: ActivatedRoute) {

        super(messageService, changeDetector, cookieService, authenticationService, rulesService);

        this.formName = 'interview';
        this.form = new UntypedFormGroup({});
    }

    get editing(): boolean {
        return this.interviewService.summaryState?.editing;
    }

    isRepeatingGroup(questions: Question[]): boolean {
        if (questions) {
            return questions.filter(q => !!q.groupId && q.type !== 'HIDDEN' && q.type !== 'CHARITY_SEARCH').length > 0;
        }
        return false;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.qaChanges = new ReplaySubject<Answer[]>();
        this.questionChanges = new ReplaySubject<Question[]>();

        this.route.queryParams.subscribe(async (params: { beq_initiator: string; beq_initiator_type: string; origin: string }) => {
            if (params) {
                const initiator = params.beq_initiator;
                const initiatorType = params.beq_initiator_type;

                if (!!initiator && !!initiatorType) {
                    if (initiatorType == 'lawfirm') {
                        const branding = await this.lawfirmService.getBrandingFor(initiator);

                        HtmlUtils.updateCss('primary', branding.colourLightBase   || '#123257');
                        HtmlUtils.updateCss('secondary', branding.colourSuccess   || '#f6d100');
                        HtmlUtils.updateCss('tertiary', branding.colourHighlight  || '#0066b2');
                        HtmlUtils.updateCss('quaternary', branding.colourFail     || '#f28e00');

                        HtmlUtils.updateCss('text-primary', branding.colourLightText   || '#fff');
                        HtmlUtils.updateCss('text-secondary', branding.colourLightText || '#123257');

                        HtmlUtils.updateCss('btn-primary-base', branding.colourSuccess      || '#f6d100');
                        HtmlUtils.updateCss('btn-primary-text', branding.colourLightBase    || '#123257');
                        HtmlUtils.updateCss('btn-primary-hover', branding.colourFail        || '#f28e00');
                        HtmlUtils.updateCss('btn-secondary-base', branding.colourLightBase  || '#123257');
                        HtmlUtils.updateCss('btn-secondary-text', branding.colourLightText  || '#fff');
                        HtmlUtils.updateCss('btn-secondary-hover', branding.colourHighlight || '#0066b2');
                        HtmlUtils.updateCss('btn-tertiary-base', branding.colourSuccess     || '#123257');
                        HtmlUtils.updateCss('btn-tertiary-text', branding.colourLightBase   || '#fff');
                        HtmlUtils.updateCss('btn-tertiary-hover', branding.colourFail       || '#0066b2');

                        this.updateBranding(branding.logoLarge, branding.logoSmall);
                    }
                }
            }
        });

        this.route.params.subscribe((params: { id: number; type: string; action: string }) => {
            this.type = params.type;

            // If no action is present on the URL but an ID is, then assume it's a continuing interview otherwise start a new on
            if (!!params.id && !isNaN(params.id)) {
                this.interviewId = params.id;
                this.action = InterviewAction.edit;
            } else {
                this.action = InterviewAction.start;
            }

            // TODO :: get init and init_type from route.params (query params? -> do this app.comp level?)
            // TODO :: make call to lawfirm to get branding... -> stored: app storage (tmp)

            // applyBranding();

            this.initInterview().then(() => LOG.debug('init', `Interview initialised! Type: ${this.type}, ID: ${this.interviewId}`));
        });
    }

    private async initInterview(): Promise<void> {
        this.form = new UntypedFormGroup({});
        this.loading = true;

        try {
            if (InterviewAction.edit === this.action) {
                this.interview = await this.interviewService.edit(this.type, this.interviewId);
            } else {
                this.interview = await this.interviewService.start(this.type);
            }
        } catch (err) {
            LOG.error(`Error loading the interview! ${err}`);
            // ToDo :: Show an error
            // this.goToHome();
            return;
        }

        if (!!this.interview && !!this.interview.id) {
            this.interviewId = this.interview.id;
        }

        // Forward to new URL to include interview ID, ensures session is preserved on a refresh
        const url: string = this.router.url;
        if (url.match(/\/interview\/[A-Za-z]+\/[0-9]+\/edit/) || url.match(/\/interview\/[A-Za-z]*\/start.*/)) {
            LOG.debug('initInterview', `Changing URL to [/interview/${this.type}/${this.interviewId}] so that session is preserved on refresh`);

            if (this.interviewId) {
                // TODO :: add init and init_type to url, able to grab paras again and load branding... [~pre-loads]
                this.router.navigateByUrl(`/interview/${this.type}/${this.interviewId}`, { replaceUrl: true });
                return;
            }
            // ToDo :: Show error
            return;
        }

        // Validate answer-set against existing rules and apply to the questions
        this.interview.errorsOnEdit = await this.rulesService.validateAll(this.interview.qa);

        // Subscribe to any changes in the question list
        this.questionChanges.subscribe((questions: Question[]) => {
            if (this.interviewService.summaryState?.enabled
                && !!this.interviewService.summaryState?.editingTopic
                && this.interviewService.summaryState?.editingTopic !== this.navigator.currentTopic.id
                && !this.errorsOnEdit) {

                const editedTopic: string = this.interviewService.summaryState.editingTopic;
                const editedSubTopic: string = this.interviewService.summaryState.editingSubTopic;

                LOG.debug('questionChanges', `Switching to editing topic: ${editedTopic} -> ${editedSubTopic}`);
                this.navigator.goToSubTopic(editedTopic, editedSubTopic);

                return;
            } else if (
                (!this.interviewService.summaryState?.enabled || this.interviewService.summaryState?.editing)
                && !this.interviewService.summaryState?.editingTopic
                && this.navigator.completed
                && !this.errorsOnEdit) {

                LOG.debug('questionChanges', 'Navigating to summary...');
                this.goToSummary();
                return;
            }

            // Reset editing parameters as they're no longer needed after this
            // this.interviewService.summaryState.editingTopic = null;
            // this.interviewService.summaryState.editingSubTopic = null;

            LOG.debug('questionChanges', 'Rendering questions...');
            this.groupName = null;
            this.dynamicGroupSource = null;
            this.groupQuestions = [];
            this.controlGroup?.destroy();
            this.questionControls = [];
            this.errorMap = new Map<string, ErrorInfo[]>();
            this.errorList = new Map<string, ErrorInfo[]>();
            this.form.controls = {};

            if (this.isRepeatingGroup(questions)) {
                const groupQuestions: Question[] = questions?.filter((q: Question) => !!q.groupId && q.type !== 'HIDDEN');
                if (groupQuestions.length > 0) {
                    this.groupName = groupQuestions[0]?.groupId;
                    this.dynamicGroupSource = groupQuestions[0]?.dynamicGroupSource;
                    this.groupQuestions = groupQuestions;
                    if (this.controlGroup) {
                        this.controlGroup.groupName = this.groupName;
                        this.controlGroup.dynamicSource = this.dynamicGroupSource;
                        this.controlGroup.questions = this.groupQuestions;
                        this.controlGroup?.ngOnInit();
                    }
                }
            }
            this.questions = questions.sort((a: Question, b: Question) => a.order - b.order);

            PageUtil.setFocus('topicTitle');

            this.loading = false;
        });

        this.questionOverrides.subscribe((answers: Answer[]) => {
            this.evaluateOverride(answers)
        })

        // Communicate changes to the QA
        this.qaChanges.next(this.interview.qa);
    }

    async back(): Promise<void> {
        if (this.loading) {
            LOG.debug('back', 'Interview is already changing, please wait...');
            return;
        }
        this.loading = true;
        this.navigator.goToPreviousTopic();
        this.loading = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    triggerEvaluation(): Function {
        return () => this.evaluate();
    }

    private async gatherAnswersIntoQA(): Promise<boolean> {

        if (!!this.controlGroup && !(await this.controlGroup.applyValues())) {
            return false;
        }

        // Remove existing answers to make sure they're overwritten
        this.questions
            .filter((question: Question) => !question.groupId && question.type !== 'CHARITY')
            .forEach((question: Question) => {
                for (let i: number = 0; i < this.interview.qa.length; i++) {
                    if (this.interview.qa[i].question === question.id) {
                        this.interview.qa.splice(i, 1);
                        // Makes sure all multiple answer are being removed
                        if (!question.multiple) {
                            return;
                        }
                        i--; // Don't increment index in case of multiple answers
                    }
                }
            });

        this.questionControls
            .filter((control: BaseControl) => !control.question.groupId || control instanceof CharitySearchControl)
            .forEach((control: BaseControl) => {

                if (control instanceof CharityControl) {
                    (control as CharityControl).applyValues(this.interview);
                    return;
                }

                if (control instanceof CharitySearchControl) {
                    (control as CharitySearchControl).applyValues(this.interview);
                    return;
                }

                let value: string | string[] = control.getValue();
                if (typeof value === 'string') {
                    value = [value];
                }

                if (!value) {
                    value = [];
                }

                value.forEach((val: string) => this.interview.qa.push(new Answer({ question: control.question.id, answer: val })));
            });

        return true;
    }

    async evaluate(): Promise<void> {
        if (this.loading) {
            LOG.debug('evaluate', 'Interview is already evaluating, please wait...');
            return;
        }
        await this.revalidateForm();
        if (this.form.invalid || this.errorMap.size > 0) {
            LOG.debug('evaluate', `Form is still invalid! Errors in form: ${this.errorMap.size}`);
            return;
        }

        this.loading = true;
        try {
            if (!(await this.gatherAnswersIntoQA())) {
                this.loading = false;
                return;
            }

            LOG.debug('evaluate', 'Evaluating interview...');
            this.interview = await this.interviewService.evaluate(this.interview.type, this.interview.id, this.interview.qa, this.interview.removed);
            if (!this.interview || !this.interview.id) {
                LOG.error('No interview returned!');
            } else {

                if (this.interviewService.summaryState.enabled) {
                    this.interviewService.summaryState.editingTopic = null;
                    this.interviewService.summaryState.editingSubTopic = null;
                }

                this.qaChanges.next(this.interview.qa);
                // Scroll back to the top of the page
                const element = document.getElementById('main-focusable');
                if (element) {
                    window.scroll({
                        top: element.getBoundingClientRect().top + window.scrollY - 200,
                        behavior: 'smooth'
                    });
                }
            }

        } catch (e) {
            LOG.error('Error evaluating interview!');
        }
        this.loading = false;
    }

    async evaluateOverride(qa: Answer[]): Promise<void> {
        try {
            qa.forEach((answer: Answer) => {
                const matchingAnswers = this.interview.findAnswers(answer.question, this.interview.qa);
                if (matchingAnswers.length > 0) {
                    matchingAnswers[0].answer = answer.answer;
                }
            })

            this.interview = await this.interviewService.evaluate(this.interview.type, this.interview.id, this.interview.qa, this.interview.removed);
            if (!this.interview || !this.interview.id) {
                LOG.error('No interview returned!');
            } else {

                if (this.interviewService.summaryState.enabled) {
                    this.interviewService.summaryState.editingTopic = null;
                    this.interviewService.summaryState.editingSubTopic = null;
                }

                this.qaChanges.next(this.interview.qa);
                // Scroll back to the top of the page
                const element = document.getElementById('main-focusable');
                if (element) {
                    window.scroll({
                        top: element.getBoundingClientRect().top + window.scrollY - 200,
                        behavior: 'smooth'
                    });
                }
            }
        } catch (e) {
            LOG.error('Error evaluating override interview!')
        }
    }

    async save(): Promise<void> {
        if (this.loading) {
            LOG.debug('save', 'Interview is already saving, please wait...');
            return;
        }

        this.loading = true;
        try {
            this.gatherAnswersIntoQA();

            LOG.debug('save', 'Saving interview...');
            this.interview = await this.interviewService.save(this.interview.type, this.interview.id, this.interview.qa, this.interview.removed);
            this.qaChanges.next(this.interview.qa);

        } catch (e) {
            LOG.error('Error saving interview!');
        }
        this.loading = false;
    }

    async saveAndExit(): Promise<void> {
        // await this.save(); BEQ-981: Do not save changes on current topic - causes missing answers / answer values
        LOG.debug('saveAndExit', 'Interview saved, redirecting to vault...');
        this.goToWills();
    }

    async confirmSaveAndExit(): Promise<boolean> {
        // var title = this.messageService.getText('dialog.save-and-exit.title');
        // this.messageService.getText('dialog.save-and-exit.confirm');
        // this.messageService.getText('dialog.save-and-exit.dismiss');

        return this.confirmationPopup.open({
            // messageService.getText
            modalTitle: this.messageService.getText('dialog.save-and-exit.title'),
            confirmButtonLabel:  this.messageService.getText('dialog.save-and-exit.confirm'),
            hideDismissButton: () => false, dismissButtonLabel: this.messageService.getText('dialog.save-and-exit.dismiss')
        });
    }

    goToHome(): void {
        window.location.href = `${this.homeAppUri}`;
    }

    goToWills(): void {
        window.location.href = `${this.homeAppUri}/my-wishes`;
    }

    async goToSummary(): Promise<void> {
        this.interviewService.summaryState = {
            editing: false,
            enabled: true,
            editingTopic: null,
            editingSubTopic: null,
            activeTopics: this.interviewService.summaryState.activeTopics || []
        };
        this.router.navigate(['interview', this.type, this.interviewId, 'summary']);
    }

    doNothing(): void {
    }

    /** Trigger a custom event to change app branding */
    private updateBranding(large: string = "", small: string = ""): void {
        this.elementRef.nativeElement.dispatchEvent(
            new CustomEvent('UpdateBrandingEvent', {
                bubbles: true,
                detail: {large, small}
            })
        );
    }

}
