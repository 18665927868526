import { Component, Input } from '@angular/core';
import { MessageService } from "../../../../core/message/message.service";
import { QuestionSummary } from "../../../../models/interview.model";

/**
 * Displays questions as read-only on the summary page
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'summary-questions',
    templateUrl: './summary-questions.component.html',
    styleUrls: ['./summary-questions.component.sass']
})
export class SummaryQuestionsComponent {

    @Input() groupId: string;
    @Input() groupIndex: number;
    @Input() groupTitle: string;
    @Input() questions: QuestionSummary[];
    @Input() active: boolean;

    constructor(
        protected messageService: MessageService) {
    }

    get messages(): MessageService {
        return this.messageService;
    }

    /**
     * Helper method that allows the "standard" text for a question to be overridden by a summary version
     *
     * @param id
     * @param type
     * @param messageCode
     */
    getMessage(id: string, type: string = 'question', messageCode?: string): string {
        id = id.replace(' ', '').toLowerCase();
        // Check for summary specific first
        let message: string = this.messages.getText(`summary.${type}.${id}`);
        if (message) {
            return message;
        }
        // Then try using messageCode
        message = this.messages.getText(messageCode);
        if (message) {
            return message;
        }
        // Then try parsing messageCode using id
        message = this.messages.getText(`${type}.${id}`);

        return message;
    }

    getAnswer(question: QuestionSummary): string {
        if (!!question.options && Object.keys(question.options)?.length) {
            return (question.answer || '')
                .split('|')
                .map(answer => this.getMessage(question.id, 'option', question.options[answer]))
                .join('<br>');
        }
        return (question.answer || '').split('|').join('<br>');
    }

    getGroupTitle(): string {
        return this.groupTitle; // || this.messages.getText(`summary.group.${this.groupId}`, [this.questions[0].answer]);
    }
}
