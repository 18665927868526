<fieldset *ngIf="question.locked !== true" [attr.id]=" question.id ">
  <legend>
    <div [attr.aria-labelledby]=" question.id +'-err'"></div>
    <span *ngIf="!!question.messageCode">
      <p #label id="{{ question.id }}-label" class="field-label">{{ messages.getText(question.messageCode) }}</p>
    </span>
  </legend>
  <div *ngIf="!!question.noteMessageCode" [attr.aria-labelledby]=" question.id +'-note'"></div>
  <span *ngIf="!!question.noteMessageCode">
    <div id="{{ question.id }}-desc" class="field-description" [innerHTML]="messages.getText(question.noteMessageCode)"></div>
  </span>
  <div *ngIf="controlHasError()" class="alert-danger error pill form-error-list">
    <div id="{{ question.id }}-err" class="error-list-wrapper" [attr.aria-live]="'assertive'">
      <ul>
        <li *ngFor="let err of baseForm.errorMap.get(question.id)">
          <span>{{ determineErrorMessage(err.error, control, question) }}</span>
        </li>
      </ul>
    </div>
  </div>
  <!-- make sure aria attributes and other are configurable -->
  <div [ngClass]="inputClass">
    <div class="option" *ngFor="let option of question.optionsList; let i = index; let last = last" >

      <label for="{{ question.id }}-{{convertWhitespaces(option.value)}}">
        <span class="displayValue" >{{ messages.getText(option.messageCode) }}</span>
      </label>
      <!-- [note] we need to keep both attr.name and name to handle screen reading, tabing through groups and handling multiple groups -->
      <input #input type="radio"
             id="{{ question.id }}-{{convertWhitespaces(option.value)}}" [value]=" question.id  + '###' + option.value"
             [attr.name]=" question.id " name="{{ question.id }}"
             [required]="question.required" [attr.aria-required]="question.required"
             [attr.aria-invalid]="controlHasError()"
             [(formControl)]="control"
             (change)="revalidateInput()"
             (keydown.enter)="onEnter($event)"
             [checked]="control.value ===  question.id  + '###' + option.value">
      <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events --> <!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
      <span class="checkmark" (click)="selectOption(option.value)"></span>
      <div *ngIf="!!control.validator && !isHoriz() && last && control.touched && !control.pending" #feedback class="feedback-icon form-group radio" aria-hidden="true">
        <i class="fa" aria-hidden="true"
           [ngClass]="{ 'fa-check-circle' : !controlHasError() && (!question.required || !!getControlValue()), 'fa-times-circle' :  controlHasError() }"></i>
      </div>
    </div>
  </div>

</fieldset>
<div *ngIf="question.locked === true" class="locked" [ngClass]="inputClass">
  <span class="displayValue">{{this.question.options.get(getControlValue())}}</span>
  <input #input type="hidden" name="{{ question.id }}" id="{{ question.id }}-{{ getControlValue() }}" [value]=" question.id  + '-' + getControlValue()"
         class="form-control locked"
         [attr.aria-label]="getLabel()"
         [attr.disabled]="true">

</div>
<ng-content></ng-content>
