import { EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Answer } from '../../../../models/interview.model';
import { Question } from '../../../../models/navigator.model';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { PageUtil } from '../../../util/page.util';
import { BaseForm, ErrorInfo } from '../../base-form/base-form.component';

/**
 * Root of all custom form controls providing shared functionality
 *
 * @author Dan Bennett (dbennett)
 */
@Directive()
export abstract class BaseControl implements OnInit {

    protected LOG: LoggerService = LoggerService.get('BaseControl');

    // Form details
    @Input() baseForm: BaseForm;
    @Input() form: UntypedFormGroup;
    @Input() formName: string = 'interview';
    @Input() question: Question;
    @Input() onEnter: (event: any) => void;
    @Input() modelValue: any;
    @Output() modelValueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() preventSave: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

    control: AbstractControl;
    messages: MessageService;

    validators: ValidatorFn[] = [];
    previousAnswer: string | string[];
    previousError: string;

    constructor(messageService: MessageService) {
        this.messages = messageService;
    }

    ngOnInit(): void {
        if (!this.control) {
            this.determineValidators();
            this.previousAnswer = this.baseForm.getAnswer(this.question.id);
            this.control = new UntypedFormControl(
                (typeof this.previousAnswer === 'string')
                    ? this.previousAnswer : this.previousAnswer?.length
                        ? this.previousAnswer[0] : null,
                this.validators
            );
        }
        this.form.controls[this.question.id] = this.control;
        this.baseForm.questionControls.push(this);

        this.checkForErrorsOnEdit();
    }

    /**
     * Determines which validators to use by default
     */
    determineValidators(): void {
        if (this.question.required) {
            this.validators.push(Validators.required);
        }
        if (this.question.min) {
            this.validators.push(Validators.max(this.question.min));
        }
        if (this.question.max) {
            this.validators.push(Validators.max(this.question.max));
        }
    }

    /**
     * Performs a check to see if any errors are present on edit. If any errors exist then it forces a revalidation
     * so that the errors can be applied correctly.
     *
     * @protected
     */
    protected checkForErrorsOnEdit(): void {
        if (!this.baseForm.interview.errorsOnEdit) {
            return;
        }
        if (this.baseForm.interview.errorsOnEdit?.has(this.question.id)) {
            this.validate().then(() => this.focus());
            this.baseForm.interview.errorsOnEdit.delete(this.question.id);
            return;
        }
        for (const key in this.baseForm.interview.errorsOnEdit.keys()) {
            if (new RegExp(key).test(this.question.id)) {
                this.validate().then(() => this.focus());
                this.baseForm.interview.errorsOnEdit.delete(this.question.id);
                break;
            }
        }
    }

    controlHasError(): boolean {
        const controlErrors: any[] = this.baseForm.errorMap ? this.baseForm.errorMap.get(this.question.id) : null;
        const isInvalid: boolean =
            (this.control.touched && (this.control.invalid || (!!controlErrors)))
            || (this.baseForm.isSubmitted() && !this.control.touched);

        if (this.LOG.levelEnabled('TRACE') && isInvalid) {
            this.LOG.trace('controlHasError', `Question: [${this.question.id}] has error(s): ${JSON.stringify(controlErrors || this.control.errors)}`);
        }

        if (isInvalid) {
            this.baseForm.applyErrors(this.question.id, this.control.errors);
        }
        return isInvalid;
    }

    focus(): void {
        PageUtil.findNativeElement(this.question.id).focus();
    }

    async validate(): Promise<ErrorInfo[]> {
        if (this.control.disabled) {
            return [];
        }

        this.control.markAllAsTouched();
        this.control.updateValueAndValidity();

        if (!this.control.valid) {
            return this.convertToErrorInfo(this.control.errors);
        }

        if (!this.question.validators?.length) {
            return [];
        }

        const qa: any = {};
        // Clone QA for validation request
        this.baseForm.interview.qa.forEach((answer: Answer) => {
            if (!qa[answer.question]) {
                qa[answer.question] = [];
            }
            qa[answer.question].push(answer.answer);
        });
        // Add in answers from controls on current page
        this.baseForm.questionControls.forEach((cntrl: BaseControl) => {
            qa[cntrl.question.id] = [];

            const val: string | string[] = cntrl.getValue();
            if (val) {
                if (typeof val === 'string') {
                    qa[cntrl.question.id].push(val);
                } else {
                    val.forEach((v: string) => qa[cntrl.question.id].push(v));
                }

            }
        });

        const errors: any = await this.baseForm.rulesService.validate(this.question.validators, qa);
        if (errors) {
            errors.forEach((err: string[], key: string) => {
                if (new RegExp(key).test(this.question.id)) {
                    errors.set(this.question.id, err);
                }
            });
        }
        if (!errors || !errors.get(this.question.id)?.length) {
            this.control.setErrors(null);
            this.baseForm.errorMap.delete(this.question.id);
            return [];
        }

        const e: any = {};
        errors.get(this.question.id).forEach((err: string) => {
            e[err] = true;
        });

        this.control.setErrors(e);
        this.baseForm.errorMap.set(this.question.id, this.convertToErrorInfo(e));
        return this.baseForm.errorMap.get(this.question.id);
    }

    private convertToErrorInfo(errors: any): ErrorInfo[] {
        return Object
            .keys(errors || {})
            .filter((key: string) => errors[key])
            .map((key: string) => {
                if (this.LOG.levelEnabled('DEBUG')) {
                    this.LOG.debug('validate', `Control: [${this.question.id}] has errors: ${JSON.stringify(key)}`);
                }
                return {
                    field: this.question.id,
                    error: key
                } as ErrorInfo;
            });
    }

    abstract getValue(): string | string[];

    /**
     * Determines an appropriate autocomplete value for the given control name.
     *
     * @param controlName the name of the control to parse an autocomplete value for
     */
    determineAutocompleteValue(controlName: string): string {

        if (controlName.indexOf('address.line') > -1) {
            return `address-line${controlName.charAt(controlName.indexOf('address.line') + 'AddressLine'.length)}`;
        }

        if (controlName.indexOf('address.postcode') > -1) {
            return 'postal-code';
        }

        if (controlName.indexOf('address.city') > -1) {
            return 'address-level2';
        }

        if (controlName.indexOf('name') > -1) {
            return 'name';
        }

        if (controlName.indexOf('email') > -1) {
            return 'email';
        }
        return 'on';
    }

    determineErrorMessage(errorType: string, control: AbstractControl, question: Question): string {
        if (!control.invalid) {
            return this.previousError || null;
        }
        this.LOG.trace('determineErrorMessage', `Determining error message for ${errorType}`);
        const msg: string = this.messages.getText(`error.${errorType}.${question.id}`);

        return this.previousError = msg || this.messages.getText(`error.${errorType}.generic`);
    }

    doNothing(): void {
    }
}
