import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from '../../app-routing.module';
import { CoreModule } from '../../core/core.module';
import { InterviewService } from '../../core/services/interview.service';
import { UIModule } from '../../core/ui/ui.module';
import { AdviceService } from "../../core/services/advice.service";
import { DataCaptureComponent } from './data-capture/data-capture.component';
import { InterviewCannotCompleteComponent } from "./interview-cannot-complete/interview-cannot-complete.component";
import { InterviewCompleteComponent } from './interview-complete/interview-complete.component';
import { InterviewSummaryComponent } from './interview-summary/interview-summary.component';
import { NotHiddenPipe } from './interview-summary/summary-questions/not-hidden.pipe';
import { SummaryQuestionsComponent } from './interview-summary/summary-questions/summary-questions.component';
import { NavigatorComponent } from './navigator/navigator.component';

/**
 * Module for Interview related components
 */
@NgModule({
    declarations: [
        DataCaptureComponent,
        NavigatorComponent,
        SummaryQuestionsComponent,
        InterviewSummaryComponent,
        InterviewCompleteComponent,
        InterviewCannotCompleteComponent,
        NotHiddenPipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        // Shared application modules
        CoreModule,
        UIModule,
        NgbModule
    ],
    providers: [
        InterviewService,
        AdviceService
    ]
})
export class InterviewModule {
}
