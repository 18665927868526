import { UntypedFormGroup } from '@angular/forms';

/**
 * An option for an Advice Interview
 */
export class AdviceOption {

    order: number;
    id: string;
    name: string;
    risk: string;
    solution: string;
    price: number;
    rejectionOptions: Map<string, string>;

    constructor(adviceOption?: any) {
        if (adviceOption) {
            this.order = adviceOption.order;
            this.id = adviceOption.id;
            this.name = adviceOption.name;
            this.risk = adviceOption.risk;
            this.solution = adviceOption.solution;
            this.price = adviceOption.price;
            this.rejectionOptions = adviceOption.rejectionOptions;
        }
    }
}

/**
 * An answer for a given question, that is to be returned to backend service
 */
export class Answer {

    question: string;
    answer: string;
    groupId: string;
    groupIndex: number;

    constructor(answer?: any) {
        if (answer) {
            this.question = answer.question;
            if (answer.answer) {
                this.answer = answer.answer.trim().replace(/\n\r|\n|\r/g, ' ');
            }
            this.groupId = answer.groupId;
            this.groupIndex = answer.groupIndex;
        }
    }
}

/**
 * Contains response from the back-end that contains actual questions content for a single topic and sub-topic.
 * If there are any grouped questions, those can be referenced via groups field
 */
export class Interview {

    id: number;
    type: string;
    complete: boolean;

    qa: Answer[];
    removed: Answer[] = [];

    errorsOnEdit: Map<string, string[]>;
    charity: Charity;

    getValue(questionId: string): any {
        return this.findValueIn(questionId) || this.findValueIn(questionId, this.removed);
    }

    findValueIn(questionId: string, list: Answer[] = this.qa): string {
        for (const answer of list) {
            if (answer.question === questionId) {
                return answer.answer;
            }
        }
        return null;
    }

    findAnswers(questionId: string, list: Answer[] = this.qa): Answer[] {
        const answers: Answer[] = [];
        for (const answer of list) {
            if (answer.question === questionId) {
                answers.push(answer);
            }
        }
        return answers;
    }
}

export class Charity {

    nonProfitId: number;
    initiator: string;
    name: string;
    number: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    logoUrl: string;
    optOut: boolean;

    constructor(c: any) {
        if (!c) {
            return;
        }
        this.nonProfitId = c.nonprofitId;
        this.initiator = c.initiator;
        this.optOut = c.optOut;
        this.name = c.name;
        this.number = c.number;
        this.addressLine1 = c.addressLine1;
        this.addressLine2 = c.addressLine2;
        this.city = c.city;
        this.county = c.county;
        this.postcode = c.postcode;
        this.logoUrl = c.logoUrl;
    }
}

/**
 * Map configuring how certain 'type' and displayType from the backend response
 * is to be mapped to actual control (see interview.component.html how controls are selected)
 */
export const QuestionType = {
    CHECKBOX: { type: 'MULTIPLE', inputType: 'checkbox' },
    CHARITY: { type: 'CHARITY', inputType: 'text' },
    CHARITY_SEARCH: { type: 'CHARITY_SEARCH', inputType: 'text' },
    DATE: { type: 'QUESTION_DATE', inputType: 'text' },
    DROPDOWN_TEXT: { type: 'MULTIPLE', inputType: 'text' },
    DROPDOWN: { type: 'MULTIPLE', inputType: 'dropdown' },
    DROPDOWN_DISPLAY: { type: 'DISPLAY', inputType: 'dropdown' },
    HIDDEN: { type: 'HIDDEN', inputType: 'hidden' },
    NOTE: { type: 'DISPLAY', inputType: 'note' },
    POSTCODE: { type: 'QUESTION', inputType: 'postcode' },
    POSTCODE_DISPLAY: { type: 'DISPLAY', inputType: 'postcode' },
    RADIO: { type: 'MULTIPLE', inputType: 'radio' },
    TEXT: { type: 'QUESTION', inputType: 'text' },
    LARGE_TEXT: { type: 'QUESTION', inputType: 'text' },
    NUMBER: { type: 'QUESTION_NUMBER', inputType: 'text' },
    CURRENCY: { type: 'QUESTION', inputType: 'currency' },
    TEXT_DISPLAY: { type: 'DISPLAY', inputType: 'text' },
    UNKNOWN: { type: 'UNKNOWN', inputType: 'unknown' }
};

/**
 * Question body retrieved from the backed interview service.
 */
export class Question {
    topic: string;
    subTopic: string;
    exampleText: string;
    dataType: string;
    note: string;
    required = false;
    answer: string | string[];
    displayType: string;
    type: string;
    elementText: string;
    elementId: string;
    index = -1; // by default -1 when the questin is not repeating type
    filteredTopic: string;
    info = false;
    disclaimer = false;
    // array of other question being part of the same group
    questionFullQroup: Question[][];
    // true if question is answered and not editable
    locked = false;
    // question group name
    group: string;
    groupTitle: string;
    groupId: string;
    mode: string = null;

    static nextUID = 0;
    uid = Question.nextUID++;
    dynamic: boolean;
    allowMultipleAnswers = true;

    constructor(question: any) {
        this.topic = question.topic;
        this.subTopic = question.subTopic;
        this.exampleText = question.exampleText;
        this.dataType = question.dataType;
        this.note = question.note;
        this.required = question.required;
        this.answer = question.answer;
        this.type = question.type;
        this.displayType = question.displayType;
        this.elementText = question.elementText;
        this.elementId = question.elementId;
        this.filteredTopic = question.filteredTopic;
        this.info = question.info;
        this.disclaimer = question.disclaimer;
        this.dynamic = question.dynamic;

        if (question.index !== -1) {
            this.index = question.index;
        }
        if (question.locked) {
            this.locked = question.locked;
        }

        this.group = question.group;
        this.groupTitle = question.groupTitle;
        this.groupId = question.groupId;
    }

    isFirstInGroup(): boolean {
        return this.index >= 0 && !!this.questionFullQroup && this.questionFullQroup.length > 0 && !!this.questionQroup && this.questionQroup.length > 0 && this.questionQroup[0]?.elementId === this.elementId;
    }

    isLastInGroup(): boolean {
        return this.index >= 0 && !!this.questionFullQroup && this.questionFullQroup.length > 0 && !!this.questionQroup && this.questionQroup.length > 0 && (this.questionQroup.length - 1) === this['i'];
    }

    isFromLastGroup(): boolean {
        return this.index >= 0 && !!this.questionFullQroup && (this.questionFullQroup.length - 1) === this.index;
    }

    get questionQroup(): Question[] {
        return this.questionFullQroup[this.index];
    }

    get adding(): boolean {
        return this.mode === 'add';
    }

    get editting(): boolean {
        return this.mode === 'edit';
    }

    setMode(mode: string) {
        this.mode = mode;
    }

    updateQuestionWith(toCopy: Question) {
        this.type = toCopy.type;
        this.displayType = toCopy.displayType;
        this.dataType = toCopy.dataType;
        this.elementText = toCopy.elementText;
        this.group = toCopy.group;
        this.groupTitle = toCopy.groupTitle;
        this.topic = toCopy.topic;
        this.subTopic = toCopy.subTopic;
        this.info = toCopy.info;
        this.required = toCopy.required;
        this.note = toCopy.note;
        if (toCopy['choices']) {
            this['choices'] = (toCopy as ChoiceQuestion).choices;
            this['allowMultipleAnswers'] = (toCopy as ChoiceQuestion).allowMultipleAnswers;
        }
    }

    isRepeatinGroup(): boolean {
        return !!this.group && this.dynamic !== true;
    }

    getRepeatingQuestionName(): string {
        return this.elementId.split('__SL_Repeat_')[0];
    }
}

export class ChoiceQuestion extends Question {

    choices: Map<string, string | ComplexChoice>;
    type = 'RADIO';

    constructor(question: any) {
        super(question);
        if (question.allowMultipleAnswers !== undefined) {
            this.allowMultipleAnswers = question.allowMultipleAnswers;
        }

        if (question.type) {
            this.type = question.type;
        }

        if (question.choices) {
            this.choices = new Map<string, string | ComplexChoice>();
            // if this constructor is copying from ChoiceQuestion object already
            if (question.choices instanceof Map) {
                const choicesMap = question.choices as Map<string, string | ComplexChoice>;
                for (const choice of choicesMap.entries()) {
                    this.choices.set(choice[0], choice[1]);
                }
            } else {
                for (const choiceKey of Object.keys(question.choices)) {
                    if (typeof (question.choices[choiceKey]) === 'string') {
                        this.choices.set(choiceKey, question.choices[choiceKey]);
                    } else {

                        this.choices.set(choiceKey, new ComplexChoice(question.choices[choiceKey]));
                    }
                }
            }
        }
    }
}

export class ComplexChoice {

    title: string;
    description: string;
    logo: string;
    fullDescription: string;
    collapsed: boolean;

    constructor(option?: any) {
        if (option) {
            this.title = option.name;
            this.description = option.shortDescription;
            this.logo = option.imageUrl;
            this.fullDescription = option.description;
        }
        this.collapsed = true;
    }
}

export class QuestionSummary {

    id: string;
    groupId: string;
    groupIndex: number;
    type: string;
    order: number;
    messageCode: string;
    answer: string;
    options?: { [key: string]: string }

    constructor(q?: any) {
        if (q) {
            this.id = q.id;
            this.groupId = q.groupId;
            this.groupIndex = q.groupIndex;
            this.type = q.type;
            this.order = q.order;
            this.messageCode = q.messageCode;
            this.answer = q.answer;
            this.options = q.options;
        }
    }
}

export class QuestionSummaryGroup {

    id: string;
    index: number;
    title: string;
    questions: QuestionSummary[];

    constructor(groupId: string, groupIndex: number, groupTitle: string, questions: QuestionSummary[]) {
        this.id = groupId;
        this.index = groupIndex;
        this.title = groupTitle;
        this.questions = questions;
    }
}

export class TopicSummary {

    id: string;
    order: number;
    messageCode: string;
    questions: QuestionSummary[];
    groups: QuestionSummaryGroup[];
    active: boolean;

    subTopics: TopicSummary[];

    constructor(t?: any) {
        if (t) {
            this.id = t.id;
            this.order = t.order;
            this.messageCode = t.messageCode;
            this.active = t.active;
            if (t.questions?.length) {
                const groups: Map<string, Map<number, string>> = new Map<string, Map<number, string>>();
                this.questions = t.questions
                                  ?.filter(q => TopicSummary.checkForGroup(q, groups))
                                  ?.filter(q => q.order > -1)
                                  ?.map(q => new QuestionSummary(q))
                                  .sort(TopicSummary.sortByOrder);

                if (groups.size > 0) {
                    this.groups = this.addGroups(groups, t.questions);
                }
            }
            if (t.subtopics) {
                this.subTopics = [];
                Object.entries(t['subtopics'])
                      .forEach(([id, subTopic]) => {
                          if (subTopic['questions']?.length) {
                              const groups: Map<string, Map<number, string>> = new Map<string, Map<number, string>>();
                              const topicSummary: TopicSummary = {
                                  id: id,
                                  order: subTopic['order'],
                                  messageCode: subTopic['messageCode'],
                                  questions: subTopic['questions']
                                      ?.filter(q => TopicSummary.checkForGroup(q, groups))
                                      ?.filter(q => q.order > -1)
                                      ?.map(q => new QuestionSummary(q))
                                      .sort(TopicSummary.sortByOrder)
                              } as TopicSummary;

                              if (groups.size > 0) {
                                  topicSummary.groups = this.addGroups(groups, subTopic['questions']);
                              }

                              this.subTopics.push(topicSummary)
                          }
                      });
            }
        }
    }

    private static checkForGroup(q: any, groups: Map<string, Map<number, string>>): boolean {
        if (q.groupId) {
            if (!groups.has(q.groupId)) {
                groups.set(q.groupId, new Map<number, string>());
            }
            groups.get(q.groupId).set(q.groupIndex, q.groupTitle);
        }
        return !q.groupId;
    }

    private addGroups(groupMap: Map<string, Map<number, string>>, questions: QuestionSummary[]): QuestionSummaryGroup[] {
        const groups: QuestionSummaryGroup[] = [];
        groupMap.forEach((data, id) =>
            data.forEach((title, index) => {
                const group: QuestionSummaryGroup = new QuestionSummaryGroup(
                    id,
                    index,
                    title,
                    questions
                        .filter(q => q.groupId === id && q.groupIndex === index)
                        .filter(q => q.order > -1)
                        .map(q => new QuestionSummary(q))
                        .sort(TopicSummary.sortByOrder)
                );
                if (group.questions?.length) {
                    groups.push(group);
                }
            })
        );

        return groups.sort(TopicSummary.sortByIndex);
    }

    private static sortByIndex(a: QuestionSummaryGroup, b: QuestionSummaryGroup): number {
        return a.index < b.index ? a.index : b.index;
    }

    private static sortByOrder(a: QuestionSummary, b: QuestionSummary): number {
        return a.order < b.order ? a.order : b.order;
    }
}

export class Summary {

    topics: TopicSummary[];

    constructor(response?: any) {
        if (!response) {
            return;
        }

        this.topics = [];
        Object.entries(response.topics)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .forEach(([id, topic]) => {
              this.topics.push(new TopicSummary(topic));
          });
    }
}

/**
 * Single topic or sub-topic that contains questions.
 * It may also contain some topics, in that case those are sub-topics,
 *  wich will keep refernce to main topic via parent field
 */
export class Topic {
    static nextId: number = 0;
    id: number = Topic.nextId++;
    priority: string;
    topic: string;
    questions: Map<string, Question>;
    subTopics: Array<Topic>;
    collapsed: boolean = false;
    parent: Topic = null;
    form: UntypedFormGroup;
    loaded: boolean = false;

    constructor(topic: any) {
        this.priority = topic.priority;
        this.topic = topic.topic;
        this.priority = topic.priority;
    }

    isEmpty(): boolean {
        return !this.questions || !Array.from(this.questions.values()).find(q => !!q);
    }
}
