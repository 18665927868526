/**
 * Utility providing re-usable helper functions for manipulating the document and user journey
 *
 * @author Dan Bennett (dbennett)
 */
export class PageUtil {

    /**
     * Sets the focus on the given element if it exists
     *
     * @param name ID attribute of the element to find
     * @param delay time to wait for attempting to set focus, allows time for DOM to render fully. Default 50ms
     */
    static setFocus(name: string, delay: number = 50): void {
        setTimeout(
            () => {
                const element = PageUtil.findNativeElement(name);
                if (element) {
                    element.focus();
                }
            },
            delay
        );
    }

    /**
     * Finds a a native element by name
     *
     * @param name ID of the native element to search
     */
    static findNativeElement(name: string): HTMLElement {
        return document.getElementById(name);
    }
}
