import { Service } from "../../models/environment.model";
import { LoggerService } from '../logger/logger.service';

const LOG: LoggerService = LoggerService.get('UrlUtils');

/**
 * Utility to help parse URLs
 *
 * @author Dan Bennett (dbennett)
 */
export class UrlUtils {

    /**
     * Utility method to construct a url from its parts
     *
     * @param service
     * @param endpoint
     * @param params
     * @param pathVariables
     * @returns {any}
     */
    public static constructServiceUrl(service: Service, endpoint: string | null, pathVariables?: any, params?: any): string {
        if (!endpoint) {
            LOG.warn('No endpoint passed!');
            return '';
        }
        // ToDo :: Uncomment this later
        // let endpointUri: string = service.api.get(endpoint)!;
        let endpointUri: string = service.api[endpoint]!;
        if (!endpointUri) {
            LOG.warn(`No endpoint URI found for key: ${endpoint}`);
            endpointUri = endpoint;
        } else if (!endpointUri?.startsWith('/public') && !service.baseUrl?.endsWith('/public')
            && !endpointUri?.startsWith('/app') && !service.baseUrl?.endsWith('/app')) {
            endpointUri = `/app${endpointUri}`
        }
        endpointUri = this.applyPathVariables(endpointUri, pathVariables);
        endpointUri = this.addTimestamp(endpointUri);
        endpointUri = this.addParams(endpointUri, params);

        return service.baseUrl + endpointUri;
    }

    /**
     * Utility to parse path variables as part of the URL
     *
     * @param endpointUri
     * @param pathVariables
     * @returns {string}
     */
    public static applyPathVariables(endpointUri: string, pathVariables: any | undefined | null): string {
        if (!pathVariables) {
            return endpointUri;
        }
        let url: string = endpointUri;
        Object.keys(pathVariables).map((param: string) => {
            const value: any = pathVariables[param];
            if (LOG.levelEnabled('DEBUG')) {
                LOG.debug('applyParams', `Applying param: ${param} -> ${value}`)
            }
            url = url.replace('{' + param + '}', value);
        });
        return url;
    }

    /**
     * Utility method to construct a url from it's parts
     *
     * @param baseUrl service base URL
     * @param urlPostfix URL suffix
     * @param params to add
     * @param pathVariables to add (deprecated)
     * @returns constructed URL
     */
    static constructUrl(baseUrl: string, urlPostfix: string, params?: any, pathVariables?: any): string {
        let url = baseUrl + urlPostfix;

        url = this.parsePathVariables(url, pathVariables);

        url = this.addTimestamp(url);
        url = this.addParams(url, params);

        return url;
    }

    /**
     * Utility to parse path variables as part of the URL
     *
     * @param url to apply path variables to
     * @param pathVariables variables to parse
     * @returns string URL
     *
     * @deprecated use () => in environment.ts instead
     */
    static parsePathVariables(url: string, pathVariables?: any): string {
        if (pathVariables) {
            for (const pathVariable in pathVariables) {
                if (pathVariable) {
                    const pvToken = `{${pathVariable}}`;
                    const pvValue = pathVariables[pathVariable];

                    url = url.replace(pvToken, pvValue);
                }
            }
        }
        return url;
    }

    /**
     * Utility method to add a timestamp to a url
     *
     * @param url to add timestamp to
     * @returns url with timestamp applied
     */
    static addTimestamp(url: string): string {
        return this.addParam(url, 'ms', '' + Date.now());
    }

    /**
     * Utility method to add GET parameters to a url
     *
     * @param url to apply parameters to
     * @param params to apply
     * @returns URL with parameters applied
     */
    static addParams(url: string, params?: string[]): string {
        if (params) {
            for (const p in params) {
                url = this.addParam(url, p, params[p]);
            }
        }
        return url;
    }

    /**
     * Utility method to add a GET parameter to a url
     *
     * @param url to apply parameters to
     * @param key name to apply
     * @param value to apply
     * @returns URL with parameters applied
     */
    static addParam(url: string, key: string, value: string): string {
        let joinVar = '?';
        if (url.includes('?')) {
            joinVar = '&';
        }

        let v;
        if (typeof value === 'boolean' || !!value) {
            v = value;
        } else {
            v = '';
        }

        return url + joinVar + key + '=' + v;
    }

}
