import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MessageService } from 'src/app/core/message/message.service';
import { LoggerService } from '../../../logger/logger.service';
import { BaseForm } from '../../base-form/base-form.component';
import { BaseControl } from '../base-control/base-control.component';
import {Subject} from "rxjs/internal/Subject";
import {Answer} from "../../../../models/interview.model";

const LOG: LoggerService = LoggerService.get('BaseInputControl');

/**
 * Root of all custom form input controls providing shared functionality
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'base-input-control',
    templateUrl: './base-input-control.component.html',
    styleUrls: ['./base-input-control.component.sass', '../../base-form/base-form.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class BaseInputControl extends BaseControl implements OnInit {

    static nextUID = 0;
    uid = BaseInputControl.nextUID++;
    messages: MessageService;

    @Input() questionOverrides: Subject<Answer[]>;
    // keeps up-to-date input validation state
    @Input() baseForm: BaseForm;
    @Input() wrappingClass = 'form-group position-relative';
    @Input() inputClass = 'form-control';
    @Input() repeatingGroupItemHeader: string;
    @Input() onEnter: (event) => void;
    @Input() modelValue: any;
    @Output() modelValueChange = new EventEmitter<any>();

    inputType = 'text';

    constructor(messageService: MessageService) {
        super(messageService);
        this.LOG = LOG;
    }

    ngOnInit(): void {
        if (!this.form) {
            this.form = this.baseForm.form;
        }
        super.ngOnInit();

        this.control['ctrl'] = this;
    }

    /**
     * Shortcut for revalidateControl used in the template
     * @param form A formgroup instance representing main or sub-form on the current page
     * (base-form-component). By default it uses main FormGroup from the base-form instance
     */
    async revalidateInput(form: UntypedFormGroup = this.form): Promise<void> {
        this.baseForm.revalidateControl(this.question.id, form);
    }

    markAsTouched(): void {
        this.control.markAsTouched();
        LOG.debug('isTouched', `${this.control.touched}`);
    }

    /**
     * Tries to resolve a message for the label element for the input fields
     */
    getLabel(): string {
        return this.messages.getText(this.question.messageCode || `question.${this.question.id}`);
    }

    /**
     * Checks if an object is TemplateRef, a more complex object than just string to display for the input field
     * @param obj
     */
    isElementRef(obj: TemplateRef<any> | string): boolean {
        return obj instanceof TemplateRef;
    }

    setModelValue(val: any): void {
        this.control.setValue(val.target.value);
        this.modelValue = val.target.value;
        this.modelValueChange.emit(this.modelValue);
    }

    convertWhitespaces(text: string): string {
        return text.replace ? text.replace(/\s/g, '+').replace(/'/g, '*') : text;
    }

    getAriaLabels(additionalKey: string = null) {
        let labels: string = '';
        if (this.question.locked) {
            return null;
        }

        if (this.controlHasError()) {
            labels += `${this.question.id}-err `;
        }

        if (document.getElementById(this.repeatingGroupItemHeader)) {
            labels += `${this.repeatingGroupItemHeader} `;
        }

        if (this.question.noteMessageCode) {
            labels += `${this.question.id}-desc `;
        }

        if (additionalKey) {
            labels += `${this.question.id}-${this.convertWhitespaces(additionalKey)} `;
        }

        if (document.getElementById(`${this.question.id}-note`)) {
            labels += `${this.question.id}-note `;
        }

        return labels.length > 0 ? labels.trim() : null;
    }

    getValue(): string | string[] {
        return this.control.value;
    }
}


