import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Answer, Interview } from '../../../../models/interview.model';
import { CharityOption } from '../../../../models/navigator.model';
import { NavigatorComponent } from '../../../../features/interview/navigator/navigator.component';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { CheckboxControl } from '../checkbox-control/checkbox-control.component';

const LOG: LoggerService = LoggerService.get('CharityControl');

/**
 * Displays a charity with details
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'charity-control',
    templateUrl: './charity-control.component.html',
    styleUrls: [
        './charity-control.component.sass',
        '../checkbox-control/checkbox-control.component.sass',
        '../../base-form/base-form.component.sass',
        '../base-input-control/base-input-control.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class CharityControl extends CheckboxControl implements OnInit {

    @Input() navigator: NavigatorComponent;

    constructor(private messageService: MessageService) {
        super(messageService);
        this.LOG = LOG;
    }

    ngOnInit(): void {
        if (this.question.charityOptionType === 'INITIATOR_ONLY') {
            this.question.optionsList = [this.navigator.initiatorCharity];
        } else if (this.question.charityOptionType === 'INITIATOR_FIRST' && !!this.navigator.initiatorCharity) {
            this.question.optionsList = [this.navigator.initiatorCharity,
                ...this.navigator.partnerCharities.filter(c => c.charityId != this.navigator.initiatorCharity.charityId)];
        } else if (this.question.charityOptionType === 'PREFERRED_WITHOUT_INITIATOR' && !!this.navigator.initiatorCharity) {
            this.question.optionsList = this.navigator.partnerCharities.filter(c => c.charityId != this.navigator.initiatorCharity.charityId);
        } else {
            this.question.optionsList = this.navigator.partnerCharities;
        }
        super.ngOnInit();
    }

    applyValues(interview: Interview): void {
        for (let i = 0; i < interview.qa.length; i++) {
            // Check QA to remove any questions for the same group
            if (new RegExp(`${this.question.targetGroup}\\[[0-9]+]\\.[a-zA-Z0-9._]*`).test(interview.qa[i].question)) {
                interview.qa.splice(i, 1);
                i--;
            }
            // Check QA to remove any duplicate answers for this question
            if (interview.qa[i].question === this.question.id) {
                interview.qa.splice(i, 1);
                i--;
            }
        }

        // Add a record for each name but also pre-emptively within the group
        const selectedCharities: string[] = this.getValue();
        for (let i = 0; i < selectedCharities.length; i++) {
            interview.qa.push(new Answer({ question: this.question.id, answer: selectedCharities[i] }));
            interview.qa.push(new Answer({ question: `${this.question.targetGroup}[${i}].${this.question.targetName}`, answer: selectedCharities[i] }));
        }
    }

    options(): CharityOption[] {
        return this.question.optionsList.map(o => o.asCharityOption());
    }
}

