import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { BaseSelectionControl } from '../base-selection-control/base-selection-control.component';

const LOG: LoggerService = LoggerService.get('RadioButtonControl');

/**
 * Control to display radio buttons
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'radio-button-control',
    templateUrl: './radio-button-control.component.html',
    styleUrls: [
        './radio-button-control.component.sass',
        '../../base-form/base-form.component.sass',
        '../base-input-control/base-input-control.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class RadioButtonControl extends BaseSelectionControl implements OnInit {

    constructor(messageService: MessageService) {
        super(messageService);

        this.inputClass = '';
        this.LOG = LOG;
    }

    ngOnInit(): void {
        if (this.question.required) {
            // this.validators.push(Validators.requiredTrue);
        }
        super.ngOnInit();
        // try to convert plain value to unique so that radio buttons works
        if (!!this.control.value && !(this.control.value as string).startsWith(this.question.id)) {
            this.control.patchValue(`${this.question.id}###${this.control.value}`);
            this.control.updateValueAndValidity();
        }
    }

    isHoriz(): boolean {
        return this.inputClass === 'options-wrapper-horizontal';
    }

    getControlValue(): string {
        return !!this.control && !!this.control.value ? this.control.value.split('###')[1] : null;
    }

    selectOption(key: string): void {
        this.control.setValue(`${this.question.id}###${key}`);
        this.control.updateValueAndValidity();
    }

    getValue(): string | string[] {
        if (!this.control) {
            return null;
        }

        let val: string = this.control.value;
        if (!!this.control.value && this.control.value.indexOf(this.question.id) > -1) {
            val = this.control.value.substring(this.question.id.length + 3);
        }
        return val;
    }

}
