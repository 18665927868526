import { Question, SubTopic, Topic } from '../../models/navigator.model';

/**
 * Provides utilities for manipulating the QA
 *
 * @author Dan Bennett (dbennett)
 */
export class QAUtils {

    static hasErroredQuestion(topic: Topic | SubTopic, errors: Map<string, string[]>): boolean {
        return QAUtils.getErroredQuestion(topic, errors)?.length > 0;
    }

    static getErroredQuestion(topic: Topic | SubTopic, errors: Map<string, string[]>): Question[] {
        if (!topic.hasQuestions()) {
            return null;
        }
        const erroredQuestions: Question[] = [];
        topic.questions
            .filter((question: Question) => {
                if (errors.has(question.id)) {
                    return true;
                }
                for (const key in errors.keys()) {
                    if (new RegExp(key).test(question.id)) {
                        return true;
                    }
                }
                return false;
            })
            .forEach((question: Question) => erroredQuestions.push(question));
        return erroredQuestions;
    }
}
