import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { BaseControl } from '../base-control/base-control.component';

const LOG: LoggerService = LoggerService.get('NoteControl');

/**
 * Interview form control to display a note in HTML format
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'note-control',
    templateUrl: './note-control.component.html',
    styleUrls: [
        './note-control.component.sass',
        '../base-input-control/base-input-control.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class NoteControl extends BaseControl implements OnInit {

    constructor(private messageService: MessageService) {
        super(messageService);
        this.LOG = LOG;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    getValue(): string | string[] {
        return '';
    }
}
