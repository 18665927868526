import { Component, Injectable, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-popup',
    templateUrl: './confirm-popup.component.html',
    styleUrls: ['./confirm-popup.component.sass'],
    encapsulation: ViewEncapsulation.None
})
@Injectable()
export class ConfirmPopupComponent {

    @Input() public modalConfig: ModalConfig;
    @ViewChild('modal') private modalContent: TemplateRef<ConfirmPopupComponent>;
    @Output() confirmAction = new EventEmitter<any>();
    private modalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    async open(config: ModalConfig): Promise<boolean> {
        if (config) {
            this.modalConfig = config;
        }

        return new Promise<boolean>(resolve => {
            this.modalRef = this.modalService.open(this.modalContent, { centered: true, windowClass: 'popup-window' });
            this.modalRef.result.then(resolve, resolve);
        });
    }

    async confirm(): Promise<void> {
        if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
            this.modalConfig.onClose === undefined || (await this.modalConfig.onClose());
            this.modalRef.close(true);
            this.confirmAction.emit();
        }
    }

    async dismiss(): Promise<void> {
        if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
            this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss());
            this.modalRef.dismiss(false);
        }
    }
}

export interface ModalConfig {
    modalTitle?: string;
    dismissButtonLabel?: string;
    confirmButtonLabel?: string;

    shouldClose?(): Promise<boolean> | boolean;

    shouldDismiss?(): Promise<boolean> | boolean;

    onClose?(): Promise<boolean> | boolean;

    onDismiss?(): Promise<boolean> | boolean;

    disableCloseButton?(): boolean;

    disableDismissButton?(): boolean;

    hideCloseButton?(): boolean;

    hideDismissButton?(): boolean;
}
