import { HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs/internal/observable/of';
import { APPLICATION_CONSTANTS } from "../../models/application.constants";
import { Service } from "../../models/environment.model";
import { WebServiceError } from "../../models/http.model";
import { LoggerService } from '../logger/logger.service';

const LOG: LoggerService = LoggerService.get('HttpUtils');

/**
 * Utility class to provide functionality when submitting HTTP requests
 *
 * @author Dan Bennett
 */
export class HttpUtils {

    /**
     * Create the JWT token header
     *
     * @param headers
     * @param token
     */
    public static createTokenHeader(service: Service, headers: any, token: string, isAnonymous: boolean = false): any {
        headers[isAnonymous === true ? service.tokenHeader : service.tokenHeader] = 'Bearer ' + token;
        headers[isAnonymous === true ? APPLICATION_CONSTANTS.anonymousAuthToken : APPLICATION_CONSTANTS.authToken] = token;
        return headers;
    }

    public static createTypeHeader(headers: any, formPost: boolean = false): HttpHeaders {
        headers['Content-Type'] = formPost ? 'application/x-www-form-urlencoded' : 'application/json';
        return headers;
    }

    /**
     * Utility method to handle a HTTP error
     *
     * @param url
     * @param response
     * @returns {any}
     */
    public static handleError(url: string, response: any): any {
        LOG.error(`Error calling url [${url}]! Returned response status: [${response.status}] and error message: [${response.error}]!`);

        // Show detail depending on type of error
        if (response.status > 200 && response.status < 300) {
            return of({ errored: false } as WebServiceError);
        }

        let type = 'error';
        switch (response.status) {
            case 400:
                type = response.type;
                LOG.error(`${url} : Threw an application error! Received status:  ${response.status}, message: ${type}`);
                break;
            case 401:
                type = 'notAuthorized';
                LOG.error(`${url} : You are not authorized! Received status:  ${response.status}, message: ${response.error}`);
                break;
            case 403:
                type = 'forbidden';
                LOG.error(`${url} : This resource is forbidden! Received status:  ${response.status}, message: ${response.error}`);
                break;
            case 404:
                type = 'serverDown';
                LOG.error(`${url} : Server is down! Received status:  ${response.status}, message: ${response.error}`);
                break;
            case 500:
                type = 'internalServer';
                LOG.error(`${url} : Internal Server error on server! Received status:  ${response.status}, message: ${response.error}`);
        }

        return of({ errored: true, status: response.status, type: type } as WebServiceError);
    }
}
