import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ENV, Service, ServiceType } from "../../models/environment.model";
import { WebServiceError } from "../../models/http.model";
import { AnonymousTokenService } from '../authentication/anonymous/anonymous-token.service';
import { TokenService } from '../authentication/token.service';
import { CoreModule } from '../core.module';
import { LoggerService } from '../logger/logger.service';
import { UrlUtils } from '../util/url.utils';
import { HttpUtils } from './http.utils';

const LOG: LoggerService = LoggerService.get('HttpService');

/**
 * Http service to decorate all requests with JWT
 *
 * @author Dan Bennett
 */
@Injectable({
    providedIn: CoreModule
})
export class HttpService {

    constructor(private tokenService: TokenService,
        private anonymousTokenService: AnonymousTokenService
    ) {
    }

    private getToken() {
        let service: string = this.tokenService.get();
        if (this.tokenService.isExpired()) {
            service = this.anonymousTokenService.get();
        }
        return service;
    }

    private isPublicUri(uri: string): boolean {
        return [
            '/login',
            '/register',
            '/reset-password',
            '/forgotten-password',
            '/resend-activation'
        ].reduce((acc: boolean, value: string) => value === uri || acc, false);
    }

    public async handleError(url: string, response: any): Promise<any> {
        LOG.error(`Error calling url [${url}]! Returned response status: [${response.status}] and error message: [${response.error}]!`);

        // Show detail depending on type of error
        if (response.status > 200 && response.status < 300) {
            return of(new WebServiceError(false));
        }

        let type = 'error';
        switch (response.status) {
            case 400:
                type = response.error.error || response.error;
                LOG.error(`${url} : Threw an application error! Received status:  ${response.status}, message: ${response.error}`);
                break;
            case 401:
                type = 'notAuthorized';
                LOG.error(`${url} : You are not authorized! Received status:  ${response.status}, message: ${response.error}`);
                break;
            case 403:
                type = 'forbidden';
                LOG.error(`${url} : This resource is forbidden! Received status:  ${response.status}, message: ${response.error}`);
                break;
            case 404:
                type = 'serverDown';
                LOG.error(`${url} : Server is down! Received status:  ${response.status}, message: ${response.error}`);
                break;
            case 500:
                type = 'internalServer';
                LOG.error(`${url} : Internal Server error on server! Received status:  ${response.status}, message: ${response.error}`);
        }
        return new WebServiceError(true, response.status, type, response.error.body);
    }

    private async createHeaders(service: Service, includeAuth: boolean = true, formPost: boolean = false): Promise<any> {
        let headers = {};
        headers = HttpUtils.createTypeHeader(headers, formPost);

        if (includeAuth) {
            headers = HttpUtils.createTokenHeader(service, headers, this.getToken());
        }
        return headers;
    }

    /**
     * GET request wrapper
     *
     * @param service
     * @param endpoint
     * @param pathVariables
     * @param params
     * @param includeAuth
     * @param withCredentials
     */
    async get(service: Service | ServiceType, endpoint: string, pathVariables?: any, params?: any, includeAuth: boolean = true, withCredentials: boolean = false): Promise<any> {
        return this.request('GET', service, endpoint, null, pathVariables, params, includeAuth, withCredentials);
    }

    /**
     * POST request wrapper
     *
     * @param service
     * @param endpoint
     * @param payload
     * @param pathVariables
     * @param params
     * @param includeAuth
     * @param withCredentials
     */
    async post(service: Service | ServiceType, endpoint: string, payload: any, pathVariables?: any, params?: any, includeAuth: boolean = true, withCredentials: boolean = false): Promise<any> {
        return this.request('POST', service, endpoint, payload, pathVariables, params, includeAuth, withCredentials);
    }

    /**
     * PUT request wrapper
     *
     * @param service
     * @param endpoint
     * @param payload
     * @param pathVariables
     * @param params
     * @param includeAuth
     * @param withCredentials
     */
    async put(service: Service | ServiceType, endpoint: string, payload: any, pathVariables?: any, params?: any, includeAuth: boolean = true, withCredentials: boolean = false): Promise<any> {
        return this.request('PUT', service, endpoint, payload, pathVariables, params, includeAuth, withCredentials);
    }

    /**
     * DELETE request wrapper
     *
     * @param service
     * @param endpoint
     * @param pathVariables
     * @param params
     * @param includeAuth
     * @param withCredentials
     */
    async delete(service: Service | ServiceType, endpoint: string, pathVariables?: any, params?: any, includeAuth: boolean = true, withCredentials: boolean = false): Promise<any> {
        return this.request('DELETE', service, endpoint, null, pathVariables, params, includeAuth, withCredentials);
    }

    private async request(type: string, service: Service | ServiceType, endpoint: string, payload?: any, pathVariables?: any, params?: any, includeAuth: boolean = true, withCredentials: boolean = false): Promise<any> {
        if (typeof service === 'string') {
            service = ENV.services.get(service) as Service;
        }
        const headers: any = await this.createHeaders(service, includeAuth);

        endpoint = UrlUtils.constructServiceUrl(service, endpoint, pathVariables, params);

        LOG.trace(type, `URL: [${endpoint}]`);
        LOG.trace(type, `Headers: [${JSON.stringify(headers)}]`);
        const request: any = {
            method: type,
            mode: 'cors',
            headers: headers
        };
        if (withCredentials) {
            request.withCredentials = withCredentials;
        }
        if (payload) {
            request.body = JSON.stringify(payload);
        }

        const response: Response = await fetch(endpoint, request);

        const body: any = await response.json();
        if (response.ok && response.status >= 200 && response.status < 300) {
            if (['GET', 'PUT', 'POST'].reduce((acc: boolean, val: string) => type == val || acc, false) && !!body) {
                LOG.trace(type, `Response: [${!body ? 'null' : JSON.stringify(body)}]`);
                // Incase it's nested
                // eslint-disable-next-line no-prototype-builtins
                return body.hasOwnProperty('body') ? body.body : body;
            }
            return null;
        }
        return this.handleError(endpoint, body);
    }
}
