<div id="display-charity-{{ charity.nonProfitId }}" class="row">
  <div class="col-sm-12 col-md-4 align-self-center text-center">
    <img class="charity-logo" [src]="charity.logoUrl" [alt]="charity.name"/>
  </div>
  <div class="col-sm-12 col-md-4 align-self-center text-center">
    <span id="charity-name-{{ charity.nonProfitId }}" class="charity-name">{{ charity.name }}</span>
  </div>
  <div class="col-sm-12 col-md-4 align-self-center text-center" id="donate-button-{{ charity.nonProfitId }}">
    <button type="button" class="btn btn-primary rounded-pill btn-lg"
            [attr.aria-label]="messages.getTextInterpolate('btn.donate-to', [charity.name])"
            (click)="confirmDonation()">{{ messages.getText('btn.donate') }}
    </button>
  </div>
</div>
