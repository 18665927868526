<div #card class="card-header">
  <h3>
    <span tabindex="0">{{messages.getText(config.name)}}</span>
    <div *ngIf="recommendation.status !== null && recommendation.status !== 'NOT_ANSWERED'" class="status-icon" aria-hidden="true">
      <i class="fa" aria-hidden="true"
         [ngClass]="{ 'fa-check-circle' : recommendation.status === 'ACCEPTED', 'fa-times-circle': this.recommendation.status === 'REJECTED' }"></i>
    </div>
  </h3>
</div>

<div class="card-body field-description" tabindex="0">
  <div [innerHTML]="messages.getText(config.risk)"></div>
  <div class="mt-3" [innerHTML]="messages.getTextInterpolate(config.solution, [config.price])"></div>
  <div class="alert-danger error pill form-error-list" *ngIf="flagAsInvalid">
    <div class="error-list-wrapper" id="err" aria-live="assertive">
      <ul><li><span>{{ messages.getText('error.recommendations.answer', 'Please select an answer') }}</span></li></ul>
    </div>
  </div>
</div>

<div class="card-footer text-right" [class.rejection]="showRejectionReasons()" [class.invalid]="flagAsInvalid">
  <div class="float-right">
    <div class="row">
      <div ngbDropdown placement="top-end" class="dropdown">
        <button class="btn btn-secondary dropdown-toggle rounded-pill" [class.rejected]="recommendation.status === 'REJECTED'" [class.accepted]="recommendation.status === 'ACCEPTED'"
                type="button"
                id="decision.{{ config.id }}"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ngbDropdownToggle>
          {{ messages.getText('label.status.' + recommendation.status, '--please select --') }}<ng-container *ngIf="recommendation.status === 'REJECTED'">, {{ messages.getText(config.rejectionOptions[recommendation.rejectionCode]).toLowerCase() }}</ng-container>
        </button>
        <div ngbDropdownMenu [attr.aria-labelledby]="'decision.' + config.id" [class.rejected]="recommendation.status === 'REJECTED'">
          <button ngbDropdownItem (click)="updateStatus('ACCEPTED')">{{ messages.getText('btn.accept') }}</button>
          <button ngbDropdownItem
                  *ngFor="let option of config.rejectionOptions | keyvalue; let i = index; let last = last"
                  (click)="updateStatus('REJECTED', option.key)">
            {{ messages.getText('btn.reject') }}, {{ messages.getText(option.value).toLowerCase() }}
          </button>
        </div>
        <app-confirm-popup #confirmPopup (onActionConfirm)="scroll(card)">
          <div [innerHTML]="messages.getText('dialog.recommendations.popup-information')"></div>
        </app-confirm-popup>
      </div>
    </div>
    <div *ngIf="rejectionCodeIsOther()" class="row">
      <textarea #rejectionText class="form-control form-text rejection-text d-inline-block" [(ngModel)]="recommendation.rejectionText"></textarea>
    </div>
  </div>
</div>

