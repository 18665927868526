import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs/internal/Observable';
import { AppService } from '../app.service';
import { LoggerService } from './logger/logger.service';

const LOG: LoggerService = LoggerService.get('AppLoadedGuard');

/**
 * Guard to ensure that the application has loaded some required data before proceeding
 *
 * @author Dan Bennett (dbennett)
 * @author Paul Thorp (pthorp)
 */
@Injectable()
export class AppLoadedGuard  {

    constructor(private appService: AppService) {
    }

    /**
     * Check if the application is loaded before continuing
     *
     * @returns boolean indicating that the application is loaded
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        LOG.trace('canActivate', `URL? [${state.url}]`);

        const loaded: boolean = this.appService.isLoaded();
        if (!loaded) {
            // Try and re-authenticate
            this.appService.initialiseApp();
            return this.appService.appLoaded;
        }
        return loaded;
    }
}
