import { Injectable } from '@angular/core';
import { ServiceType } from "../../models/environment.model";
import { BroadcastMessage, BroadcastService } from "../broadcast/broadcast.service";
import { CoreModule } from "../core.module";
import { HttpService } from "../http/http.service";
import { LoggerService } from "../logger/logger.service";
import { StringUtils } from '../util/string.utils';

const LOG: LoggerService = LoggerService.get('MessageService');

/**
 * Message service to return a message from a message bundle
 *
 * @author Dan Bennett
 */
@Injectable({
    providedIn: CoreModule
})
export class MessageService {

    private static messageBundle: any = {};

    constructor(
        private http: HttpService,
        private broadcastService: BroadcastService) {}

    /**
     * Retrieve the message bundle from the server by locale
     * - Currently just uses the local file
     */
    async retrieveMessageBundle(): Promise<boolean> {

        if (Object.keys(MessageService.messageBundle).length) {
            return true;
        }

        try {
            const messageBundle: any = await this.http.get(ServiceType.RESOURCE, 'get-messages', null, null, false);
            if (messageBundle.errored) {
                this.broadcastService.broadcast(new BroadcastMessage('ERROR_LOADING_MESSAGES').error());
                return true;
            }
            MessageService.messageBundle = messageBundle;

            return true;
        } catch (err) {
            LOG.error(`Error loading message bundle! ${err}`);
        }
        return false;
    }

    /**
     * Return message from bundle by id
     *
     * @param id
     * @param args - optional arguments to be inserted within the message
     * @returns {any}
     */
    getText(id: string, defaultStrOrArgs?: string[] | string, args?: string[]): string {
        const msgs: any = MessageService.messageBundle;

        const msgArgs: string[] = (typeof defaultStrOrArgs === 'string') ? args : defaultStrOrArgs;
        let value: string = msgs[id];
        if (!value && typeof defaultStrOrArgs === 'string') {
            return defaultStrOrArgs;
        }

        if (!!msgArgs && !!value) {
            value = msgArgs.reduce((acc: string, val: string, index: number) => acc.split(`{${index}}`).join(val), value);
        }
        return value || '';
    }

    getTextInterpolate(msgId: string, vars: any) {
        const template = this.getText(msgId);
        return StringUtils.interpolateVariables(template, vars);
    }

}
