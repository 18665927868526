import { Component, Input } from '@angular/core';
import { MessageService } from "../../message/message.service";

/**
 * Helper component to display errors correctly on the form
 *
 * @author Dan Bennett
 */
@Component({
    selector: 'form-control-info',
    templateUrl: './form-control-info.component.html',
    styleUrls: ['./form-control-info.component.sass']
})
export class FormControlInfoComponent {

    @Input() show: boolean;
    @Input() id: string;
    @Input() items: InfoMessage[];

    constructor(private messageService: MessageService) {
    }

    get messages(): MessageService {
        return this.messageService;
    }

    get errors(): InfoMessage[] {
        return this.items.filter((item: InfoMessage) => (!item.type || item.type === 'error') && item.match());
    }

    get success(): InfoMessage[] {
        return this.items.filter((item: InfoMessage) => item.type === 'success' && item.match());
    }
}

/**
 * Definition class of an information message to be displayed to the user
 *
 * @author Dan Bennett
 */
export declare class InfoMessage {
    code: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    match: Function;
    type?: string;
}
