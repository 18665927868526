/**
 * An option for an Advice
 */
export class Advice {

    id: number;
    recommendations: Recommendation[];

    constructor(advice?: any) {
        if (advice) {
            this.id = advice.adviceId;
            this.recommendations = advice.recommendations;
        }
    }
}

/**
 * An option for an Advice Recommendation
 */
export class Recommendation {

    id: number;
    name: string;
    status: string;
    rejectionCode: string;
    rejectionText: string;

    constructor(recommendation?: any) {
        if (recommendation) {
            this.id = recommendation.id;
            this.name = recommendation.name;
            this.status = recommendation.status;
            this.rejectionCode = recommendation.rejectionCode;
            this.rejectionText = recommendation.rejectionText;
        }
    }
}
