import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { MessageService } from '../../../core/message/message.service';
import { BaseForm } from '../../../core/ui/base-form/base-form.component';
import { RulesService } from '../../../core/services/rules.service';
import { InterviewService } from '../../../core/services/interview.service';

const LOG: LoggerService = new LoggerService('InterviewCompleteComponent');

@Component({
    selector: 'app-interview-complete',
    templateUrl: './interview-complete.component.html',
    styleUrls: ['./interview-complete.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class InterviewCompleteComponent extends BaseForm implements OnInit {

    type: string;
    interviewId: number;

    constructor(
        private title: Title,
        protected changeDetector: ChangeDetectorRef,
        protected interviewService: InterviewService,
        protected messageService: MessageService,
        protected cookieService: CookieService,
        protected authenticationService: AuthenticationService,
        public rulesService: RulesService,
        private route: ActivatedRoute
    ) {
        super(messageService, changeDetector, cookieService, authenticationService, rulesService);
        this.title.setTitle('Will Interview : Complete');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.route.params.subscribe((params: { id: number; type: string; action: string }) => {
            this.type = params.type;
            this.interviewId = params.id;

            this.listOfWills().then(() => LOG.debug('init', 'Redirecting to my wishes...'));
        });
    }

    async listOfWills(): Promise<void> {

        // FixMe :: Check if is-complete first (check sharepoint locations)
        await this.interviewService.completeProcess('WILL', this.interviewId);
        window.location.href = `${this.homeAppUri}/my-wishes`;
    }
}
