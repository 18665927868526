/**
 * String utility class
 *
 * @author Dan Bennett
 */
export class StringUtils {

    static interpolateVariables(template: string, variables?: any): string {
        if (variables) {
            for (const variable in variables) {
                if (variable) {
                    const pvToken = '{' + variable + '}';
                    const pvValue = variables[variable];
                    if (pvValue) {
                        template = template.replace(pvToken, pvValue);
                    }
                }
            }
        }
        return template;
    }

    /**
     * Private function to check for a value in a string
     *
     * @param value - value to validate
     */
    static isNotEmpty(value: any): boolean {
        return (value != null && (value !== '') && value.length > 0);
    }

    /**
     * Strips leading and trailing spaces from the given input string.
     *
     * @param value, the string to be striped of leading and trailing spaces.
     */
    static trimWhitespaces(value: string): string {
        if (value) {
            return value.replace(/\s+$/g, '').replace(/^\s+/g, '');
        }

        return value;
    }

    /**
     * Trims the ending from a given string
     *
     * @param str string to check
     * @param tokens tokens to check for
     */
    static trimEnd(str: string, ...tokens: string[]): string {
        const token = !tokens ? [] : tokens.filter((t: string) => str.endsWith(t));
        if (token.length > 0) {
            str = str.substring(0, str.indexOf(token[0]));
        }
        return str;
    }
}
