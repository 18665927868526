import { Injectable } from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';
import EvaluateJson from 'src/assets/evaluate.json';
import TopicsJson from 'src/assets/topics.json';
import StartJson from 'src/assets/start.json';

import EvaluateJsonGifts from 'src/assets/evaluateGifts.json';
import StartJsonGifts from 'src/assets/startGifts.json';

import EvaluateJsonCash from 'src/assets/evaluateCash.json';
import StartJsonCash from 'src/assets/startCash.json';
import { ENV, ServiceType } from "../models/environment.model";

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    static i = 0;
    static topicsResponse = TopicsJson;
    static startResponse = StartJson;
    static evaluateResponses = EvaluateJson;

    static startGiftsResponse = StartJsonGifts;
    static evaluateGiftsResponses = EvaluateJsonGifts;

    static startCashResponse = StartJsonCash;
    static evaluateCashResponses = EvaluateJsonCash;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const {url, method} = request;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(delay(200))
            .pipe(dematerialize());

        function handleRoute() {
            switch (true) {
                case url.indexOf(ENV.services.get(ServiceType.INTERVIEW).api.start('MOCK')) > 0 && method === 'GET':
                    return startInterview(FakeBackendInterceptor.startResponse);
                case url.indexOf(ENV.services.get(ServiceType.INTERVIEW).api.start('MOCK_GIFTS')) > 0 && method === 'GET':
                    return startInterview(FakeBackendInterceptor.startGiftsResponse);
                case url.indexOf(ENV.services.get(ServiceType.INTERVIEW).api.start('MOCK_CASH')) > 0 && method === 'GET':
                    return startInterview(FakeBackendInterceptor.startCashResponse);

                case url.indexOf(ENV.services.get(ServiceType.INTERVIEW).api.topics('MOCK_GIFTS')) > 0 && method === 'GET':
                    return topics();
                case url.indexOf(ENV.services.get(ServiceType.INTERVIEW).api.topics('MOCK_CASH')) > 0 && method === 'GET':
                    return topics();
                case url.indexOf(ENV.services.get(ServiceType.INTERVIEW).api.topics('MOCK')) > 0 && method === 'GET':
                    return topics();

                case url.indexOf(ENV.services.get(ServiceType.INTERVIEW).api.evaluate('MOCK', 1990)) > 0 && method === 'POST':
                    console.log('faking evaluate');
                     if(FakeBackendInterceptor.i >= FakeBackendInterceptor.evaluateResponses.length){
                         console.log('faking evaluate 2');
                         return next.handle(request);
                     }else {
                         console.log(FakeBackendInterceptor.i);
                         return evaluate(FakeBackendInterceptor.i++, FakeBackendInterceptor.evaluateResponses);
                     }
                case url.indexOf(ENV.services.get(ServiceType.INTERVIEW).api.evaluate('MOCK_GIFTS', 2151)) > 0 && method === 'POST':
                     if(FakeBackendInterceptor.i >= FakeBackendInterceptor.evaluateGiftsResponses.length){
                         return next.handle(request);
                     }else {
                         console.log(FakeBackendInterceptor.i);
                         return evaluate(FakeBackendInterceptor.i++, FakeBackendInterceptor.evaluateGiftsResponses);
                     }
                case url.indexOf(ENV.services.get(ServiceType.INTERVIEW).api.evaluate('MOCK_CASH', 2165)) > 0 && method === 'POST':
                    if(FakeBackendInterceptor.i >= FakeBackendInterceptor.evaluateCashResponses.length){
                        return next.handle(request);
                    }else {
                        console.log(FakeBackendInterceptor.i);
                        return evaluate(FakeBackendInterceptor.i++, FakeBackendInterceptor.evaluateCashResponses);
                    }
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }
        }

        // helper functions

        function ok(body?) {
            return of(new HttpResponse({status: 200, body}))
        }

        function topics() {

            return ok(FakeBackendInterceptor.topicsResponse);
        }

        function evaluate(index:number, response: any) {
            return ok(response[index]);
        }

        function startInterview(response: any) {

            return ok(response);
        }
    }
}

export const fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
