import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ContentService } from "./core/services/content.service";
import { AuthenticationService } from './core/authentication/authentication.service';
import { LoggerService } from './core/logger/logger.service';
import { MessageService } from './core/message/message.service';
import { User } from './models/user.model';
import { ApplicationType, ENV } from "./models/environment.model";

declare let ga: any;

const LOG: LoggerService = LoggerService.get('AppComponent');

declare function initIntercom(appId: string);

/**
 * Main app component
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

    @ViewChild('main') main: ElementRef;

    authServiceSubscription: Subscription;
    routerSubscription: Subscription;
    currentUser: User;
    homeAppUri: string;
    joinWebappUri: string;

    messages: MessageService;
    showCookiesBanner: boolean = true;
    footer: string = '';
    menuCollapsed: boolean = true;

    protected logoSrc = "https://bequeathedassets.s3.eu-west-2.amazonaws.com/Images/bequeathed-images/logo_willforgood_inverted.png";
    protected branded = false;

    constructor(private router: Router,
                private cookieService: CookieService,
                private contentService: ContentService,
                private authenticationService: AuthenticationService,
                public title: Title,
                private location: Location,
                private messageService: MessageService) {
        this.authenticationService.getAnonymousToken().then(() => this.loadFooter().then(() => LOG.debug('init', 'Footer loaded!')));
    }

    get breadcrumbs(): any[] {
        const breadcrumbs: string[] = this.title.getTitle().split(':');

        return breadcrumbs.map((name: string, index: number) => {
            const breadcrumb: any = {
                name: name,
                url: '',
                active: false
            }

            if (index === (breadcrumbs.length - 1)) {
                breadcrumb.active = true;
            }

            return breadcrumb;
        });
    }

    ngOnInit(): void {
        this.messages = this.messageService;
        this.homeAppUri = ENV.applications.get(ApplicationType.HOME).baseUrl;
        this.joinWebappUri = ENV.applications.get(ApplicationType.JOIN).baseUrl;
        this.authServiceSubscription = AuthenticationService.authenticated
            .subscribe(
                (isAuthenticated: boolean) => {
                    if (isAuthenticated) {
                        this.currentUser = this.authenticationService.getUser();
                        this.authenticationService.updateIntercomWidget(this.currentUser.userId, this.currentUser.email);
                    } else {
                        this.currentUser = null;
                    }
                }
            );

        if (ga) {
            ga('create', ENV.config.get('gaAccountNo'), 'auto');

            this.routerSubscription = this.router.events.subscribe((event: any) => {

                if (event instanceof NavigationEnd) {
                    const userUID: string = this.cookieService.get('evident_uid');
                    ga('set', 'UID', userUID);
                    ga('set', 'page', event.urlAfterRedirects);
                    ga('send', 'pageview');
                }
            });
        }

        initIntercom(ENV.config.get('intercomAppID'));
    }

    ngOnDestroy(): void {
        this.authServiceSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
    }

    async loadFooter(): Promise<void> {
        try {
            let footerHtml: string = await this.contentService.loadFooter();
            footerHtml = footerHtml.replace(/(\s[ ]+)|\r\n|\n|\r/g, '');
            footerHtml = footerHtml.replace('<footer class="footer mt-auto"><div class="container">', '')
            footerHtml = footerHtml.replace('</div></footer>', '')
            this.footer = footerHtml;
        } catch (e) {
            LOG.error("Error loading footer!");
        }
    }

    logout(): void {
        this.authenticationService.logout();
        window.location.href = `${this.homeAppUri}/login`;
    }

    getCurrentPage(): string {
        return this.location.path();
    }

    areCookiesAccepted(): boolean {
        return !this.showCookiesBanner || this.cookieService.check('evident_accept_cookies');
    }

    acceptCookies(): void {
        this.cookieService.set('evident_accept_cookies', 'accepted', 365, '/', ENV.config.get('mainDomain'));
        this.showCookiesBanner = false;
    }

    closeCookieBanner(): void {
        this.showCookiesBanner = false;
    }

    /** Updates the branding when update event is received */
    @HostListener('UpdateBrandingEvent', ['$event'])
    onUpdateBranding(event: any) {
        this.logoSrc = event.detail.large;
        this.branded = true;
    }

}
