<ng-container *ngFor="let group of groups; let in = index">
  <div class="repeating-group-top" *ngIf="in !== 0"></div>
  <h3 id="group-heading-{{ group.index }}" tabindex="-1" *ngIf="dynamicGroup">{{ group.associatedAnswer }}</h3>
  <div class="position-relative" *ngFor="let question of group.questions; let i = index;">
    <!-- Show as a summary -->
    <display-control
      *ngIf="group.locked"
      [first]="i === 0"
      [group]="group"
      [question]="question"
      [answer]="baseForm.getAnswer(question.id)"></display-control>

    <ng-container *ngIf="!group.locked">
      <note-control
        *ngIf="question.type === 'NOTE'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [question]="question"
        [onEnter]="onEnter"></note-control>

      <number-control
        #questionControl
        *ngIf="question.type === 'NUMBER'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [question]="question"
        [onEnter]="onEnter"></number-control>

      <currency-control
        #questionControl
        *ngIf="question.type === 'CURRENCY'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [question]="question"
        [onEnter]="onEnter"></currency-control>

      <percentage-control
        #questionControl
        *ngIf="question.type === 'PERCENTAGE'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [attr.data-order]="question.order"
        [question]="question"
        [onEnter]="onEnter"></percentage-control>

      <text-control
        #questionControl
        *ngIf="question.type === 'TEXT'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [attr.data-order]="question.order"
        [question]="question"
        [onEnter]="onEnter"></text-control>

      <large-text-control
        #questionControl
        *ngIf="question.type === 'LARGE_TEXT'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [attr.data-order]="question.order"
        [question]="question"
        [onEnter]="onEnter"></large-text-control>

      <email-control
        #questionControl
        *ngIf="question.type === 'EMAIL'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [attr.data-order]="question.order"
        [question]="question"
        [onEnter]="onEnter"></email-control>

      <radio-button-control
        #questionControl
        *ngIf="question.type === 'RADIO'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [attr.data-order]="question.order"
        [question]="question"
        [onEnter]="onEnter"></radio-button-control>

      <checkbox-control
        #questionControl
        *ngIf="question.type === 'CHECKBOX'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [attr.data-order]="question.order"
        [question]="question"
        [onEnter]="onEnter"></checkbox-control>

      <dropdown-control
        #questionControl
        *ngIf="question.type === 'DROPDOWN'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [attr.data-order]="question.order"
        [question]="question"
        [onEnter]="onEnter"></dropdown-control>

      <date-control
        #questionControl
        *ngIf="question.type === 'DATE'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="groupName + '-' +question.id + '-wrapper'"
        [question]="question"
        [onEnter]="onEnter"></date-control>

      <postcode-control
        #questionControl
        *ngIf="question.type === 'POSTCODE'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [attr.data-order]="question.order"
        [question]="question"
        [onEnter]="onEnter"></postcode-control>

      <charity-control
        #questionControl
        *ngIf="question.type === 'CHARITY'"
        [baseForm]="baseForm"
        [form]="form"
        [id]="question.id + '-wrapper'"
        [attr.data-order]="question.order"
        [question]="question"
        [navigator]="navigator"
        [onEnter]="onEnter"></charity-control>

    </ng-container>

  </div>
  <div class="form-group position-relative row" *ngIf="!dynamicGroup">
    <div class="col-6" *ngIf="(group.adding || group.editing) && !group.locked">
      <div class="outer-button">
        <button class="btn btn-primary rounded-pill submit" id="save-{{group.name}}-{{group.index}}"
                type="button" (click)="save(group)" (keyup.enter)="save(group)">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ messages.getText('btn.saveEdit', 'Add') }}
        </button>
      </div>
    </div>
    <div class="col-6" *ngIf="group.editing">
      <div class="outer-button">
        <button class="btn btn-primary rounded-pill cancel submit" id="cancel-{{group.name}}-{{group.index}}"
                type="button" (click)="cancel(group)" (keyup.enter)="cancel(group)">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ messages.getText('btn.cancelEdit', 'Cancel edit') }}
        </button>
      </div>
    </div>
    <div class="col-6" *ngIf="group.adding && canDelete()">
      <div class="outer-button">
        <button class="btn btn-primary rounded-pill submit pull-right" id="delete-{{group.name}}-{{group.index}}"
                type="button" (click)="delete(group)" (keyup.enter)="delete(group)">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ messages.getText('btn.delete', 'Delete') }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="canDeleteOrEdit(group) " class="form-group position-relative row repeating-controls" [ngClass]="{'repeating-controls' : canDeleteOrEdit(group)}">
    <div class="col-6">
      <div class="outer-button">
        <button class="btn-repeating-action submit btn edit rounded-pill" id="edit-{{group.name}}-{{group.index}}"
                type="button" (click)="edit(group)" (keyup.enter)="edit(group)">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1">
            </span>
          {{ messages.getText('btn.edit', 'Edit') }}
          <i class="fa fa-pencil"></i>
        </button>
      </div>
    </div>
    <div class="col-6" *ngIf="canDelete() || adding">
      <div class="outer-button">
        <button class="btn-repeating-action submit pull-right btn delete rounded-pill" id="delete-{{group.name}}-{{group.index}}"
                type="button" (click)="delete(group)" (keyup.enter)="delete(group)">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ messages.getText('btn.delete', 'Delete') }}
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<div *ngIf="!dynamicGroup && (!adding || (!editing && groups.length > 1))" class="form-group position-relative row">
  <div class="col-6">
    <div class="outer-button">
      <button class="btn btn-primary rounded-pill submit" id="add-{{ groupName }}"
              type="button" (click)="addGroup()" (keyup.enter)="addGroup()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ messages.getText('btn.add', 'Add another entry') }}
      </button>
    </div>
  </div>
</div>
