import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { APPLICATION_CONSTANTS } from '../../models/application.constants';
import { ENV } from "../../models/environment.model";
import { LoggerService } from '../logger/logger.service';
import { User } from '../../models/user.model';

const LOG: LoggerService = LoggerService.get('AbstractTokenService');

/**
 * Service to handle token maintenance / decoding
 *
 * @author Dan Bennett (dbennett)
 */
export class AbstractTokenService {

    private jwtHelper: JwtHelperService = new JwtHelperService();
    protected authToken: string;

    constructor(authToken: string, private cookieService: CookieService) {
        this.authToken = authToken;
    }

    get(): string {
        LOG.trace('get', `Getting token [${this.authToken}] ...`);
        return this.cookieService.get(this.authToken);
    }

    getUser(): User {
        const token: string = this.get();
        if (this.decode(token)['aud'] === 'anonymous') {
            return null;
        }
        return token ? new User().convert(this.decode(token)['user']) : null;
    }

    save(cookieName: string, token: string): void {
        LOG.trace('save', `Saving token to [${this.authToken}] ...`);
        this.deleteToken(cookieName);
        this.cookieService.set(cookieName, token, APPLICATION_CONSTANTS.cookieTimeoutDays, '/', ENV.config.get('mainDomain'));
    }

    decode(token: string): any {
        return this.jwtHelper.decodeToken(token);
    }

    deleteToken(cookieName: string = APPLICATION_CONSTANTS.authToken): void {
        this.cookieService.delete(cookieName, null, ENV.config.get('mainDomain'));
    }

    isExpired(token?: string): boolean {
        if (!token) {
            token = this.get();
        }
        LOG.trace('isExpired', `token [${token}] is expired [${(!token || this.jwtHelper.isTokenExpired(token))}]`);

        if (!token || this.jwtHelper.isTokenExpired(token)) {
            this.deleteToken();
            return true;
        }
        return false;
    }
}
