import { Injectable } from '@angular/core';
import { ServiceType } from "../../models/environment.model";
import { LawfirmBranding } from "../../models/lawfirm.model";
import { HttpService } from "../http/http.service";
import { LoggerService } from "../logger/logger.service";

const BASE: ServiceType = ServiceType.LAWFIRM;
const LOG: LoggerService = LoggerService.get('LawfirmService');

/**
 * Service for lawfirm-related logic
 *
 * @author J. R. Smith
 * @since 16th May 2024
 */
@Injectable({
  providedIn: 'root'
})
export class LawfirmService {

    constructor(private http: HttpService) {}

    /** Retrieve the branding for a given lawfirm */
    async getBrandingFor(initiator: string | undefined): Promise<LawfirmBranding> {
        if (!initiator) {
            return {} as LawfirmBranding;
        }

        const res = await this.http.get(BASE, 'get-branding', { initiator });
        LOG.debug('getBrandingFor', `Retrieved ${initiator} branding: ${res}`);

        const branding = res.branding || {};
        return LawfirmBranding.convert(branding, true);
    }

}
