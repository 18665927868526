import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { validatePostcode } from '../../validators/postcode.validator';
import { BaseInputControl } from '../base-input-control/base-input-control.component';

const LOG: LoggerService = LoggerService.get('PostcodeControl');

/**
 * Display a control which actively validates post code input
 *
 * @author Mateusz Jablonski
 */
@Component({
    selector: 'postcode-control',
    templateUrl: '../base-input-control/base-input-control.component.html',
    styleUrls: [
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class PostcodeControl extends BaseInputControl implements OnInit {

    constructor(messageService: MessageService) {
        super(messageService);
        this.inputType = 'text';

        this.LOG = LOG;
    }

    ngOnInit(): void {
        this.addPostCodeValidation();
        super.ngOnInit();
    }

    private addPostCodeValidation(): void {
        if (this.question.type === 'POSTCODE') {
            this.validators.push(validatePostcode());
        }
    }
}
