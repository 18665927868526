/**
 * Utility to parse date into verified string format
 *
 * @author Paul Thorp (pthorp)
 * @author Dan Bennett (dbennett)
 */
export class DateUtils {

    static formatDate(date: Date): string {
        if (!date) {
            return '';
        }
        const mm: number = date.getMonth() + 1;
        const dd: number = date.getDate();

        return [
            (dd > 9 ? '' : '0') + `${dd}`,
            (mm > 9 ? '' : '0') + `${mm}`,
            date.getFullYear()
        ].join('/');
    }

    static toDate(date: string, separator?: string, yearLast?: boolean): Date {
        if (!date) {
            return null;
        }

        separator = (!separator || separator !== '-') ? '/' : separator;
        let parts = date.split(separator);

        if (!parts || parts.length < 3) {
            parts = date.split('-');
        }

        let parsedDate;
        if (yearLast) {
            parsedDate = new Date(+parts[2], +parts[1] - 1, +parts[0]);
        } else {
            parsedDate = new Date(+parts[0], +parts[1] - 1, +parts[2]);
        }
        return parsedDate;
    }

    static getDay(date: string): number {
        if (!date) {
            return null;
        }
        let parts: string[] = null;
        if (date.indexOf('/') !== -1) {
            parts = date.split('/');
            return +parts[0];
        } else if (date.indexOf('-') !== -1) {
            parts = date.split('-');
            return +parts[2];
        }
    }

    static getMonth(date: string): number {
        if (!date) {
            return null;
        }
        let parts: string[] = null;
        if (date.indexOf('/') !== -1) {
            parts = date.split('/');
            return +parts[1];
        } else if (date.indexOf('-') !== -1) {
            parts = date.split('-');
            return +parts[1];
        }
    }

    static getYear(date: string): number {
        if (!date) {
            return null;
        }
        let parts: string[] = null;
        if (date.indexOf('/') !== -1) {
            parts = date.split('/');
            return +parts[2];
        } else if (date.indexOf('-') !== -1) {
            parts = date.split('-');
            return +parts[0];
        }
    }

    static formatTime(date: Date) {
        return date.toLocaleTimeString();
    }

    /**
     * Gets current datetime as string
     * @returns date as a string
     */
    static getDateTimeAsString(): string {
        const date: number = Date.now();
        return date.toString();
    }

    static formatISODate(date: Date) {
        if (!date) {
            return '';
        }
        const mm: number = date.getMonth() + 1;
        const dd: number = date.getDate();

        return [
            date.getFullYear(),
            (mm > 9 ? '' : '0') + `${mm}`,
            (dd > 9 ? '' : '0') + `${dd}`
        ].join('-');
    }
}
