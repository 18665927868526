import { AbstractControl } from '@angular/forms';
import { LoggerService } from '../../logger/logger.service';

const LOG: LoggerService = LoggerService.get('CheckboxValidator');

/**
 * Set of validators for checkboxes
 *
 * @author Dan Bennett (dbennett)
 */
export class CheckboxValidator {

    static requiredCheckboxGroupValidator(control: AbstractControl): { [key: string]: boolean } | null {
        LOG.trace('requiredCheckboxGroupValidator', `Validating checkbox value: = ${control.value}`);
        for (const value of control.value) {
            if (value) {
                return null;
            }
        }
        return { 'unchecked': true };
    }

    static singleRequiredCheckboxGroupValidator(control: AbstractControl): { [key: string]: boolean } | null {
        LOG.trace('singleRequiredCheckboxGroupValidator', `Validating checkbox value: = ${control.value}`);
        let selectedCount: number = 0;
        for (const value of control.value) {
            if (value) {
                selectedCount++;
            }

            if (selectedCount > 1) {
                return { 'multiple': true };
            }
        }
        return selectedCount === 0 ? { 'unchecked': true } : null;
    }
}

