import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs/internal/ReplaySubject";
import { APPLICATION_CONSTANTS } from '../../models/application.constants';
import { ApplicationType, ENV, ServiceType } from "../../models/environment.model";
import { CoreModule } from '../core.module';
import { HttpService } from "../http/http.service";
import { HttpUtils } from "../http/http.utils";
import { LoggerService } from '../logger/logger.service';
import { User } from '../../models/user.model';
import { AnonymousTokenService } from './anonymous/anonymous-token.service';
import { TokenService } from './token.service';

const LOG: LoggerService = LoggerService.get('AuthenticationService');

declare function updateIntercomWithUserData(appId: string, userId: number, userEmail: string, userHash: string);

/**
 * Service to retrieve auth token in order to authenticate any further requests
 *
 * @author Paul Thorp (pthorp)
 * @author Dan Bennett (dbennett)
 */
@Injectable({
    providedIn: CoreModule
})
export class AuthenticationService {

    public static authenticated: ReplaySubject<boolean> = new ReplaySubject();

    constructor(
        private http: HttpService,
        private tokenService: TokenService,
        private anonymousTokenService: AnonymousTokenService) {

        if (!this.tokenService.isExpired()) {
            const user: User = this.tokenService.getUser();
            if (user) {
                AuthenticationService.authenticated.next(true);
            }
        }
    }

    async getAnonymousToken(): Promise<any | string> {
        try {
            const response: any = await this.http.post(ENV.services.get(ServiceType.SECURITY)!, 'anonymous', null, null, null, false, true);

            if (response) {
                LOG.debug('getAnonymousToken', `User authenticated successfully? [${response.body}]`);
                const token: string = response;
                LOG.trace('getAnonymousToken', `Got token: ${token}`);
                this.anonymousTokenService.save(APPLICATION_CONSTANTS.anonymousAuthToken, token);
                return token;
            }
            throw new Error('Error communicating with the server!');

        } catch (err) {
            LOG.error(`Error logging in! ${err}`);
            return HttpUtils.handleError('SECURITY::anonymous', err);
        }

    }

    /**
     * Check to determine if user is logged in
     *
     * @returns boolean to indicate logged in or not
     */
    isLoggedIn(): boolean {
        const toReturn: boolean = !(this.tokenService.isExpired());
        LOG.trace('isLoggedIn', `User logged in [${toReturn}]`);

        return toReturn;
    }

    /**
     * Logs out user by removing token and redirecting to login
     *
     * @WARNING
     * authenticated Subject listeners can try to access backend so make sure old token is removed before notifying them with next.
     */
    logout(): void {
        this.tokenService.deleteToken();

        AuthenticationService.authenticated.next(false);

        window.location.href = ENV.applications.get(ApplicationType.HOME).baseUrl;
    }

    /**
     * Returns the current logged-in user if possible
     */
    getUser(tokenService?: TokenService): User {
        if (!tokenService) {
            tokenService = this.tokenService;
        }

        return tokenService.getUser();
    }

    /**
     * Generates user_hash using current user ID and updates Intercom widget with user's details
     */
    async updateIntercomWidget(userId: number, userEmail: string): Promise<void> {
        LOG.debug('updateIntercomWidget', `Updating Intercom widget with user details ${userEmail}`)
        try {
            const intercomId = ENV.config.get('intercomAppID')
            const userHash: any = await this.http.get(
                ServiceType.SECURITY, 'generate-user-hash', null, { userId }
            );

            updateIntercomWithUserData(intercomId, userId, userEmail, userHash);

        } catch (err) {
            LOG.error(`Error generating user hash... ${err}`);
        }
    }

}
