
export const ENV: EnviromentCfg = {
    configServiceHostMap: {
        'localhost:4200': 'http://localhost:8083',
        'localhost:4300': 'http://localhost:8083',
        'willforgood-interview.tier2consulting.uk': 'https://willforgood-services.tier2consulting.uk/config-services',
        'dev-interview.bequeathed.org': 'https://dev-services.bequeathed.org/config-services',
        'uat-interview.bequeathed.org': 'https://uat-services.bequeathed.org/config-services',
        'interview.bequeathed.org': 'https://services.bequeathed.org/config-services'
    },
    config: new Map<string, any>(),
    applications: new Map<ApplicationType, Application>(),
    services: new Map<ServiceType, Service>()
};

/**
 * Catalogue of all available REST services and endpoints on the back-end
 *
 * @author Dan Bennett
 */
export declare class EnviromentCfg {

    // Used to resolve where to load config from based on the current hostname
    configServiceHostMap: any;
    // A simple map of relevant config i.e. Google Analytics ID, etc
    config: Map<string, string>;
    // A map of all applications and related content
    applications: Map<ApplicationType, Application>;
    // A map of all microservices and related endpoints
    services: Map<ServiceType, Service>;
}

/**
 * Enumeration of all the exposed microservices
 *
 * @author Dan Bennett
 */
export enum ServiceType {
    CALENDLY = 'CALENDLY',
    CHARITY = 'CHARITY',
    CONFIG = 'CONFIG',
    CONTENT = 'CONTENT',
    DATA = 'DATA',
    DOCUMENT_GENERATOR = 'DOCUMENT_GENERATOR',
    INTERCOM = 'INTERCOM',
    INTERVIEW = 'INTERVIEW',
    LAWFIRM = 'LAWFIRM',
    LOCATION = 'LOCATION',
    REPORT = 'REPORT',
    REPORT_GENERATOR = 'REPORT_GENERATOR',
    RESOURCE = 'RESOURCE',
    RULES_ENGINE = 'RULES_ENGINE',
    SECURITY = 'SECURITY'
}

/**
 * A configured microservice
 *
 * @author Dan Bennett
 */
export declare class Service {

    baseUrl: string;
    tokenHeader: string;
    api: any; //Map<string, string>; FixMe :: Make this a Map later
}

/**
 * Enumeration of all the web applications
 *
 * @author Dan Bennett
 */
export enum ApplicationType {

    HOME = 'HOME',
    INTERVIEW = 'INTERVIEW',
    MANAGEMENT = 'MANAGEMENT',
    JOIN = 'JOIN'
}

/**
 * A configured web application
 *
 * @author Dan Bennett
 */
export declare class Application {

    baseUrl: string;
    loginRequired: boolean;
    api: any; //Map<string, string>; FixMe :: Make this a Map later
}

