<div class="container-fluid">
  <div class="row">
    <div class="content-panel col-12">
      <div class="row">
        <div class="col">
          <h1 class="underline">{{ messages.getText('advice.interview.cannot-complete.title') }}</h1> <!-- aria -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 interview-finished">
          <div class="form-group position-relative topic-form">
            <div class="position-relative" [innerHTML]="messages.getText('advice.interview.cannot-complete.body', [myWishesUrl])"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
