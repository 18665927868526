import { Component, Input, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subject } from 'rxjs/internal/Subject';
import { CharityService } from '../../../core/services/charity.service';
import { LoggerService } from '../../../core/logger/logger.service';
import { MessageService } from '../../../core/message/message.service';
import { QAUtils } from '../../../core/util/qa.utils';
import { RulesService } from '../../../core/services/rules.service';
import { InterviewService } from "../../../core/services/interview.service";
import { Answer, Interview } from "../../../models/interview.model";
import { CharityOption, Question, Topic, SubTopic } from "../../../models/navigator.model";

const LOG: LoggerService = LoggerService.get('NavigatorComponent');

/**
 * Displays a navigation panel on the left hand-side of the interview
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'navigator',
    templateUrl: './navigator.component.html',
    styleUrls: ['./navigator.component.sass']
})
export class NavigatorComponent implements OnInit {

    @Input() editing: boolean;
    @Input() interview: Interview;
    @Input() qaChanges: Subject<Answer[]>;
    @Input() questionChanges: Subject<Question[]>;

    currentTopic: Topic = {} as Topic;
    currentSubTopic: SubTopic = {} as SubTopic;
    answeredQuestions: string[];
    initiatorCharity: CharityOption;
    partnerCharities: CharityOption[];

    latestTopic: string;
    latestSubTopic: string;

    progress: number;

    topics: Topic[];
    topicsMap: Map<string, Topic> = new Map<string, Topic>();
    completed: boolean = false;
    interviewFinished: boolean = false;
    type: string = 'WILL';

    constructor(
        private title: Title,
        private router: Router,
        private rulesService: RulesService,
        private interviewService: InterviewService,
        private charityService: CharityService,
        private messageService: MessageService) {
    }

    get messages(): MessageService {
        return this.messageService;
    }

    get progressAsString(): string {
        return `${Math.floor(this.progress)}`;
    }

    async ngOnInit(): Promise<void> {

        this.progress = 0;

        // Load partner charities for later
        this.partnerCharities = (await this.charityService.getPartnerCharities())
            ?.map(c => new CharityOption(c))
            // Sort the charities randomly
            .sort(() => (Math.random() > 0.5) ? 1 : -1);

        this.qaChanges.subscribe(async (next: Answer[]) => {
            LOG.debug('init', 'Loading new navigator...');

            if (!this.initiatorCharity) {
                // Load initiator charity, if not already loaded
                const initiatorIdQ: Answer[] = next.filter((answer: Answer) => answer.question === 'pre.initiator.id');
                if (initiatorIdQ.length > 0) {
                    const charityId = initiatorIdQ[0].answer;
                    this.initiatorCharity = (await this.charityService.getCharityBy(charityId));
                    LOG.debug('init', `Retrieved initiator charity: ${JSON.stringify(this.initiatorCharity)}`);
                }
            }

            await this.loadNavigator(next);
        });
    }

    private async loadNavigator(qa: Answer[]): Promise<any> {
        let res: any;
        try {
            res = await this.rulesService.getNavigator(qa);
            if (!res) {
                throw new Error('No response!');
            }
        } catch (err) {
            setTimeout(() => this.loadNavigator(qa), 3000);
            LOG.error('Error retrieving navigator! Retrying in 3 seconds...');
            return;
        }

        this.completed = res.completed;
        this.interview.complete = res.completed;

        const lastLatestTopic: string = this.latestTopic;
        const lastLatestSubTopic: string = this.latestSubTopic;

        this.latestTopic = res.currentTopic;
        this.latestSubTopic = res.currentSubTopic;
        this.answeredQuestions = qa.map((q: Answer) => q.question);

        this.topicsMap = new Map<string, Topic>();
        res.topics.forEach((topic: Topic) => this.topicsMap.set(topic.id, topic));

        this.topics = res.topics;
        if (LOG.levelEnabled('TRACE')) {
            LOG.trace('init', `Loaded: ${JSON.stringify(this.topics, null, '  ')}`);
        }

        // Determine currentTopic
        if (this.interviewService.summaryState.enabled && !!this.interviewService.summaryState.editingTopic) {
            // ToDo :: This causes an issue
            LOG.debug('qaChanges', `Switching to editing topic: ${this.interviewService.summaryState.editingTopic} -> ${this.interviewService.summaryState.editingSubTopic}`);
            this.goToSubTopic(this.interviewService.summaryState.editingTopic, this.interviewService.summaryState.editingSubTopic);
        } else if (this.interview.errorsOnEdit?.size) {
            this.goToNextError();
        } else if (!!this.currentTopic && lastLatestTopic !== this.currentTopic.id || (!!this.currentSubTopic && this.currentSubTopic.id !== lastLatestSubTopic)) {
            this.goToNextTopic();
        } else {
            // ToDo :: Use the topic determined by the back-end?
            this.currentTopic = this.determineCurrentTopic(this.latestTopic);
            this.currentSubTopic = this.determineCurrentSubTopic(this.latestSubTopic);
        }

        if (this.completed
            && this.interviewService.summaryState?.enabled
            && this.interviewService.summaryState?.editingTopic !== this.currentTopic.id) {

            this.goToSummary();
            return;
        }

        this.determineCompletionState().then(() => LOG.debug('init', 'Completion state determined!'));
        this.questionChanges.next(this.getQuestionsForCurrentTopic());
        if (this.completed || this.interviewService.summaryState.enabled) {
            this.progress = 100;
        } else {
            this.progress = this.latestSubTopic ? this.topicsMap.get(this.latestTopic).subTopicsMap.get(this.latestSubTopic).progress : this.topicsMap.get(this.latestTopic).progress;
        }

        this.title.setTitle(`Will Interview${!this.currentTopic ? '' : ' : ' + this.messages.getText(this.currentTopic.messageCode)}${!this.currentSubTopic ? '' : ' : ' + this.messages.getText(this.currentSubTopic.messageCode)}`);
    }

    private allMandatoryQuestionsAnswered(questions: Question[]): boolean {
        return questions
            .filter(q => q.required || q.id.startsWith('note.'))
            .reduce(
                (acc: boolean, question: Question) => this.answeredQuestions.filter(q2 => q2.indexOf(question.id) > -1).length > 0 && acc,
                true
            );
    }

    private anyQuestionsAnswered(questions: Question[]): boolean {
        return questions.reduce(
            (acc: boolean, question: Question) => this.answeredQuestions.filter((q2: string) => q2.indexOf(question.id) > -1).length > 0 || acc,
            false
        );
    }

    private async determineCompletionState(): Promise<void> {
        this.topics.forEach((topic: Topic) => {
            topic.subTopics?.forEach((subTopic: SubTopic) => {
                subTopic.started = this.isStarted(topic, subTopic);
                subTopic.completed = !subTopic.hasQuestions() || this.allMandatoryQuestionsAnswered(subTopic.questions);
            });
            topic.completed = (!topic.hasQuestions() || this.allMandatoryQuestionsAnswered(topic.questions))
                && (!topic.hasSubTopics() || topic.subTopics.reduce((acc: boolean, val: Topic) => val.completed && acc, true));
            topic.collapsed = topic.completed && this.currentTopic.id !== topic.id;
            topic.started = this.isStarted(topic);
        });
    }

    private determineCurrentTopic(latestTopic: string): Topic {
        for (const topic of this.topics) {
            LOG.debug('determineCurrentTopic', `Checking topic: ${topic.id}`);
            const topicHasUnansweredQuestions: boolean = this.topicHasUnansweredQuestions(topic);
            const subTopicHasUnansweredQuestions: boolean = topic.hasSubTopics() && topic.subTopics.reduce((acc: boolean, subTopic: SubTopic) => this.topicHasUnansweredQuestions(subTopic) || acc, false);

            if (topicHasUnansweredQuestions || subTopicHasUnansweredQuestions) {
                LOG.debug('determineCurrentTopic', `Current topic determined as: ${topic.id}`);
                topic.collapsed = false;
                return topic;
            }
        }
        LOG.debug('determineCurrentTopic', `Current topic determined as: ${this.topicsMap.get(latestTopic) || this.topics[0]}`);
        return this.topicsMap.get(latestTopic) || this.topics[0];
    }

    private topicHasUnansweredQuestions(topic: Topic | SubTopic): boolean {
        return topic.hasQuestions() && !this.allMandatoryQuestionsAnswered(topic.questions);
    }

    private determineCurrentSubTopic(latestSubTopic: string): SubTopic {
        if (!this.currentTopic.hasSubTopics() || (this.topicHasUnansweredQuestions(this.currentTopic))) {
            return null;
        }

        for (const subTopic of this.currentTopic.subTopics) {
            if (this.topicHasUnansweredQuestions(subTopic)) {
                LOG.debug('determineCurrentSubTopic', `Current topic determined as: ${subTopic.id}`);
                return subTopic;
            }
        }

        return !!this.currentTopic.subTopics && this.currentTopic.subTopics.length > 0 ? this.currentTopic.subTopics[0] : this.currentTopic.subTopicsMap.get(latestSubTopic);
    }

    isCurrentTopic(topic: Topic | SubTopic): boolean {
        if (topic instanceof Topic) {
            return this.currentTopic.id === topic.id;
        }
        return !!this.currentSubTopic && this.currentSubTopic.id === topic.id;
    }

    isStarted(topic: Topic, subTopic?: SubTopic): boolean {
        // Allow if it's already completed
        if (topic?.completed && (!subTopic || subTopic.completed)) {
            return true;
        }
        // Allow if it's the latest topic/sub-topic
        if (topic?.id === this.latestTopic && (!this.latestSubTopic || subTopic?.id === this.latestSubTopic)) {
            return true;
        }
        // Allow if any questions have already been answered
        if (subTopic) {
            return this.anyQuestionsAnswered(subTopic.questions || []);
        }
        return this.anyQuestionsAnswered(topic?.questions || []);
    }

    getTooltipText(topic: Topic, subTopic?: SubTopic): string {
        if (topic.completed) {
            return this.messages.getText('tooltip.you-are-here');
        }

        if (subTopic?.completed) {
            return this.messages.getText('tooltip.completed');
        }
    }

    getCurrentTopic(): Topic | SubTopic {

        const topic: Topic = this.currentTopic ? this.topicsMap.get(this.currentTopic.id) : null;
        if (!this.currentSubTopic) {
            return topic;
        }
        // Navigator might not be ready yet
        if (!topic || !topic.subTopics) {
            return {} as Topic;
        }

        if (!!topic && this.topicHasUnansweredQuestions(topic) && !!this.currentSubTopic) {
            return topic;
        }

        return topic.subTopicsMap.get(this.currentSubTopic.id);
    }

    getQuestionsForCurrentTopic(): Question[] {
        if (!this.currentSubTopic) {
            return this.currentTopic.questions;
        }

        // Navigator might not be ready yet
        if (!this.currentTopic.subTopics) {
            return [];
        }
        if (!!this.currentTopic && this.topicHasUnansweredQuestions(this.currentTopic) && !!this.currentSubTopic) {
            return this.currentTopic.questions;
        }

        return this.currentSubTopic ? this.currentSubTopic.questions : [];
    }

    goToPreviousTopic(): void {
        // If current topic has previous sub-topics, navigate to there
        if (this.currentTopic.hasSubTopics() && !!this.currentSubTopic && this.currentSubTopic.order > 1) {
            const currentSubTopic: SubTopic = this.determinePreviousSubTopic();
            if (currentSubTopic) {
                this.currentSubTopic = currentSubTopic;
                this.questionChanges.next(this.currentSubTopic.questions);
                return;
            }
        }

        if (this.currentTopic.hasQuestions() && this.currentSubTopic != null) {
            this.currentSubTopic = null;
            this.questionChanges.next(this.currentTopic.questions);
            return;
        }

        // If not, determine location within previous topic
        for (let i = 1; i < this.topics.length; i++) {
            if (this.topics[i].id === this.currentTopic.id) {
                this.currentTopic = this.topics[i - 1];
                if (!this.currentTopic.hasSubTopics()) {
                    this.currentSubTopic = null;
                    this.questionChanges.next(this.currentTopic.questions);
                    break;
                }
                this.currentSubTopic = this.currentTopic.subTopics[this.currentTopic.subTopics.length - 1];
                this.questionChanges.next(this.currentSubTopic.questions);
                break;
            }
        }
        this.determineCompletionState().then(() => {
        });
    }

    determinePreviousSubTopic(topic: Topic = this.currentTopic): SubTopic {
        if (topic.hasSubTopics() && !!this.currentSubTopic && this.currentSubTopic.order > 1) {
            const subTopics: SubTopic[] = topic.subTopics;
            for (let i = 1; i < subTopics.length; i++) {
                if (subTopics[i].id === this.currentSubTopic.id) {
                    return subTopics[i - 1];
                }
            }
        }
        return null;
    }

    /**
     * Iterate topics and find the first topic or subtopic with an errored question
     * This should only ever happen on Edit
     */
    goToNextError(): void {
        this.topics?.forEach((topic: Topic) => {
            if (QAUtils.hasErroredQuestion(topic, this.interview.errorsOnEdit)) {
                this.goToTopic(topic.id);
                return;
            }
            if (topic.hasSubTopics()) {
                for (const subTopic of topic.subTopics) {
                    if (QAUtils.hasErroredQuestion(subTopic, this.interview.errorsOnEdit)) {
                        this.goToSubTopic(topic.id, subTopic.id);
                        return;
                    }
                }
            }
        });
    }

    goToNextTopic(): void {
        // If current topic has next sub-topic, navigate to there
        const currentTopic: Topic = this.topicsMap.get(this.currentTopic.id);
        if (currentTopic.hasSubTopics() && (!this.currentSubTopic || this.currentSubTopic?.order < currentTopic.subTopics.slice(-1)[0].order)) {
            this.currentTopic = currentTopic;
            this.currentSubTopic = this.determineNextSubTopic();
            if (this.currentSubTopic) {
                LOG.debug('goToNextTopic', `Next topic/sub-topic determined as: ${this.currentTopic.id} -> ${this.currentSubTopic.id}`);
                this.questionChanges.next(this.currentSubTopic.questions);
                return;
            }

        }

        // If not, determine location within next topic
        for (let i = 0; i < this.topics.length; i++) {
            if (this.topics[i].id === currentTopic.id) {
                this.currentTopic = this.topics[i + 1];
                if (this.currentTopic.hasQuestions()) {
                    this.currentSubTopic = null;
                    LOG.debug('goToNextTopic', `Next topic/sub-topic determined as: ${this.currentTopic.id} -> null`);
                    this.questionChanges.next(this.currentTopic.questions);
                    break;
                }
                this.currentSubTopic = this.currentTopic.subTopics[0];
                LOG.debug('goToNextTopic', `Next topic/sub-topic determined as: ${this.currentTopic.id} -> ${this.currentSubTopic.id}`);
                this.questionChanges.next(this.currentSubTopic.questions);
                break;
            }
        }
    }

    determineNextSubTopic(topic: Topic = this.currentTopic): SubTopic {
        if (!topic.hasSubTopics()) {
            return null;
        }
        if (topic.hasSubTopics() && !this.currentSubTopic) {
            return topic.subTopics[0];
        }
        if (topic.hasSubTopics() && !!this.currentSubTopic) {
            const subTopics: SubTopic[] = topic.subTopics;
            for (let i: number = 0; i < subTopics.length; i++) {
                if (subTopics[i].id === this.currentSubTopic.id) {
                    LOG.debug('determineNextSubTopic', `Current sub-topic is: ${this.currentSubTopic.id} so next one is: ${subTopics[i + 1].id}`);
                    return subTopics[i + 1];
                }
            }
        }
        return null;
    }

    goToTopic(topicId: string, subTopicId?: string): void {
        LOG.debug('goToTopic', `Navigating to: ${topicId} -> ${subTopicId}`);
        this.currentTopic = this.topicsMap.get(topicId);

        if (subTopicId) {
            this.currentSubTopic = this.currentTopic.subTopicsMap.get(subTopicId);
            this.questionChanges.next(this.currentSubTopic.questions);
        } else if (this.currentTopic.hasQuestions()) {
            this.currentSubTopic = null;
            this.questionChanges.next(this.currentTopic.questions);
        } else if (!this.currentTopic.hasSubTopics()) {
            // FixMe :: Not sure this makes sense!
            this.questionChanges.next(this.currentSubTopic.questions);
        } else {
            this.currentSubTopic = this.currentTopic.subTopics[0];
            this.questionChanges.next(this.currentSubTopic.questions);
        }
        this.determineCompletionState().then(() => LOG.debug('goToTopic', 'Completion state determined!'));
    }

    goToSubTopic(topicId: string, subTopicId: string): void {
        this.goToTopic(topicId, subTopicId);
    }

    async goToSummary(): Promise<void> {
        this.interviewService.summaryState = {
            editing: false,
            enabled: true,
            editingTopic: null,
            editingSubTopic: null,
            activeTopics: this.interviewService.summaryState.activeTopics || []
        };
        this.router.navigate(['interview', this.type, this.interview.id, 'summary']);
    }

    toggleTopic(topic: Topic) {
        topic.collapsed = !topic.collapsed;
    }
}
