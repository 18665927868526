import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { BaseInputControl } from '../base-input-control/base-input-control.component';

const LOG: LoggerService = LoggerService.get('PercentageControl');

/**
 * Control to retrieve content as a percentage, typically refers to an integer but displays a '%' sign
 * as a suffix
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'percentage-control',
    templateUrl: './percentage-control.component.html',
    styleUrls: [
        './percentage-control.component.sass',
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class PercentageControl extends BaseInputControl implements OnInit {

    constructor(private messageService: MessageService) {
        super(messageService);
        this.inputType = 'number';
        this.LOG = LOG;
    }

    ngOnInit(): void {
        this.determineValidators();
        super.ngOnInit();
    }
}
