<div *ngIf="!paymentStatus" class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" tabindex="-1">{{ messages.getTextInterpolate('title.confirm-donation', [charity.name]) }}</h4>
</div>
<div class="modal-body">
  <div *ngIf="!paymentStatus" class="container col-md-12">
    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center text-center">
        <img class="charity-logo" [src]="charity.logoUrl" [alt]="charity.name"/>
      </div>
      <div class="col-sm-12 col-md-3 align-self-center text-center">
        <span id="charity-name-{{ charity.nonProfitId }}" class="charity-name">{{ charity.name }}</span>
      </div>
      <div class="col-sm-12 col-md-5 align-self-center" id="charity-amount-{{charity.nonProfitId }}">
        <div *ngIf="(this.donationAmount.touched || consentToShareDetails.touched || includeGiftAid.touched || errored) && donationAmount.invalid" class="alert-danger error pill form-error-list">
          <div id="donation-amount-err" class="error-list-wrapper" [attr.aria-live]="'assertive'">
            <ul>
              <li>{{ messages.getText('error.required.amount') }}</li>
            </ul>
          </div>
        </div>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">£</span>
          </div>
          <input #input id="charity-amount" type="number" name="charityAmount{{ charity.nonProfitId }}" class="form-control"
                 min="0"
                 aria-required="false"
                 aria-describedby="donation-amount-err"
                 [attr.aria-label]="messages.getTextInterpolate('label.charity-amount', [charity.name])"
                 [(formControl)]="donationAmount"
                 [class.is-invalid]="donationAmount.touched && donationAmount.invalid"
                 integer />
        </div>
        <div #feedback class="feedback-icon" aria-hidden="true" *ngIf="donationAmount.touched || consentToShareDetails.touched || includeGiftAid.touched || errored">
          <i class="fa" aria-hidden="true"
             [class.fa-check-circle]="donationAmount.valid"
             [class.fa-times-circle]="donationAmount.invalid"
          ></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div id="gift-aid-check" class="col input-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="gift-aid" [(formControl)]="includeGiftAid" />
        </div>
        <label class="input-group-prepend" for="gift-aid">{{ messages.getText('label.include-gift-aid') }}</label>
        <div *ngIf="includeGiftAid.touched || consentToShareDetails.touched || errored" #feedback class="feedback-icon form-group radio" aria-hidden="true">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </div>
        <div [innerHTML]="messages.getText('label.include-gift-aid.info')"></div>
      </div>
    </div>
    <div class="row">
      <div id="consent-check" class="col input-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="consent-to-share"
                 [(formControl)]="consentToShareDetails"
                 aria-describedby="consent-error"
                 required
          />
        </div>
        <label class="input-group-prepend" for="consent-to-share" [innerHTML]="messages.getText('label.consent-to-share-details')"></label>
        <div *ngIf="consentToShareDetails.touched || errored" #feedback class="feedback-icon form-group radio" aria-hidden="true">
          <i class="fa" aria-hidden="true"
             [class.fa-check-circle]="consentToShareDetails.valid"
             [class.fa-times-circle]="consentToShareDetails.invalid"
          ></i>
        </div>
        <div [innerHTML]="messages.getText('label.consent-to-share-details.info')"></div>
      </div>
    </div>
    <div class="row">
      <div class="hidden-col col-md-4 col-sm-12"></div>
      <div class="col-md-4 col-sm-12">
        <div id="charity-button-{{ charity.nonProfitId }}"></div>
      </div>
      <div class="col-md-4 col-sm-12 text-md-left text-center">
        <button type="button" id="cancel-button" class="btn btn-outline-secondary rounded-pill" (click)="modal.close()">{{ messages.getText('btn.cancel') }}</button>
      </div>
    </div>
  </div>

  <div *ngIf="!!paymentStatus" id="payment-outcome" class="container col-md-12">
    <div  class="text-center">
      <i *ngIf="paymentStatus === 'CONFIRMED'" class="payment-indicator fa fa-check"></i>
      <i *ngIf="paymentStatus === 'CANCELLED'" class="payment-indicator fa fa-exclamation-circle"></i>
      <i *ngIf="paymentStatus === 'FAILED'" class="payment-indicator fa fa-times"></i>
      <div id="payment-made-text" tabindex="-1" [innerHTML]="messages.getTextInterpolate('body.payment-made.' + paymentStatus, [charity.name])"></div>
      <button *ngIf="paymentStatus !== 'CONFIRMED'" type="button"
              class="btn btn-primary rounded-pill"
              (click)="modal.close()">
        {{ messages.getText('btn.try-again') }}
      </button>
      <button *ngIf="paymentStatus !== 'CONFIRMED'" type="button"
              class="btn btn-outline-secondary rounded-pill"
              (click)="confirmCancellation()">
        {{ messages.getText('btn.no-thank-you') }}
      </button>
    </div>
  </div>
</div>
