import { compileDeclareComponentFromMetadata } from "@angular/compiler";
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Recommendation } from "../../../../models/advice.model";
import { AdviceOption } from "../../../../models/interview.model";
import { LoggerService } from "../../../logger/logger.service";
import { MessageService } from "../../../message/message.service";
import { ConfirmPopupComponent } from "../../confirm-popup/confirm-popup.component";

const LOG: LoggerService = LoggerService.get('AdviceControlComponent');

/**
 * Advice Interview form control to display a recommendation
 *
 * @author Bilal Yasin
 */
@Component({
    selector: 'advice-control',
    templateUrl: './advice-control.component.html',
    styleUrls: ['./advice-control.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class AdviceControl {

    @Input() config: AdviceOption;
    @Input() recommendation: Recommendation;
    @Input() adviceId: number;
    @Input() interviewId: number;
    @Input() lastOption: boolean;

    @ViewChild('confirmPopup') confirmationPopup: ConfirmPopupComponent

    OTHER = 'other';
    flagAsInvalid: boolean = false;

    @Output() interviewCompleteEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(protected messageService: MessageService) {
    }

    get messages(): MessageService {
        return this.messageService;
    }

    get invalid(): boolean {
        return this.flagAsInvalid = this.recommendation.status === 'NOT_ANSWERED'
            || (
                this.recommendation.status === 'REJECTED'
                && this.recommendation.rejectionCode === 'other'
                && !this.recommendation.rejectionText
            );
    }

    updateStatus(value: string, rejectionOption?: string, rejectionMsgCode?: string) {
        this.recommendation.status = value;
        if (rejectionOption) {
            this.updateRejectionReason(rejectionOption, rejectionMsgCode)
        }

        if (value === 'ACCEPTED' || rejectionOption !== 'other' || !!this.recommendation.rejectionText) {
            this.flagAsInvalid = false;
        }
        if (this.recommendation.name.includes('requirement') && value === 'REJECTED') {
            this.confirmRejected();
        }
    }

    updateRejectionReason(code: string, text: string) {
        this.recommendation.rejectionCode = code;
        if (!this.rejectionCodeIsOther()) {
            this.recommendation.rejectionText = this.messages.getText(this.config.rejectionOptions[code], text);
        } else {
            this.recommendation.rejectionText = undefined;
        }
    }

    async confirmRejected() {
        LOG.debug('confirmRejected', `${this.recommendation}`);
        return this.confirmationPopup.open( {
            modalTitle: this.messageService.getText('vault.areYouSure'),
            confirmButtonLabel: this.messageService.getText('dialog.recommendations.popup-no'),
            hideDismissButton: () => false, dismissButtonLabel: this.messageService.getText('dialog.recommendations.popup-yes')
        });
    }

    rejectionCodeIsOther(): boolean {
        return this.recommendation.rejectionCode === this.OTHER;
    }

    showRejectionReasons(): boolean {
        return !!this.config.rejectionOptions && this.recommendation?.status === 'REJECTED';
    }

    invalidRejection(): boolean {
        return this.recommendation.rejectionCode == null || this.recommendation.rejectionText == null;
    }

    protected readonly compileDeclareComponentFromMetadata = compileDeclareComponentFromMetadata;

    scroll(el: HTMLElement) {
        el.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
}
