import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { BaseInputControl } from '../base-input-control/base-input-control.component';

const LOG: LoggerService = LoggerService.get('NumberControl');

/**
 * Interview form control to display a numeric-only control
 *
 * @author Dan Bennett (dbennett)
 */
@Component({
    selector: 'number-control',
    templateUrl: '../base-input-control/base-input-control.component.html',
    styleUrls: [
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class NumberControl extends BaseInputControl implements OnInit {

    constructor(private messageService: MessageService) {
        super(messageService);
        this.inputType = 'number';
        this.LOG = LOG;
    }

    ngOnInit(): void {
        this.determineValidators();
        super.ngOnInit();
    }
}
