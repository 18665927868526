import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { LoggerService } from '../../../core/logger/logger.service';
import { MessageService } from '../../../core/message/message.service';
import { BaseForm } from '../../../core/ui/base-form/base-form.component';
import { PageUtil } from '../../../core/util/page.util';
import { ApplicationType, ENV } from "../../../models/environment.model";
import { RulesService } from '../../../core/services/rules.service';
import { InterviewService } from '../../../core/services/interview.service';
import { QuestionSummaryGroup, Summary, TopicSummary } from '../../../models/interview.model';

const LOG: LoggerService = LoggerService.get('InterviewSummaryComponent');

@Component({
    selector: 'app-interview-summary',
    templateUrl: './interview-summary.component.html',
    styleUrls: ['./interview-summary.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class InterviewSummaryComponent extends BaseForm implements OnInit{

    interviewId: number;
    type: string = 'WILL';
    summary: Summary;
    loading: boolean;
    homeAppUri: string;

    constructor(
        private router: Router,
        private title: Title,
        protected messageService: MessageService,
        protected changeDetector: ChangeDetectorRef,
        protected interviewService: InterviewService,
        protected cookieService: CookieService,
        protected authenticationService: AuthenticationService,
        public rulesService: RulesService,
        private route: ActivatedRoute
    ) {
        super(messageService, changeDetector, cookieService, authenticationService, rulesService);
        this.title.setTitle('Will Interview : Summary');

        this.homeAppUri = ENV.applications.get(ApplicationType.HOME).baseUrl;
    }

    ngOnInit(): void {

        this.route.params.subscribe((params: { id: number; type: string; action: string }) => {
            this.type = params.type;
            this.interviewId = params.id;

            this.fetchSummary();
        });
    }

    private fetchSummary(): void {
        this.loading = true;
        this.interviewService.summaryState = this.interviewService.summaryState || {};
        this.interviewService.summaryState.enabled = true;
        this.interviewService.summaryState.editing = false;

        this.interviewService.summary(this.type, this.interviewId)
            .then((res) => {
                this.summary = res;
                if (this.interviewService.summaryState?.activeTopics?.length) {
                    this.interviewService.summaryState.editing = false;
                    if (this.interviewService.summaryState.activeTopics?.length) {
                        this.summary.topics
                            .filter(topic => this.interviewService.summaryState.activeTopics.indexOf(topic.id) > -1)
                            .forEach(topic => topic.active = true);
                    }
                }
                this.loading = false;

                PageUtil.setFocus('interview-summary-header');
            })
    }

    public toggleTopic(topic: TopicSummary): void {
        LOG.debug('toggleTopic', `Toggling: ${topic.id}`);

        topic.active = !topic.active;
        if (topic.active) {
            this.interviewService.summaryState.activeTopics.push(topic.id);
        } else {
            this.interviewService.summaryState.activeTopics = this.interviewService.summaryState.activeTopics.filter(id => id !== topic.id);
        }
    }

    public editTopic(topic: TopicSummary, subTopic?: TopicSummary): void {
        const { params } = this.route.snapshot;

        this.interviewService.summaryState.editing = true;
        this.interviewService.summaryState.editingTopic = topic.id;
        this.interviewService.summaryState.editingSubTopic = subTopic?.id || null;
        this.router.navigate(['interview', params.type, params.id]);
    }

    async saveAndExit(): Promise<void> {
        LOG.debug('saveAndExit', 'Redirecting to my wishes...');
        this.goToWills();
    }

    goToWills(): void {
        window.location.href = `${this.homeAppUri}/my-wishes`;
    }

    async completeInterview(): Promise<void> {
        await this.interviewService.complete(this.type, this.interviewId);
        this.loading = false;

        await this.router.navigate(['interview', 'ADVICE', this.interviewId, 'recommendations']);
    }

    visibleQuestions(group: QuestionSummaryGroup): boolean {
        return !!group.questions.filter(q => q.type !== 'HIDDEN').length;
    }
}
