<div id="topic-navigator">
  <div id="progress-title" class="no-gutters">
    <div class="col-10 col-lg-12 no-gutters">
      <h2>{{ messages.getText('title.interview-progress') }}</h2>
    </div>
    <div class="col-2 d-lg-none no-gutters navbar-toggle-div">
      <button class="navbar-toggler pull-right" type="button" data-toggle="collapse" data-target="#collapsible-stage-navbar"
              aria-label="Interview progress">
        <div class="arrow-up-down"></div>
        <div class="arrow-text-down">{{ messages.getText('btn.expand') }}</div>
        <div class="arrow-text-up">{{ messages.getText('btn.close') }}</div>
      </button>
    </div>
  </div>
  <div id="progressBarTotal" class="progress-bar total" [attr.aria-label]="messages.getText('label.interview-progress', [progressAsString])" tabindex="0">
    <div id="progressBarCompleted" class="progress-bar completed" [style.width.%]="progress"></div>
  </div>
  <nav class="navbar navbar-expand-lg" *ngIf="!interviewFinished">
    <div class="collapse navbar-collapse" id="collapsible-stage-navbar">
      <ol *ngIf="!!topics">
        <ng-container *ngFor="let topic of topics; let i = index">
          <li class="form-group position-relative no-gutters topic" *ngIf="!!topic.messageCode"> <!-- Check this -->
            <div class="no-gutters row">
              <div class="col-1 stage-index" [attr.aria-label]="'Step ' + (i + 1)">
                <span class="circle"
                      [class.stage-index-current]="isCurrentTopic(topic)"
                      [class.stage-index-completed]="topic.completed">
                  <span [attr.aria-hidden]="true">{{ i + 1 }}</span>
                </span>
              </div>
              <div class="col-9 col-lg-7" [tabIndex]="topic.started ? 0 : -1">
                <a *ngIf="topic.started && !editing"
                   href="javascript:;" class="no-gutters"
                   (click)="goToTopic(topic.id)"
                   (keydown.enter)="goToTopic(topic.id)"
                   [ngClass]="[ topic.started ? 'navigable' : (topic.completed ? 'completed': '') ]"
                   [attr.data-text]="getTooltipText(topic)">
                  <h3 class="topic-title" [class.stage-title-current]="isCurrentTopic(topic)" [attr.aria-current]="isCurrentTopic(topic) ? 'step' : null">{{ messages.getText(topic.messageCode) }}</h3>
                </a>
                <div *ngIf="!topic.started || editing" class="no-gutters" [attr.data-text]="getTooltipText(topic)">
                  <h3 class="topic-title" [class.stage-title-current]="isCurrentTopic(topic)" [attr.aria-current]="isCurrentTopic(topic) ? 'step' : null">{{ messages.getText(topic.messageCode) }}</h3>
                </div>

              </div>
              <div class="col-1">
                <button type="button"
                        class="pull-right subtopic-collapse"
                        [attr.data-target]="'#collapsible-subtopic-' + topic.id"
                        [attr.aria-expanded]="!topic.collapsed"
                        [attr.aria-label]="messages.getText('label.expand-topic', [messages.getText(topic.messageCode)])"
                        (click)="toggleTopic(topic)"
                        [hidden]="!topic.subTopics || topic.subTopics.length === 0">
                  <i class="fa fa-1"
                     [class.fa-angle-up]="currentTopic.id === topic.id || topic.collapsed === false"
                     [class.fa-angle-down]="currentTopic.id !== topic.id && topic.collapsed === true"></i>
                </button>
              </div>
            </div>
            <ng-container *ngIf="topic.subTopics">
              <ol class="sub-topic-wrapper" [ngbCollapse]="topic.collapsed" id="collapsible-subtopic-{{topic.id}}">
                <li class="form-group position-relative no-gutters sub-topic" *ngFor="let subTopic of topic.subTopics; let j = index" [id]="'sub-topic-' + subTopic.id">
                  <div class="no-gutters row" [tabIndex]="subTopic.completed || subTopic.started ? 0 : -1">
                    <div *ngIf="!subTopic.started || editing" class="sub-topic-item no-gutters"
                       [attr.data-text]="getTooltipText(topic, subTopic)">
                      <div class="col-1 stage-index"></div>
                      <div class="col-9 col-lg-7">
                        <h4 class="topic-title" [attr.aria-current]="isCurrentTopic(subTopic) ? 'step' : null">{{ messages.getText(subTopic.messageCode) }}</h4>
                      </div>
                      <div class="col-1"></div>
                    </div>
                    <a *ngIf="subTopic.started && !editing"
                       href="javascript:;" class="no-gutters"
                       (click)="goToSubTopic(topic.id, subTopic.id)"
                       (keydown.enter)="goToSubTopic(topic.id, subTopic.id)"
                       [class.navigable]="subTopic.started"
                       [class.completed]="subTopic.completed"
                       [attr.data-text]="getTooltipText(topic, subTopic)">
                      <div class="col-1 stage-index"></div>
                      <div class="col-9 col-lg-7">
                        <h4 class="topic-title" [ngClass]="{'stage-title-current': isCurrentTopic(subTopic)}"
                            [attr.aria-current]="isCurrentTopic(subTopic) ? 'step' : null">{{ messages.getText(subTopic.messageCode) }}</h4>
                      </div>
                      <div class="col-1 feedback">
                        <div *ngIf="subTopic.completed" #feedback class="feedback-icon pull-right" aria-hidden="true">
                          <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!subTopic.completed && subTopic.started" #feedback class="feedback-icon pull-right" aria-hidden="true">
                          <i class="fa fa-circle-o" aria-hidden="true"></i>
                        </div>
                      </div>
                    </a>
                  </div>
                </li>
              </ol>
            </ng-container>
          </li>
        </ng-container>
      </ol>
      <div class="navbar-toggle-div navbar-toggle-div-bottom">
        <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#collapsible-stage-navbar"
                aria-label="Topic navigation close">
          <div class="arrow-up-down"></div>
          <div>Close</div>
        </button>
      </div>
    </div>
  </nav>
</div>
