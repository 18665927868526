<fieldset *ngIf="!!control" [attr.id]=" question.id ">
  <legend>
    <div [attr.aria-labelledby]=" question.id +'-err'"></div>
    <span *ngIf="!!question.messageCode">
      <p #label id="{{ question.id }}-label" class="field-label">{{ messages.getText(question.messageCode) }}</p>
    </span>
  </legend>
  <div *ngIf="!!question.noteMessageCode" [attr.aria-labelledby]=" question.id +'-note'"></div>
  <span *ngIf="!!question.noteMessageCode">
    <div id="{{ question.id }}-desc" class="field-description" [innerHTML]="messages.getText(question.noteMessageCode)"></div>
  </span>
  <div *ngIf="controlHasError()" class="alert-danger error pill form-error-list">
    <div id="{{ question.id }}-err" class="error-list-wrapper" [attr.aria-live]="'assertive'">
      <ul>
        <li *ngFor="let err of baseForm.errorMap.get(question.id)">
          <span>{{ determineErrorMessage(err.error, control, question) }}</span>
        </li>
      </ul>
    </div>
  </div>
  <!-- make sure aria attributes and onther are configurable -->
  <div [ngClass]="inputClass" class="container">
    <div class="option-row row align-items-center" *ngFor="let option of options();let i = index; let last = last; odd as isOdd; even as isEven">

      <label class="option " for="{{ question.id }}-{{ option.charityId }}">
        <input #input type="checkbox"
               [attr.name]="question.id + '-' + option.charityId"
               name="{{ question.id }}-{{ option.charityId }}"
               id="{{ question.id }}-{{ option.charityId }}"
               [value]="option.value"
               [attr.aria-invalid]="controlHasError()"
               [(formControl)]="formArray.controls[i]"
               (keydown.enter)="onEnter($event)"
               (change)="revalidateInput();uncheckOthers(option.value, i)"
               [attr.aria-label]="getLabel()"
               [readOnly]="question.locked"/>
        <span class="checkmark"></span>

        <span class="complex-checkbox">
          <div class="col-5 img">
            <img src="{{ option.logoUrl }}" class="option-logo" alt="{{ option.accountShortName }} logo"/>
          </div>
          <div class="col-7 option-content">
            <div><h6>{{ option.accountShortName }}</h6>
            <span>{{ option.charityMissionStatement }}</span>
            </div>
              <button class="pull-left btn btn-primary rounded-pill more-info" data-toggle="collapse" [attr.data-target]="'#'+ getInputId(i)"
                      (click)="option.collapsed = !option.collapsed"
                      [attr.aria-label]="'Show ' + (option.collapsed ? 'more' : 'less') + ' information about ' + option.accountShortName">
                    {{ option.collapsed ? 'More information' : 'Less information' }}
                <i class="fa fa-1" [ngClass]="{'fa-angle-up': !option.collapsed, 'fa-angle-down': option.collapsed }"></i>
                </button>
            </div>
          </span>
      </label>
      <div *ngIf="!isHoriz() && last && baseForm.isSubmitted(form) && control.touched" #feedback class="feedback-icon form-group radio" aria-hidden="true">
        <i class="fa" aria-hidden="true"
           [ngClass]="{ 'fa-check-circle' : !controlHasError(), 'fa-times-circle' :  controlHasError() }"></i>
      </div>
      <div class="collapse option-fullDescription" [id]="getInputId(i)">
        <span>{{ option.charityLongStatement }}</span>
      </div>
    </div>
  </div>
</fieldset>

<ng-content></ng-content>
