<fieldset [attr.id]="question.id">
  <legend>
    <div [attr.aria-labelledby]=" question.id +'-err'"></div>
    <span *ngIf="!!question.messageCode">
      <p #label id="{{ question.id }}-label" class="field-label">{{ messages.getText(question.messageCode) }}</p>
    </span>
  </legend>
  <div *ngIf="!!question.noteMessageCode" [attr.aria-labelledby]=" question.id +'-note'"></div>
  <span *ngIf="!!question.noteMessageCode">
    <div id="{{ question.id }}-desc" class="field-description" [innerHTML]="messages.getText(question.noteMessageCode)"></div>
  </span>
  <div *ngIf="controlHasError()" class="alert-danger error pill form-error-list">
    <div id="{{ question.id }}-err" class="error-list-wrapper" [attr.aria-live]="'assertive'">
      <ul>
        <li *ngFor="let err of baseForm.errorMap.get(question.id)">
          <span>{{ determineErrorMessage(err.error, control, question) }}</span>
        </li>
      </ul>
    </div>
  </div>
  <!-- make sure aria attributes and other are configurable -->
  <div class="dropdown" role="radiogroup">
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-outline-primary form-control form-select"
              [id]="question.id" ngbDropdownToggle
              [value]="previousAnswer || null"
              (focusout)="revalidateInput()"
              [attr.aria-labelledby]="getAriaLabels()">
        <div class="dropdown-text">{{ messages.getText((getOption(control.value) || getOption(previousAnswer) || { messageCode: '' }).messageCode, '-- select --')}}</div>
      </button>
      <div ngbDropdownMenu aria-labelledby="getAriaLabels(optionKey)" (blur)="validate()" class="dropdown-control-options">
        <button ngbDropdownItem *ngFor="let option of question.optionsList; let i = index; let last = last"
                [attr.aria-label]="option.value +', '+ (i+1) + ' of ' + question.optionsList.length"
                (click)="selectOption(option.value);revalidateInput()"
                (keydown.enter)="onEnter($event)"
                [disabled]="question.locked">
          {{ messages.getText(option.messageCode) }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="question.locked && !!control.value" class="dropdown" role="">
    <input #input type="hidden" [attr.name]="question.id" [attr.id]="question.id" class="form-control {{inputClass}}"
           [required]="question.required" [attr.aria-required]="question.required"
           [value]="control.value"
           (keydown.enter)="onEnter($event)"
           readOnly
           [attr.aria-label]="getLabel()"
           [attr.aria-describedby]="getAriaLabels()" [ngClass]="{ 'is-invalid': controlHasError(), 'locked' : question.locked  }"/>
  </div>
  <div *ngIf="baseForm.isSubmitted(form) && control.touched" #feedback class="feedback-icon form-group radio dropdown-feedback-icon" aria-hidden="true">
    <i class="fa" aria-hidden="true"
       [ngClass]="{ 'fa-check-circle' : !controlHasError(), 'fa-times-circle': controlHasError() }"></i>
  </div>

</fieldset>

<ng-content></ng-content>
