export class Topic {

    id: string;
    order: number;
    progress: number;
    messageCode: string;
    endInterview: boolean;
    questions: Question[];
    subTopics: SubTopic[];
    subTopicsMap: Map<string, SubTopic> = new Map<string, SubTopic>();

    completed: boolean = false;
    started: boolean = false;
    collapsed: boolean = false;

    constructor(topic?: any) {
        if (topic) {
            this.id = topic.id;
            this.order = topic.order;
            this.progress = topic.progress;
            this.messageCode = topic.messageCode;
            this.endInterview = topic.endInterview;
            if (topic.questions) {
                this.questions = topic.questions.map((q: any) => new Question(q));
            }
            if (topic.subtopics) {
                this.subTopics = [];
                Object.values(topic.subtopics)
                      .forEach((subTopic: any) => {
                          const subT: SubTopic = new SubTopic(subTopic);
                          this.subTopics.push(subT);
                          this.subTopicsMap.set(subT.id, subT);
                      });

                this.subTopics = this.subTopics.sort((t1: any, t2: any) => (t1.order > t2.order ? 1 : -1));
            }
        }
    }

    hasQuestions(): boolean {
        return !!this.questions && this.questions.length > 0;
    }

    hasSubTopics(): boolean {
        return !!this.subTopics && this.subTopics.length > 0;
    }
}

export class SubTopic {

    id: string;
    order: number;
    progress: number;
    messageCode: string;
    endInterview: boolean;
    questions: Question[];

    completed: boolean = false;
    started: boolean = false;

    constructor(subTopic?: any) {
        if (subTopic) {
            this.id = subTopic.id;
            this.order = subTopic.order;
            this.progress = subTopic.progress;
            this.messageCode = subTopic.messageCode;
            this.endInterview = subTopic.endInterview;
            if (subTopic.questions) {
                this.questions = subTopic.questions.map((q: any) => new Question(q));
            }
        }
    }

    hasQuestions(): boolean {
        return !!this.questions && this.questions.length > 0;
    }

}

export class Question {

    id: string;
    groupId: string;
    order: number;
    messageCode: string;
    noteMessageCode: string;
    dynamicGroupSource: string;
    targetGroup: string;
    targetName: string;
    type: string;
    charityOptionType: string;
    required: boolean;
    validators: string[];
    multiple: boolean;
    min: number;
    max: number;
    options: any;
    optionsList: Option[];

    locked: boolean;

    constructor(question?: any) {
        if (question) {
            this.id = question.id;
            this.groupId = question.groupId;
            this.order = question.order;
            this.messageCode = question.messageCode;
            this.noteMessageCode = question.noteMessageCode;
            this.targetGroup = question.targetGroup;
            this.targetName = question.targetName;
            this.dynamicGroupSource = question.dynamicGroupSource;
            this.type = question.type;
            this.charityOptionType = question.charityOptionType;
            this.required = !!question.required;
            this.validators = question.validators || [];
            this.multiple = !!question.multiple;
            this.options = question.options || {};
            this.optionsList = question.options ? Object.entries(question.options).map((o: any) => new Option(o)) : [];
            this.min = question.min;
            this.max = question.max;
        }
    }
}

export class Option {

    value: string;
    messageCode: string;

    constructor(option?: any) {
        if (option) {
            this.value = option[0];
            this.messageCode = option[1];
        }
    }

    asCharityOption(): CharityOption {
        return null;
    }
}

export class Group {

    name: string;
    index: number;
    associatedAnswer: string;
    adding: boolean;
    editing: boolean;
    locked: boolean;
    answers: any;
    questions: Question[];

    constructor(group?: any) {
        if (group) {
            this.name = group.groupName;
            this.index = group.groupIndex;
            this.associatedAnswer = group.associatedAnswer;
            this.adding = group.adding;
            this.editing = group.editing;
            this.locked = group.locked;
            this.questions = group.questions;
            this.answers = group.answers;
        }
    }
}

export class CharityOption extends Option {

    charityId: string;
    accountShortName: string;
    accountLongName: string;
    charityMissionStatement: string;
    charityDescription: string;
    charityLongStatement: string;
    logoUrl: string;
    collapsed: boolean;

    constructor(option?: any) {
        super([option?.accountShortName, null]);

        if (option) {
            this.charityId = option.charityId;
            this.accountShortName = option.accountShortName;
            this.accountLongName = option.accountLongName;
            this.charityMissionStatement = option.charityMissionStatement;
            this.charityDescription = option.charityDescription;
            this.charityLongStatement = option.charityLongStatement;
            this.logoUrl = option.logoUrl;
        }
        this.collapsed = true;
    }

    asCharityOption(): CharityOption {
        return this;
    }
}
