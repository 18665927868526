import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../../logger/logger.service';
import { MessageService } from '../../../message/message.service';
import { validateEmail } from '../../validators/email.validator';
import { BaseInputControl } from '../base-input-control/base-input-control.component';

const LOG: LoggerService = LoggerService.get('EmailControl');

@Component({
    selector: 'email-control',
    templateUrl: '../base-input-control/base-input-control.component.html',
    styleUrls: [
        '../base-input-control/base-input-control.component.sass',
        '../../base-form/base-form.component.sass'
    ],
    encapsulation: ViewEncapsulation.None
})
export class EmailControl extends BaseInputControl implements OnInit {

    constructor(messageService: MessageService) {
        super(messageService);
        this.inputType = 'email';

        this.LOG = LOG;
    }

    ngOnInit(): void {
        this.addEmailValidation();
        super.ngOnInit();
    }

    private addEmailValidation(): void {
        this.validators.push(validateEmail());
    }
}
