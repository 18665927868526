import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

/**
 * Pipe to ignore Angular DomSanitizer for the passed in HTML
 *
 * @author Maciej Goszewski (mgoszewski)
 */
@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {

    constructor(private sanitized: DomSanitizer) {
    }

    transform(value) {
        console.log("Trusting HTML content: ", this.sanitized.bypassSecurityTrustHtml(value))
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
