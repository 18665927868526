import { Entity } from "./entity";

/**
 * Set of Lawfirm-related Models
 */
export class LawfirmBranding extends Entity {

    lawfirmId: number | undefined;
    logoLarge: string | undefined;
    logoSmall: string | undefined;
    colourLightBase: string | undefined;
    colourLightText: string | undefined;
    colourDarkBase: string | undefined;
    colourDarkText: string | undefined;
    colourHighlight: string | undefined;
    colourSuccess: string | undefined;
    colourFail: string | undefined;
}
